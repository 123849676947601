import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../common/useAuth';
import { message, Button, Form, Input, Upload, Table, DatePicker } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import './Education.css';

const Education = () => {
    const [data, setData] = useState([]);
    const { apiurl, token } = useAuth();
    const [add, setAdd] = useState(false);
    const [form] = Form.useForm();

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/candidate/education/`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                message.error("Failed to fetch education details");
                return;
            }

            const result = await response.json();
            setData(result);
        } catch (error) {
            message.error("Error fetching education details");
        }
    };

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    const handleAdd = async (values) => {
        const formData = new FormData();
        formData.append('institution_name', values.institution_name);
        formData.append('field_of_study', values.field_of_study);
        formData.append('start_date', values.start_date.format('YYYY-MM-DD'));
        formData.append('end_date', values.end_date.format('YYYY-MM-DD'));
        formData.append('degree', values.degree);
        formData.append('education_proof', values.education_proof[0].originFileObj);

        try {
            const response = await fetch(`${apiurl}/candidate/education/`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                message.error("Failed to add education");
                return;
            }

            message.success('Education added successfully');
            setAdd(false);
            fetchData();
        } catch (error) {
            message.error("Error adding education");
        }
    };

    const handleRemove = async (educationId) => {
        try {
            const response = await fetch(`${apiurl}/candidate/education/?id=${educationId}`, {
                method: 'DELETE',
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                message.error("Failed to remove education");
                return;
            }

            message.success('Education removed successfully');
            fetchData();
        } catch (error) {
            message.error("Error removing education");
        }
    };

    const columns = [
        { title: 'Institution Name', dataIndex: 'institution_name', key: 'institution_name' },
        { title: 'Field of Study', dataIndex: 'field_of_study', key: 'field_of_study' },
        { title: 'Degree', dataIndex: 'degree', key: 'degree' },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (text) => dayjs(text).format('YYYY-MM-DD')
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
            render: (text) => dayjs(text).format('YYYY-MM-DD')
        },
        {
            title: 'Education Proof',
            dataIndex: 'education_proof',
            key: 'education_proof',
            render: (text) => text ? <a href={`${apiurl}${text}`} target="_blank" rel="noopener noreferrer">View</a> : 'N/A',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button type="link" onClick={() => handleRemove(record.id)}>Remove</Button>
            ),
        },
    ];

    return (
        <div>
            <h2>Education</h2>
            <Button type='primary' onClick={() => setAdd(true)}>Add Education <PlusSquareOutlined /></Button>

            <Table dataSource={data} columns={columns} rowKey="id" style={{ marginTop: 20 }} />

            {add && (
                <Form form={form} onFinish={handleAdd} layout="vertical">
                    <Form.Item
                        label="Institution Name"
                        name="institution_name"
                        rules={[{ required: true, message: "Please provide the institution name" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Field of Study"
                        name="field_of_study"
                        rules={[{ required: true, message: "Please provide the field of study" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Degree"
                        name="degree"
                        rules={[{ required: true, message: "Please provide the degree" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Start Date"
                        name="start_date"
                        rules={[{ required: true, message: "Please select the start date" }]}
                    >
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>

                    <Form.Item
                        label="End Date"
                        name="end_date"
                        rules={[{ required: true, message: "Please select the end date" }]}
                    >
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>

                    <Form.Item
                        label="Upload Education Proof"
                        name="education_proof"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => e && e.fileList}
                        rules={[{ required: true, message: "Please upload an education proof document" }]}
                    >
                        <Upload
                            listType="text"
                            beforeUpload={() => false}
                            accept=".pdf,.jpg,.png"
                            maxCount={1}
                        >
                            <Button>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">Submit</Button>
                        <Button type="default" onClick={() => setAdd(false)} style={{ marginLeft: 10 }}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
};

export default Education;
