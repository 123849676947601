import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../common/useAuth'
import { UploadOutlined, } from '@ant-design/icons'
import { message, Form, Input, Button, DatePicker, Upload, Image } from 'antd'
import dayjs from 'dayjs'

const Profile = () => {
    const { token, apiurl } = useAuth();
    const [data, setData] = useState();
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState()
    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/candidate/profile/`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "content-type": 'multipart/form-data'
                }
            });

            if (!response.ok) {
                throw new Error(`Error ${response.status}: ${response.statusText}`);
            }

            const data = await response.json();
            if (data.error) {
                message.error(data.error);
            }
            else {
                if (data.date_of_birth) {
                    data.date_of_birth = dayjs(data.date_of_birth, "YYYY-MM-DD");
                }
                setData(data);
            }

        } catch (e) {
            message.error(`Failed to fetch profile: ${e.message}`);
        }
    };


    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token])


    const handleSubmit = async (values) => {
        try {
            const formData = new FormData()

            for (const key in values) {
                if (key === "profile" && values[key]?.file) {
                    console.log(values[key].file, "file is", values[key])
                    formData.append(key, values[key].file);
                } else if (key === 'date_of_birth' && values[key] != null) {
                    formData.append(key, dayjs(values[key]).format('YYYY-MM-DD'))
                }
                else if (values[key] != null) {
                    formData.append(key, values[key]);
                }
            }
            const response = await fetch(`${apiurl}/candidate/profile/`, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
                body: formData
            });

            const data = await response.json();

            if (data.error) {
                message.error("Error while submitting the file");
            } else if (data.message) {
                message.success(data.message); // Fixed incorrect key
            }
        } catch (e) {
            console.log(e)
            message.error("error occured"); // Improved error handling
        }
    };


    return (
        <div >
            {message && message.success && <div className="success">{message.success}</div>}
            {message && message.error && <div className="error">{message.error}</div>}
            <h2>Basic Details</h2>
            {data && data.name &&
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={{
                        ...data,
                        name: data?.name?.username,
                    }}
                    layout="vertical"
                >

                    {data?.profile &&
                        <Image src={`${apiurl}${data.profile}`} alt='Profile picture here' style={{ width: "150px", borderRadius: "50% 50%" }} />

                    }
                    x
                    {data?.profile &&
                        <Form.Item label="Profile Image" name="profile">
                            <Upload
                                fileList={fileList}
                                onChange={() => { setFileList() }}
                                beforeUpload={() => false}  // Prevent automatic upload
                                accept="image/*"
                                maxCount={1}  // Allow only a single file
                            >
                                <Button icon={<UploadOutlined />}>Update profile</Button>
                            </Upload>
                        </Form.Item>
                    }
                    {!data.profile &&
                        <Form.Item label="Profile Image" name="profile">
                            <Upload
                                fileList={fileList}
                                onChange={() => { setFileList() }}
                                beforeUpload={() => false}  // Prevent automatic upload
                                accept="image/*"
                                maxCount={1}  // Allow only a single file
                            >
                                <Button icon={<UploadOutlined />}>Upload Profile Image</Button>
                            </Upload>
                        </Form.Item>
                    }



                    {/* Name */}
                    <Form.Item label="Name" name="name">
                        <Input disabled />
                    </Form.Item>

                    {/* Email */}
                    <Form.Item label="Email" name="email">
                        <Input disabled />
                    </Form.Item>

                    {/* First Name */}
                    <Form.Item
                        label="First Name"
                        name="first_name"
                        rules={[{ required: true, message: 'First name is required' }]}
                    >
                        <Input />
                    </Form.Item>

                    {/* Middle Name */}
                    <Form.Item label="Middle Name" name="middle_name">
                        <Input />
                    </Form.Item>

                    {/* Last Name */}
                    <Form.Item
                        label="Last Name"
                        name="last_name"
                        rules={[{ required: true, message: 'Last name is required' }]}
                    >
                        <Input />
                    </Form.Item>

                    {/* Communication Address */}
                    <Form.Item
                        label="Communication Address"
                        name="communication_address"
                        rules={[{ required: true, message: 'Communication address is required' }]}
                    >
                        <Input.TextArea rows={2} />
                    </Form.Item>

                    {/* Permanent Address */}
                    <Button
                        onClick={() => {
                            const communicationAddress = form.getFieldValue("communication_address");
                            if (communicationAddress) {
                                form.setFieldsValue({ "permanent_address": communicationAddress });
                            } else {
                                message.error("Please fill the communication address first.");
                            }
                        }}
                    >
                        Same as communication address
                    </Button>

                    <Form.Item label="Permanent Address" name="permanent_address">
                        <Input.TextArea rows={2} />
                    </Form.Item>

                    {/* Phone Number */}
                    <Form.Item
                        label="Phone Number"
                        name="phone_num"
                        rules={[{ required: true, message: 'Phone number is required' }]}
                    >
                        <Input />
                    </Form.Item>

                    {/* Date of Birth */}
                    <Form.Item label="Date of Birth" name="date_of_birth">
                        <DatePicker
                            format="YYYY-MM-DD"
                            onChange={(date, dateString) => {
                                // form.setFieldsValue({ date_of_birth: dateString })
                                console.log(dateString, " is the date")
                            }}
                        />
                    </Form.Item>

                    {/* Designation */}
                    <Form.Item label="Designation" name="designation">
                        <Input />
                    </Form.Item>

                    {/* Social Media Links */}
                    <Form.Item label="LinkedIn Profile" name="linked_in"
                        rules={[
                            {
                                type: 'url',
                                message: 'Please enter a valid URL'
                            },
                            {
                                pattern: /^(https?:\/\/)?(www\.)?linkedin\.com\/[a-zA-Z0-9_]+$/, // Regular expression for Instagram URL
                                message: 'Please enter a valid LinkedIn URL (e.g., https://linked.com/username)',
                            }
                        ]}
                    >
                        <Input placeholder="https://linkedin.com/in/..." />

                    </Form.Item>

                    {/* Blood Group */}
                    <Form.Item label="Blood Group" name="blood_group">
                        <Input />
                    </Form.Item>

                    {/* Experience in Years */}
                    <Form.Item label="Years of Experience" name="experience_years">
                        <Input />
                    </Form.Item>

                    {/* Skills */}
                    <Form.Item label="Skills" name="skills">
                        <Input.TextArea rows={3} placeholder="e.g., React, Django, Python" />
                    </Form.Item>

                    {/* Submit Button */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            }
        </div >
    )
}

export default Profile