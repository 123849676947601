import React, { useEffect, useState } from 'react';
import Main from '../Layout';
import { Button, message, Table } from 'antd';
import { useAuth } from '../../../common/useAuth';

const Invoice = () => {
    const { token, apiurl } = useAuth();
    const [invoices, setInvoices] = useState([]);
    const [noJobs, setNoJobs] = useState(false);

    const fetchInvoice = async () => {
        try {
            const response = await fetch(`${apiurl}/manager/get_invoices/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (data.noJobs) {
                return setNoJobs(true);
            }
            if (data.error) {
                message.error(data.error);
            }
            setInvoices(data.invoices);
        } catch (e) {
            console.error(e);
            message.error('Failed to fetch invoices.');
        }
    };

    useEffect(() => {
        if (token) {
            fetchInvoice();
        }
    }, [token]);

    const handlePrint = (id) => {
        const invoiceToPrint = invoices.find(invoice => invoice.job_id === id);
        if (!invoiceToPrint) {
            message.error('Invoice not found.');
            return;
        }

        const printWindow = window.open('', '_blank');
        printWindow.document.write(invoiceToPrint.invoice);
        printWindow.document.close();
        printWindow.print();
    };

    const columns = [
        {
            title: 'Job Title',
            dataIndex: 'job_title',
            key: 'job_title',
        },
        {
            title: 'Invoice',
            dataIndex: 'invoice',
            key: 'invoice',
            render: (_, record) => (
                <Button onClick={() => handlePrint(record.job_id)}>Print Invoice</Button>
            ),
        },
    ];

    return (
        <Main defaultSelectedKey="8">
            {noJobs ? (
                <div className="no_jobs">
                    <h2>There are no Jobs here</h2>
                </div>
            ) : (
                <Table
                    dataSource={invoices}
                    columns={columns}
                    rowKey="job_id"
                    pagination={{ pageSize: 5 }}
                />
            )}
        </Main>
    );
};

export default Invoice;
