import React from 'react'
import { Form, Input, Button } from 'antd'
import { useNavigate } from 'react-router-dom'

const CompanyCode = ({ setCurrentStep, setCompanyCode, companyCode }) => {
    const navigate = useNavigate()
    return (
        <div>
            <Form layout="vertical" onFinish={() => {
                setCurrentStep(1);
            }}>
                <Form.Item
                    label="Company Code"
                    name="company_code"
                    rules={[
                        { required: true, message: "Please enter company code" },
                    ]}>
                    <Input
                        onChange={(e) => setCompanyCode(e.target.value)}
                    />
                </Form.Item>
                <Button type='primary' onClick={() => navigate(-1)}>Back</Button>
                <Button type="primary" htmlType="submit">
                    Fetch Terms
                </Button>
            </Form>
        </div>
    )
}

export default CompanyCode