import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ResumeColumn,
  CandidateNameColumn,
  OtherDetailsColumn,
  CurrentOrganisationColumn,
  CurrentJobLocationColumn,
  CurrentJobTypeColumn,
  DateOfBirthColumn,
  ExperienceColumn,
  ExpectedCTCColumn,
  CurrentCTCColumn,
  StatusColumn,
  PrimarySkillsColumn,
  NoticePeriodColumn,
  SecondarySkillsColumn,
} from "./ResumeComponents";
import Main from "../Layout";
import "./Resumes.css";
import { useAuth } from "../../../common/useAuth";
import { Button, Modal } from "antd";
import { message } from "antd";
import { ApiFilled } from "@ant-design/icons";
// import moment from "moment";
// import { FlagFilled } from "@ant-design/icons";
const apiurl = process.env.REACT_APP_BACKEND_URL;

const Resumes = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [acceptReason, setAcceptReason] = useState("");
  const [feedback, setFeedback] = useState("");
  const [currentApplicationId, setCurrentApplicationId] = useState(null);
  const [rejectAction, setRejectAction] = useState(false);
  const [acceptAction, setAcceptAction] = useState(false);
  const [selectAction, setSelectAction] = useState(false);
  // const [dateAndTime, setDateAndTime] = useState();
  const [job, setJob] = useState(null);
  const [loading, SetLoading] = useState(false);
  const [compareArray, setToCompareArray] = useState([]);
  const [visible, setVisible] = useState(false);

  const { token } = useAuth();

  useEffect(() => {
    if (compareArray?.length > 0) {
      setVisible(true);
    }
  }, [compareArray]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${apiurl}/client/get-resumes/?jobid=${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setJob(data.job_data);
      setData(data.data);
      console.log(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token, id]);

  const handleAccept = async (e, id) => {
    e.preventDefault();
    SetLoading(true);
    try {
      const response = await fetch(
        `${apiurl}/client/accept-application/?id=${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            acceptReason: acceptReason,
            resume_id: id,
            round_num: 1,
          }),
        }
      );
      if (!response.ok) {
        const data = await response.json();
        console.log(data);
        message.error(data.error);
      } else {
        const data = await response.json();
        setData(data.data);
        setFeedback("");
        message.success(data.message);
        window.location.reload();
      }
    } catch (e) {
      message.error(e);
    } finally {
      SetLoading(false);
    }
  };

  const handleReject = async (e, id) => {
    e.preventDefault();
    SetLoading(true);
    try {
      const response = await fetch(
        `${apiurl}/client/reject-application/?id=${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            feedback: feedback,
          }),
        }
      );

      SetLoading(false);
      if (!response.ok) {
        const data = await response.json();
        console.log(data);
        message.error(data.error);
      } else {
        const data = await response.json();
        setData(data.data);
        message.success(data.message);
        setFeedback("");
        window.location.reload();
      }
    } catch (e) {
      message.error(e);
    }
  };

  const handleDirectSelect = async (e, id) => {
    console.log("acceptReason", acceptReason);
    // console.log("text is ",e.target.value,id)
    e.preventDefault();
    SetLoading(true);
    try {
      const response = await fetch(
        `${apiurl}/client/select-application/?id=${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            feedback: feedback,
          }),
        }
      );

      SetLoading(false);
      if (!response.ok) {
        const data = await response.json();
        console.log(data);
        message.error(data.error);
      } else {
        const data = await response.json();
        setData(data.data);
        message.success(data.message);
        setFeedback("");
        window.location.reload();
      }
    } catch (e) {
      message.error(e);
    }
  };

  if (!job) return null;
  console.log("running this component");

  const handleAddToCompare = (application) => {
    if (compareArray.length < 3) {
      if (!compareArray.includes(application)) {
        setToCompareArray((prev) => {
          return [...prev, application];
        });
      }
    } else {
      message.info("max compare three items");
    }
  };

  console.log("compareArray", compareArray);

  // const showModal = () => {

  // };

  const handleRemoveComparisionCard = (indexToRemove) => {
    // Remove item from array by index
    const newArray = compareArray.filter((_, index) => index !== indexToRemove);
    setToCompareArray(newArray); // Update the state with the new array
  };

  const handleCompareResume = (app, method) => {
    console.log("application", app);
    console.log("method", method);
    if (method === "Accept") {
      console.log(
        "here make the request for the backend to store the accepted cand"
      );
      setAcceptAction(true);
    } else {
			setRejectAction(true);
      console.log(
        "here make the request for the backend to rejected candidate"
      );
    }
  };
  return (
    <Main defaultSelectedKey="3">
      {message && message.success && (
        <div className="success">{message.success}</div>
      )}
      {message && message.error && <div className="error">{message.error}</div>}
      <div className="resumes-main">
        <div className="jobInfo">
          <h1>{job.job_title}</h1>
          <p>
            <span className="bold">Job Description: </span>{" "}
            {job.job_description}
            <br />
            <span className="bold">CTC: </span> {job.ctc}
          </p>
        </div>
        <div className="applicationTable">
          <div className="row">
            <div className="cell">Field</div>
            {data &&
              data.map((application, index) => (
                <div key={index} className="cell">
                  Application {index + 1}
                </div>
              ))}
          </div>

          <div className="row">
            <div className="cell">Candidate Name</div>
            {data &&
              data.map((application, index) => (
                <CandidateNameColumn key={index} application={application} />
              ))}
          </div>

          <div className="row">
            <div className="cell">Other Details</div>
            {data &&
              data.map((application, index) => (
                <OtherDetailsColumn key={index} application={application} />
              ))}
          </div>

          <div className="row">
            <div className="cell">Current Job Status</div>
            {data &&
              data.map((application, index) => (
                <StatusColumn key={index} application={application} />
              ))}
          </div>

          <div className="row">
            <div className="cell">Current Organisation</div>
            {data &&
              data.map((application, index) => (
                <CurrentOrganisationColumn
                  key={index}
                  application={application}
                />
              ))}
          </div>

          <div className="row">
            <div className="cell">Current Job Location</div>
            {data &&
              data.map((application, index) => (
                <CurrentJobLocationColumn
                  key={index}
                  application={application}
                />
              ))}
          </div>

          <div className="row">
            <div className="cell">Current Job Type</div>
            {data &&
              data.map((application, index) => (
                <CurrentJobTypeColumn key={index} application={application} />
              ))}
          </div>

          <div className="row">
            <div className="cell">Date of Birth</div>
            {data &&
              data.map((application, index) => (
                <DateOfBirthColumn key={index} application={application} />
              ))}
          </div>

          <div className="row">
            <div className="cell">Experience</div>
            {data &&
              data.map((application, index) => (
                <ExperienceColumn key={index} application={application} />
              ))}
          </div>

          <div className="row">
            <div className="cell">Current CTC</div>
            {data &&
              data.map((application, index) => (
                <CurrentCTCColumn key={index} application={application} />
              ))}
          </div>

          <div className="row">
            <div className="cell">Expected CTC</div>
            {data &&
              data.map((application, index) => (
                <ExpectedCTCColumn key={index} application={application} />
              ))}
          </div>

          <div className="row">
            <div className="cell">Notice Period</div>
            {data &&
              data.map((application, index) => (
                <NoticePeriodColumn key={index} application={application} />
              ))}
          </div>

          <div className="row">
            <div className="cell">Primary Skills and Experience</div>
            {data &&
              data.map((application, index) => (
                <PrimarySkillsColumn key={index} application={application} />
              ))}
          </div>
          <div className="row">
            <div className="cell">Secondary Skills and Experience</div>
            {data &&
              data.map((application, index) => (
                <SecondarySkillsColumn
                  key={application.id}
                  application={application}
                />
              ))}
          </div>

          <div className="row">
            <div className="cell">Resume</div>
            {data &&
              data.map((application, index) => (
                <ResumeColumn key={application.id} application={application} />
              ))}
          </div>

          <div className="row">
            <div className="cell">Actions</div>
            {data &&
              data.map((application, index) =>
                application.job_application.status !== "pending" &&
                !compareArray.includes(application) ? (
                  <div key={index} className="cell">
                    {application.job_application.status}{" "}
                  </div>
                ) : (
                  <div key={index} className="cell">
                    <div className="flex buttons">
                      {job.num_of_rounds == 0 ? (
                        <Button
                          color="#32CD32"
                          variant="outlined"
                          disabled={rejectAction || selectAction}
                          onClick={() => {
                            setSelectAction(true);
                            setRejectAction(false);
                            setCurrentApplicationId(application.id);
                          }}
                        >
                          Select Direct
                        </Button>
                      ) : (
                        <Button
                          color="#32CD32"
                          variant="outlined"
                          disabled={rejectAction || acceptAction}
                          onClick={() => {
                            setRejectAction(false); // Ensure feedback form is hidden
                            setAcceptAction(true); // Show shortlist form
                            setCurrentApplicationId(application.id); // Set the current application id
                          }}
                        >
                          Accept
                        </Button>
                      )}

                      <Button
                        danger
                        disabled={acceptAction || rejectAction}
                        onClick={() => {
                          setRejectAction(true); // Show feedback form
                          setAcceptAction(false); // Hide shortlist form
                          setCurrentApplicationId(application.id); // Set the current application id
                        }}
                      >
                        Reject
                      </Button>
                      {!compareArray.includes(application) && (
                        <Button
                          primary
                          onClick={() => {
                            handleAddToCompare(application);
                          }}
                        >
                          add to compare
                        </Button>
                      )}
                    </div>

                    {selectAction &&
                      currentApplicationId === application.id && (
                        <form
                          onSubmit={(e) =>
                            handleDirectSelect(e, currentApplicationId)
                          }
                        >
                          <h2>Reason for selecting</h2>
                          <textarea
                            value={acceptReason}
                            onChange={(e) => setAcceptReason(e.target.value)}
                            required
                          />

                          <div className="buttons">
                            <Button
                              onClick={() => {
                                setRejectAction(false); // Hide feedback form
                                setSelectAction(false);
                              }}
                            >
                              Back
                            </Button>
                            <Button
                              style={{ marginTop: "10px" }}
                              htmlType="submit"
                            >
                              Accept candidate
                            </Button>
                          </div>
                        </form>
                      )}

                    {acceptAction &&
                      currentApplicationId === application.id && (
                        <form
                          onSubmit={(e) =>
                            handleAccept(e, currentApplicationId)
                          }
                        >
                          <h2>Why you are accepting</h2>
                          <textarea
                            value={acceptReason}
                            onChange={(e) => setAcceptReason(e.target.value)}
                            required
                          />

                          <div className="buttons">
                            <Button
                              onClick={() => {
                                setRejectAction(false); 
                                setAcceptAction(false);
                              }}
                            >
                              Back
                            </Button>
                            <Button
                              style={{ marginTop: "10px" }}
                              htmlType="submit"
                            >
                              Accept candidate
                            </Button>
                          </div>
                        </form>
                      )}

                    {rejectAction &&
                      currentApplicationId === application.id && (
                        <form
                          onSubmit={(e) =>
                            handleReject(e, currentApplicationId)
                          }
                        >
                          <h2>Why you are rejecting</h2>
                          <textarea
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            required
                          />
                          <div className="buttons">
                            <Button
                              onClick={() => {
                                setRejectAction(false); // Hide feedback form
                                setAcceptAction(false); // Hide shortlist form
                              }}
                            >
                              Back
                            </Button>
                            <Button
                              disabled={!feedback.trim()}
                              htmlType="submit"
                            >
                              Submit Feedback
                            </Button>
                          </div>
                        </form>
                      )}
                  </div>
                )
              )}
          </div>
        </div>
      </div>

      {visible ? (
        <div>
          <h2>Application Details</h2>
          <div className="comparison-cards-container">
            {compareArray.map((obj, indx) => (
              <div key={indx} className="comparison-card">
                <span
                  className="close-button"
                  onClick={() => handleRemoveComparisionCard(indx)}
                >
                  &#10006;
                </span>
                <h3>cand {indx + 1}</h3>
                <p>cand Name: {obj.candidate_name}</p>
                <p>Experience: {obj.experience}</p>
                <p>cand current ctc: {obj.current_ctc}</p>
                <p>cand expected ctc: {obj.expected_ctc}</p>
                <p>cand notice_period: {obj.notice_period}</p>
                <p>cand highest_qualification: {obj.highest_qualification}</p>
                <p>cand current_organization: {obj.current_organization}</p>
                <p>cand current_job_location: {obj.current_job_location}</p>
                <a
                  href={`${apiurl}${obj?.resume}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Resume
                </a>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    gap: "100px",
                  }}
                >
                  <Button
                    style={{ color: "lightblue" }}
                    variant="outlined"
                    // disabled={rejectAction || acceptAction}
                    // onClick={() => {
                    //   setRejectAction(false); // Ensure feedback form is hidden
                    //   setAcceptAction(true); // Show shortlist form
                    //   setCurrentApplicationId(obj.id); // Set the current application id
                    // }}
                    onClick={() => {
                      handleCompareResume(obj, "Accept");
											setCurrentApplicationId(obj.id); // Set the current application id

                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    danger
                    // disabled={acceptAction || rejectAction}
                    onClick={() => {
											handleCompareResume(obj, "Reject");
											setCurrentApplicationId(obj.id); // Set the current application id
                    }}
                    // onClick={() => {
                    //   setRejectAction(true); // Show feedback form
                    //   setAcceptAction(false); // Hide shortlist form
                    // }}
                  >
                    Reject
                  </Button>
                </div>
                {
                  acceptAction && (
                    currentApplicationId === obj.id && (
                    <form onSubmit={(e) => handleAccept(e, obj?.id)}>
                      <h2>Why you are accepting</h2>
                      <textarea
                        value={acceptReason}
                        onChange={(e) => setAcceptReason(e.target.value)}
                        required
                      />

                      <div className="buttons">
                        <Button
                          onClick={() => {
                            setRejectAction(false); // Hide feedback form
                            setAcceptAction(false); // Hide shortlist form
                          }}
                        >
                          Back
                        </Button>
                        <Button style={{ marginTop: "10px" }} htmlType="submit">
                          Accept candidate
                        </Button>
                      </div>
                    </form>
                  )
                  )
                }

                {rejectAction && currentApplicationId===obj.id &&(
                  <form onSubmit={(e) => handleReject(e, obj.id)}>
                    <h2>Why you are rejecting</h2>
                    <textarea
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                      required
                    />
                    <div className="buttons">
                      <Button
                        onClick={() => {
                          setRejectAction(false); // Hide feedback form
                          setAcceptAction(false); // Hide shortlist form
                        }}
                      >
                        Back
                      </Button>
                      <Button disabled={!feedback.trim()} htmlType="submit">
                        Submit Feedback
                      </Button>
                    </div>
                  </form>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </Main>
  );
};

export default Resumes;
