import React, { useState } from "react";
import {
	Form,
	Input,
	Button,
	Select,
	Steps,
	message,
	Modal,
	Slider,
	Radio,
	InputNumber,
} from "antd";
import { useAuth } from "../../../common/useAuth";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Main from "../Layout";
import "./PostJob.css";
import { useNavigate } from "react-router-dom";
import skillsList from "../../../common/skills";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import OrganizationTerms from "./OrganizationTerms";
import CompanyCode from "./CompanyCode";

const { Step } = Steps;
const { Option } = Select;
const gemini_api_key = process.env.REACT_APP_GOOGLE_AI_API_KEY

const jobLevels = ["Entry", "Mid", "Senior", "Lead"];

const interviewModes = [
	{ value: "face_to_face", label: "Face-to-Face" },
	{ value: "online", label: "Online" },
	{ value: "telephone", label: "Telephone" },
];

const interviewTypes = [
	{ value: 'non_technical', label: 'Non Tech' },
	{ value: 'technical', label: 'Tech' },
	{ value: 'assignment', label: 'Assignment' }
]


const jobTypeItems = [
	'Full Time',
	'Part Time',
	'Intern',
	'Consultant'
]

const internTimePeriod = [
	'1 Month',
	'2 Months',
	'3 Months',
	'4 Months',
	'5 Months',
	'6 Months',
	'7 Months',
	'8 Months',
	'9 Months',
	'10 Months',
	'11 Months',
	'1 Year'
]


const listOfVisa = ['H1 Visa', 'L1 Visa', 'TN Permit Holder', 'Green Card Holder', 'US Citizen', 'Authorized to work in US']

const probationPeriodItems = [
	'1 Month',
	'2 Months',
	'3 Months',
	'4 Months',
	'5 Months',
	'6 Months',
	'7 Months',
	'8 Months',
	'9 Months',
	'10 Months',
	'11 Months',
	'1 Year',
	'1 and half Year',
	'2 Years',
	'None'
]

const JobPosting = () => {
	const [form] = Form.useForm();
	const [currentStep, setCurrentStep] = useState(0);
	const [companyCode, setCompanyCode] = useState("");
	const [interviewers, setInterviewers] = useState([{
		interviewerName: "",
		interviewerEmail: ""
	}]);
	// const [selectedInterviewer, setSelectedInterviewer] = useState(0)
	const [termsData, setTermsData] = useState(null);
	const { apiurl, token } = useAuth();
	const [ctcRange, setCtcRange] = useState([2, 4]);
	const [primarySkills, setPrimarySkills] = useState([]);
	const [secondarySkills, setSecondarySkills] = useState([]);
	const navigate = useNavigate();
	const [interviewRounds, setInterviewRounds] = useState([]);
	const [noticePeriod, setNoticePeriod] = useState("");
	const [yrsRange, setYrsRange] = useState([0, 5])
	const [noticeTime, setNoticeTime] = useState(0);
	const [jobType, setJobType] = useState("");
	const [probationTime, setProbationTime] = useState("")
	const [internTime, setInternTime] = useState("")
	const [consultantTime, setConsultantTime] = useState("")

	const [jobTitle, setJobTitle] = useState("");
	const [jobDescription, setJobDescription] = useState("");
	const [primarySkillsSet, setPrimarySkillsSet] = useState(skillsList)

	const fetchSkillsUsingAI = async (jobTitle) => {

		try {

			const url = `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=${gemini_api_key}`
			const response = await fetch(url, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					contents: [{
						parts: [{
							text: `Generate primary skills for a ${jobTitle} role.
							Return output in strict JSON format without code blocks or extra text:
							{
								"description": "<job description 3 lines> ",
								"primary_skills": ["skill1", "skill2", "skill3"..."max->skill5"],
							}
							Ensure that every time the response structure remains the same.`
						}]
					}],
				}),
			});

			if (!response.ok) {
				throw new Error(`API Error: ${response.status} - ${response.statusText}`);
			}

			const data = await response.json();

			let responseText = data.candidates[0]?.content?.parts[0].text.trim() || "";

			responseText = responseText.replace(/^```json\n|```$/g, "").trim();

			if (!responseText.startsWith("{") || !responseText.endsWith("}")) {
				throw new Error("Invalid JSON format received from AI");
			}

			const parsedData = JSON.parse(responseText);

			form.setFieldsValue({
				job_description: parsedData.description,
				primary_skills: parsedData.primary_skills,
			})


			setPrimarySkills(parsedData.primary_skills);
			setPrimarySkillsSet(prev => prev.filter(skill => !parsedData.primary_skills.includes(skill)));
			setJobDescription(parsedData.description);

		} catch (error) {
			console.error("Error fetching job skills:", error.message);
		}
	}

	const [formValues, setFormValues] = useState({
		job_title: "",
		job_department: "",
		job_description: "",
		primary_skills: [],
		secondary_skills: [],
		years_of_experience: "",
		ctc: "",
		job_locations: "",
		job_type: "",
		job_level: "",
		qualifications: "",
		timings: "",
		other_benefits: "",
		working_days_per_week: "",
		decision_maker: "",
		decision_maker_email: "",
		bond: "",
		rotational_shift: "",
		notice_period: "",
		age: "",
		gender: "",
		industry: "",
		differently_abled: "",
		languages: "",
		visa_status: "",
		qualification_department: '',
	});

	const fetchInterviewers = async () => {
		try {
			const response = await fetch(`${apiurl}/client/get-interviewers/`, {
				method: 'GET',
				headers: {
					"Authorization": `Bearer ${token}`
				}
			})
			const data = await response.json();
			if (data.error) {
				message.error(data.error);
				return
			}
			if (data === null || data.length == 0) {
				alert("Add interviewers before creating job post");
				navigate('/client/interviewers')
			}
			const formattedData = data.map((item) => ({
				"interviewerName": item.username,
				"interviewerEmail": item.email,
			}))
			console.log(formattedData)
			setInterviewers(formattedData)
		}
		catch (e) {
			console.error(e)
			message.error(e)
		}
	}

	const handleNextStepSubmit = async (values) => {
		setFormValues(values);
		setCurrentStep(3);
		fetchInterviewers();
	};

	const handleJobPostingSubmit = async (values) => {
		const ctc2 = `${ctcRange[0]} - ${ctcRange[1]} LPA`;
		const years_of_experience2 = `${yrsRange[0]} - ${yrsRange[1]} Years`
		const primarySkillsString = primarySkills.join(", ");
		const secondarySkillsString = secondarySkills.join(", ");

		const payload = {
			...values,
			formValues,
			acceptedterms: termsData,
			organization_code: companyCode,
			primary_skills: primarySkillsString,
			secondary_skills: secondarySkillsString,
			job_locations: formValues.job_locations.length > 0 ? formValues.job_locations.join(',') : " ",
			ctc: ctc2,
			years_of_experience: years_of_experience2,
			interview_rounds: interviewRounds,
			languages: formValues.languages.length > 0 ? formValues.languages.join(',') : " ",
			notice_time: (noticePeriod == 'need_to_serve_notice') ? noticeTime : '',
			time_period: jobType === "Intern" ? internTime : jobType === "Full Time" ? probationTime : jobType === "Consultant" ? `${consultantTime} years` : " ",
		};

		console.log("form values are ", JSON.stringify(payload))
		try {
			const response = await fetch(`${apiurl}/job-postings/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(payload),
			});

			const data = await response.json();
			if (!response.ok) {
				throw new Error(data.detail || "Failed to create job posting");
			}
			message.success("Job posting created successfully!");
			navigate('/');
		} catch (error) {
			message.error(error.message);
		}
	};

	const handleNumRoundsChange = (value) => {
		setInterviewRounds(
			Array.from({ length: value }, (_, index) => ({
				round_num: index + 1,
				name: "",
				email: "",
				type_of_interview: "",
			}))
		);
	};

	const handleInterviewRoundChange = (index, field, value) => {
		setInterviewRounds((prevRounds) => {
			const updatedRounds = [...prevRounds];
			if (field === "name") {
				const selectedInterviewer = interviewers.find(
					(interviewer) => interviewer.interviewerName === value
				);
				updatedRounds[index] = {
					...updatedRounds[index],
					name: value,
					email: selectedInterviewer?.interviewerEmail || "",
				};
			} else {
				updatedRounds[index] = {
					...updatedRounds[index],
					[field]: value,
				};
			}
			return updatedRounds;
		});
	};

	return (
		<Main defaultSelectedKey="2">
			<div className="organization-job-posting">
				<h1>Post a Job</h1>

				<Steps current={currentStep} className="steps-container">
					<Step title="Company Code" />
					<Step title="Agree to Terms" />
					<Step title="Job Posting" />
					<Step title="Interview Details" />
				</Steps>

				<div className="steps-content">
					{currentStep === 0 && (
						<CompanyCode setCompanyCode={setCompanyCode} companyCode={companyCode} setCurrentStep={setCurrentStep} />
					)}

					{currentStep === 1 && (
						<div>
							<OrganizationTerms companyCode={companyCode} setCurrentStep={setCurrentStep}
								termsData={termsData} setTermsData={setTermsData}
							/>
						</div>
					)}

					{currentStep === 2 && (

						<Form
							form={form}
							layout="vertical"
							onFinish={handleNextStepSubmit}
							initialValues={formValues}>
							<Form.Item
								label="Job Title"
								name="job_title"
								rules={[{ required: true, message: "Please enter job title" }]}>
								<Input value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
							</Form.Item>
							<Form.Item>

								{jobTitle && (
									<Button type="primary" onClick={() => {
										console.log(jobTitle);
										fetchSkillsUsingAI(jobTitle)
									}}
										disabled={!jobTitle}>
										Generate Primary Skills and Description
									</Button>
								)}
							</Form.Item>

							<Form.Item
								label="Job Department"
								name="job_department"
								rules={[
									{ required: true, message: "Please enter job department" },
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Job Description"
								name="job_description"
								rules={[
									{ required: true, message: "Please enter job description" },
								]}>
								<Input.TextArea rows={4} value={jobDescription} />
							</Form.Item>

							<Form.Item
								label="Notice Period"
								name="notice_period"
								rules={[
									{ required: true, message: "Select a Notice Period" },
								]}>
								<Select placeholder="Select a notice period" onChange={(e) => setNoticePeriod(e)}
								>
									<Option key="serving_notice" value="serving_notice">Serving notice</Option>
									<Option key='need_to_serve_notice' value='need_to_serve_notice'>Need to serve notice</Option>
									<Option key='immediate_joining' value='immediate_joining'>Immediate Joining</Option>
								</Select>

							</Form.Item>

							{noticePeriod === 'need_to_serve_notice' &&
								<Form.Item
									label="Notice Time Period"
									name="notice_time"
									rules={[
										{ required: true, message: "Select a Time Period" },
									]}>
									<Select placeholder="Select a Time period" onChange={(value) => setNoticeTime(value)}>
										<Option key="15" value="15">15 Days</Option>
										<Option key="30" value="30">30 Days</Option>
										<Option key="45" value="45">45 Days</Option>
										<Option key="2M" value="2M">2 Months </Option>
										<Option key="6M" value="6M">6 Months </Option>
									</Select>

								</Form.Item>
							}

							<Form.Item
								label="Primary Skills"
								name="primary_skills"
								rules={[
									{ required: true, message: "Please select primary skills" },
								]}>
								<Select
									mode="multiple"
									allowClear
									placeholder="Select primary skills"
									onChange={(value) => setPrimarySkills(value)}
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}>
									{primarySkillsSet.map((skill) => (
										<Option key={skill} value={skill}>
											{skill}
										</Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item
								label="Secondary Skills"
								name="secondary_skills"
								rules={[
									{ required: true, message: "Please select secondary skills" },
								]}>
								<Select
									mode="multiple"
									allowClear
									placeholder="Select secondary skills"
									onChange={(value) => setSecondarySkills(value)}
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}>
									{skillsList.map((skill) => (
										<Option key={skill} value={skill}>
											{skill}
										</Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item
								label="Years of Experience"
								name="years_of_experience"
							>
								<Slider
									range
									min={0}
									max={70}
									defaultValue={yrsRange}
									value={yrsRange}
									onChange={setYrsRange}
								/>
								<div>
									<span>Min: {yrsRange[0]} Yrs</span> &nbsp;{" "}
									<span>Max: {yrsRange[1]} Yrs</span>
								</div>
							</Form.Item>

							<Form.Item
								label="CTC Range"
								name="ctc"
							>
								<Slider
									range
									min={1}
									max={50}
									defaultValue={ctcRange}
									value={ctcRange}
									onChange={setCtcRange}
								/>
								<div>
									<span>Min: {ctcRange[0]} LPA</span> &nbsp;{" "}
									<span>Max: {ctcRange[1]} LPA</span>
								</div>
							</Form.Item>

							<Form.Item
								label="Locations"
								name="job_locations"
								rules={[{ required: true, message: "Please enter at least one location" }]}
							>
								<Form.List
									name="job_locations"
									rules={[
										{
											validator: async (_, job_locations) => {
												if (!job_locations || job_locations.length < 1) {
													return Promise.reject(new Error("Please add at least one location"));
												}
											},
										},
									]}
								>
									{(fields, { add, remove }) => (
										<>
											{fields.map(({ key, name, fieldKey, ...restField }) => (
												<div
													key={key}
													style={{
														display: "flex",
														alignItems: "center",
														gap: "10px",
														marginBottom: "10px",
													}}
												>
													<Form.Item
														{...restField}
														name={name}
														fieldKey={fieldKey}
														rules={[{ required: true, message: "Location is required" }]}
													>
														<Input placeholder="Enter a location" />
													</Form.Item>
													<MinusCircleOutlined onClick={() => remove(name)} />
												</div>
											))}

											<Form.Item>
												<Button
													type="dashed"
													onClick={() => add()}
													icon={<PlusOutlined />}
													style={{ width: "100%" }}
												>
													Add Location
												</Button>
											</Form.Item>
										</>
									)}
								</Form.List>
							</Form.Item>


							<Form.Item
								label="Job Type"
								name="job_type"
								rules={[{ required: true, message: "Please enter job type" }]}>
								<Select
									placeholder="Choose the job Type"
									onChange={(value) => {
										setJobType(value);
									}}>
									{jobTypeItems.map((val) => (
										<Option value={val} key={val}>{val}</Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.job_type !== currentValues.job_type}>
								{({ getFieldValue }) => {
									const jobType = getFieldValue('job_type');
									if (jobType === 'Full Time') {
										return (
											<Form.Item
												label="Probation Period"
												name="probation_period"
												rules={[{ required: true, message: "Please specify Probation Period" }]}>
												<Select
													placeholder="Choose the probation period"
													onChange={(value) => setProbationTime(value)}>
													{probationPeriodItems.map((val) => (
														<Option value={val} key={val}>{val}</Option>
													))}
												</Select>
											</Form.Item>
										);
									}
									if (jobType === 'Intern') {
										return (
											<Form.Item
												label="Intern Time Period"
												name="intern_time_period"
												rules={[{ required: true, message: "Please select the intern time period" }]}>
												<Select
													placeholder="Choose the time period"
													onChange={(value) => setInternTime(value)}>
													{internTimePeriod.map((val) => (
														<Option value={val} key={val}>{val}</Option>
													))}
												</Select>
											</Form.Item>
										);
									}
									if (jobType === 'Consultant') {
										return (
											<Form.Item
												label="Consultant Time Period"
												name="consultant_time_period"
												rules={[{ required: true, message: "Please select the consultant time period" }]}>
												<Slider
													defaultValue={0}
													min={0}
													max={20}
													onChange={(value) => {
														setConsultantTime(value)
														form.setFieldValue('consultant_time_period', value);
													}} />
												<p>{consultantTime} years</p>
											</Form.Item>
										);
									}
									return null;
								}}
							</Form.Item>


							<Form.Item
								label="Job Level"
								name="job_level"
								rules={[
									{ required: true, message: "Please select job level" },
								]}>
								<Select placeholder="Select job level">
									{jobLevels.map((level) => (
										<Option key={level} value={level}>
											{level}
										</Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item
								label="Qualifications"
								name="qualifications"
								rules={[
									{ required: true, message: "Please enter qualifications" },
								]}>
								<Select placeholder={'Select Qualification'}>
									<Option key='UG' value='UG'>Under Graduate</Option>
									<Option key='PG' value='PG'>Post Graduate</Option>
									<Option key='Intermediate' value='Intermediate'>Intermediate</Option>
									<Option key='10th' value='10th'>'10th'</Option>
								</Select>
							</Form.Item>

							<Form.Item
								label="Qualification Department"
								name="qualification_department"
								rules={[{ required: true, message: "Please enter qualificaiton department" }]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Timings"
								name="timings"
								rules={[{ required: true, message: "Please enter timings" }]}>
								<Select>
									<Option key="Day Shift" value="Day Shift">Day Shift</Option>
									<Option key="Afternoon Shift" value="Afternoon Shift">Afternoon Shift</Option>
									{/* <Option key="Evening Shift" value="Evening Shift">Evening Shift</Option> */}
									<Option key="Night Shift" value="Night Shift">Night Shift</Option>
								</Select>
							</Form.Item>

							<Form.Item
								label="Other Benefits"
								name="other_benefits"
								rules={[
									{ required: true, message: "Please enter other benefits" },
								]}>
								<Input.TextArea rows={3} />
							</Form.Item>

							<Form.Item
								label='Age Limit'
								name='age_limit'
								rules={[
									{ required: true, message: "Please select the age limit" },
								]}
							>
								<Input placeholder="eg: 15-20yrs"></Input>
							</Form.Item>

							<Form.Item
								label="Gender Preferences"
								name="gender"
								rules={[
									{ required: true, message: "Please select the gender" },
								]}>
								<Select placeholder="Select any gender requirements">
									<Option key='Male' value="Male">Male</Option>
									<Option key='Female' value="Female">Female</Option>
									<Option key='Others' value="Others">Others</Option>
									<Option key='No Mention' value="No Mention">No Mention</Option>
									<Option key='Both Male and Female' value="Both Male and Female">Both Male and Female</Option>
								</Select>
							</Form.Item>


							<Form.Item
								label="Languages"
								name="languages"
								rules={[{ required: true, message: "Please enter at least one language" }]}
							>
								<Form.List
									name="languages"
									rules={[
										{
											validator: async (_, languages) => {
												if (!languages || languages.length < 1) {
													return Promise.reject(new Error("Please add at least one language"));
												}
											},
										},
									]}
								>
									{(fields, { add, remove }) => (
										<>
											{fields.map(({ key, name, fieldKey, ...restField }) => (
												<div
													key={key}
													style={{
														display: "flex",
														alignItems: "center",
														gap: "10px",
														marginBottom: "10px",
													}}
												>
													<Form.Item
														{...restField}
														name={name}
														fieldKey={fieldKey}
														rules={[{ required: true, message: "Language is required" }]}
													>
														<Input placeholder="Enter a language" />
													</Form.Item>
													<MinusCircleOutlined onClick={() => remove(name)} />
												</div>
											))}
											<Form.Item>
												<Button
													type="dashed"
													onClick={() => add()}
													icon={<PlusOutlined />}
													style={{ width: "100%" }}
												>
													Add Language
												</Button>
											</Form.Item>
										</>
									)}
								</Form.List>
							</Form.Item>

							<Form.Item
								label="Industry"
								name="industry"
								rules={[
									{
										required: true,
										message: "Please enter the industry",
									},
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Differently Abled Preference"
								name="differently_abled"
								rules={[
									{ required: true, message: "Please select the below options" },
								]}>
								<Select placeholder='Select below'>
									<Option key='Yes' value="Yes">Yes (Only for differently abled)</Option>
									<Option key='No' value="No">No</Option>
									<Option key='No Mention' value="No Mention">No Mention</Option>
								</Select>
							</Form.Item>


							<Form.Item label="Visa Status" name='visa_status'>
								<Radio.Group>
									{listOfVisa.map((val, index) => (
										<Radio key={index} value={val}>
											{val}
										</Radio>
									))}
								</Radio.Group>
							</Form.Item>

							<Form.Item
								label="Working Days Per Week"
								name="working_days_per_week"
								rules={[
									{
										required: true,
										message: "Please enter working days per week",
									},
								]}>
								<InputNumber min={1} max={7} />
							</Form.Item>

							<Form.Item
								label="Decision Maker"
								name="decision_maker"
								rules={[
									{ required: true, message: "Please enter decision maker" },
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Decision Maker Email"
								name="decision_maker_email"
								rules={[
									{
										required: true,
										message: "Please enter decision maker email",
									},
									{ type: "email", message: "Please enter a valid email" },
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Bond"
								name="bond"
								rules={[
									{ required: true, message: "Please specify bond terms" },
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Rotational Shift"
								name="rotational_shift"
								rules={[
									{
										required: true,
										message: "Please specify rotational shift",
									},
								]}>
								<Select placeholder="Select shift option">
									<Option value="yes">Yes</Option>
									<Option value="no">No</Option>
								</Select>
							</Form.Item>

							<Form.Item
								label="Number of positions"
								name="num_of_positions"
								rules={[
									{ required: true, message: "Please enter the number of positions" },
								]}>
								<InputNumber min={1} max={10} defaultValue={1} />
							</Form.Item>

							<Form.Item
								label="Last date to submit candidates"
								name="job_close_duration"
								rules={[
									{ required: true, message: "Please select the last day to submit the posts" },
								]}>
								<DatePicker
									disabledDate={(current) => current && current.isBefore(dayjs(), 'day')}
									format="YYYY-MM-DD"
								/>
							</Form.Item>


							<Button type="primary" onClick={() => setCurrentStep(1)}>
								Previous
							</Button>
							<Button
								style={{ marginLeft: 10 }}
								type="primary"
								htmlType="submit">
								Next Step
							</Button>
						</Form>
					)}

					{currentStep === 3 && (
						<div>
							<Form.Item
								label="Number of Interview Rounds"
								name="num_rounds"
								rules={[
									{ required: true, message: "Please enter number of rounds" },
								]}>
								<Input
									type="number"
									onChange={(e) => handleNumRoundsChange(e.target.value)}
								/>
							</Form.Item>

							{interviewRounds.map((round, index) => (
								<div key={index} style={{ marginBottom: 10 }}>
									<h4>Round {round.round_num}</h4>
									<Form.Item
										label="Interviewer Name"
										name={`round_${round.round_num}_name`}
										rules={[
											{
												required: true,
												message: "Please select an interviewer",
											},
										]}
									>
										<Select
											placeholder="Select Interviewer"
											onChange={(value) => handleInterviewRoundChange(index, "name", value)}
										>
											{interviewers.map((interviewer) => (
												<Select.Option key={interviewer.interviewerEmail} value={interviewer.interviewerName}>
													{interviewer.interviewerName}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item
										label="Interviewer Email"
										name={`round_${round.round_num}_email`}
										rules={[
											{
												required: true,
												message: "Please enter interviewer email",
											},
										]}>
										<Input
											value={round.email}
											// onChange={(e) =>
											// 	handleInterviewRoundChange(
											// 		index,
											// 		"email",
											// 		e.target.value
											// 	)
											// }
											disabled
											placeholder="Email will be auto filled"
										/>
									</Form.Item>
									<Form.Item
										label="Type of Interview"
										name={`round_${round.round_num}_type`}
										rules={[
											{
												required: true,
												message: "Please select type of Interview",
											},
										]}>
										<Select
											value={round.mode_of_interview}
											onChange={(value) =>
												handleInterviewRoundChange(
													index,
													"type_of_interview",
													value
												)
											}>
											{interviewTypes.map((mode) => (

												<Option key={mode.value} value={mode.value}>
													{mode.label}
												</Option>
											))}
										</Select>
									</Form.Item>

									<Form.Item
										label="Mode of Interview"
										name={`round_${round.round_num}_mode`}
										rules={[
											{
												required: true,
												message: "Please select interview type",
											},
										]}>
										<Select
											value={round.type_of_interview}
											onChange={(value) =>
												handleInterviewRoundChange(
													index,
													"mode_of_interview",
													value
												)
											}>
											{interviewModes.map((mode) => (
												<Option key={mode.value} value={mode.value}>
													{mode.label}
												</Option>
											))}
										</Select>
									</Form.Item>
								</div>
							))}

							<Button type="primary" onClick={() => setCurrentStep(6)}>
								Previous
							</Button>
							<Button
								style={{ marginLeft: 10 }}
								type="primary"
								htmlType="submit"
								onClick={() => handleJobPostingSubmit(formValues)}>
								Submit Job Posting
							</Button>
						</div>
					)}
				</div>
			</div>
		</Main>
	);
};

export default JobPosting;
