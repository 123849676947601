import React from 'react'
import Main from './Layout'

const Interviewer = () => {
    return (
        <Main defaultSelectedKey="1">
            <h2>Welcome to HireSync!</h2>
        </Main>
    )
}

export default Interviewer