import React, { useContext, useState, useEffect } from "react";
import { useAuth } from "../../../common/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import "./ReceivedData.css";
import Main from "../Layout";
const apiurl = process.env.REACT_APP_BACKEND_URL;
const ReceivedData = () => {
	const [data, setData] = useState("");
	const navigate = useNavigate();
	const { token } = useAuth();

	const getData = async () => {
		try {
			const response = await fetch(`${apiurl}/client/get-resumes/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			const data = await response.json()
			setData(data);
			console.log("data is ", data);
		}
		catch (e) {
			console.error(e);
		}
	}
	useEffect(() => {
		if (token) {
			getData();
		}
	}, [token]);

	const handleResumes = (id) => {
		try {
			navigate(`/client/get-resumes/${id}`);
		} catch (error) {
			console.log(error);
		}
	};

	console.log("all resumes are getting  ", data)
	return (
		<Main defaultSelectedKey="3">
			<div className="data-overflow">
				{data && data.length > 0 ? (
					<table>
						<thead>
							<tr>
								<th>Job ID</th>
								<th>Num Of Positions</th>
								<th>Last date to submit</th>
								<th>Applications Received</th>
								<th>Processing</th>
								<th>Selected</th>
								<th>Pending</th>
								<th>Rejected</th>
								<th>View Resume</th>
							</tr>
						</thead>
						<tbody>
							{data.map((job) => (
								<tr key={job.job_id}>
									<td>{job.job_id}</td>
									<td>{job.num_of_postings}</td>
									<td>{job.last_date}</td>
									<td>{job.applications_sent}</td>
									<td>{job.processing}</td>
									<td>{job.selected}</td>
									<td>{job.pending}</td>
									<td>{job.rejected}</td>

									<td>
										<button onClick={() => handleResumes(job.job_id)}>
											View Resumes
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					<div className="no-applications">There are no applications</div>
				)}
			</div>
		</Main>
	);
};

export default ReceivedData;
