import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/context";
import { Button } from "antd";
import { useAuth } from "../../../common/useAuth";
const JobStatus = ({ job, onClose }) => {
	const { token, apiurl } = useAuth();
	const [data, setData] = useState();


	useEffect(() => {
		fetch(`${apiurl}/api/client/recruiter_data/${job.id}`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setData(data.data);
				console.log(data.data);
			})
			.catch((error) => console.error(error));
	}, [token]);
	return (

		<table>
			<thead>
				<tr>
					<th>Job ID</th>
					<th>Recruiter Name</th>
					<th>No. of Resumes Sent</th>
					<th>No. of Resumes Selected</th>
					<th>No. of Resumes Rejected</th>
					<th>No. of Pending Resumes</th>
				</tr>
			</thead>
			<tbody>
				{data && (
					<tr>
						<td>{job.id}</td>
						<td>{data.recruiter_name}</td>
						<td>{data.resume_sent}</td>
						<td>{data.resume_selected}</td>
						<td>{data.resume_rejected}</td>
						<td>{data.resume_pending}</td>
					</tr>
				)}
			</tbody>
		</table>

	);
};

export default JobStatus;
