import React, { useEffect, useState } from 'react'
import Main from '../Layout'
import { useAuth } from '../../../common/useAuth'
import { Table, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

const apiurl = process.env.REACT_APP_BACKEND_URL;
const ParticularJobEdit = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [originalJob, setOriginalJob] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiurl}/client/not-approval-jobs/?id=${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const data = await response.json();
      setJob(data.data);
      setOriginalJob(data.job);
      if (data.error) {
        message.error(data.error);
        navigate(-1);
      }
    }
    catch (e) {
      console.error("Error fetching job details ", e);
      message.error(e.error);
      navigate(-1);
    }
  }

  const columns = [
    {
      title: "Round",
      dataIndex: "round_num",
      key: "round_num",
    },
    {
      title: "Interviewer Name",
      dataIndex: ["name","username"],
      key: "username",
    },
    {
      title: "Interviewer Email",
      dataIndex: ["name","email"],
      key: "email",
    },
    {
      title: "Type of Interview",
      dataIndex: "type_of_interview",
      key: "type_of_interview",
    },
    {
      title: "Mode of Interview",
      dataIndex: "mode_of_interview",
      key: "mode_of_interview",
    },
  ];

  const handleAccepted = async () => {
    try {
      const response = await fetch(`${apiurl}/accept-job-post/?id=${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.error) {
        message.error(data.error);
        // navigate(-1);
      }
      message.success(data.message);
      if (data.message) {
        // window.location.reload();
        navigate(-1)
      }
      // window.location.reload();
    }
    catch (e) {
      console.error(e);
      message.error(e.error);
      // navigate(-1);
    }
  }

  const handleRejected = async () => {
    try {
      const response = await fetch(`${apiurl}/reject-job-post/?id=${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (data.error) {
        message.error(data.error);
        navigate(-1);
      }
      message.success(data.message);
      window.location.reload();
    }
    catch (e) {
      console.error(e);
      message.error(e.error);
      navigate(-1);
    }
  }

  const renderField = (field, label) => {
    const isDifferent = job?.[field] !== originalJob?.[field];
    return (
      <div className="job-detail">
        <span>{label}:</span>
        <div style={{ color: isDifferent ? "#f58424" : "inherit" }}>
          {job?.[field] || "N/A"}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <Main>
      <div className="job-details-manager-main">
        {job && <h1>Job Edit requested by {job.edited_by_username}</h1>}
        {renderField("job_title", "Job Title")}
        {renderField("job_description", "Job Description")}
        {renderField("job_department", "Job Department")}
        {renderField("primary_skills", "Primary Skills")}
        {renderField("secondary_skills", "Secondary Skills")}
        {renderField("years_of_experience", "Years of Experience")}
        {renderField("ctc", "CTC")}
        {renderField("rounds_of_interview", "Rounds of Interview")}
        {renderField("job_locations", "Job Location")}
        {renderField("job_type", "Job Type")}
        {renderField("job_level", "Job Level")}
        {renderField("qualifications", "Qualifications")}
        {renderField("qualification_department", "Qualification Department")}
        {renderField("timings", "Timings")}
        {renderField("other_benefits", "Other Benefits")}
        {renderField("working_days_per_week", "Working Days per Week")}
        {renderField("decision_maker", "Decision Maker")}
        {renderField("decision_maker_email", "Decision Maker Email")}
        {renderField("bond", "Bond")}
        {renderField("rotational_shift", "Rotational Shift")}

        <hr />
        <h2>Interviewers Data</h2>
        <Table
          columns={columns}
          dataSource={job?.interview_details || []}
          rowKey="id"
          pagination={false}
        />
        <hr />
        <Button onClick={() => handleAccepted()}>Accept</Button>
        <Button onClick={() => handleRejected()} danger>
          Reject
        </Button>
      </div>
    </Main>
  );
}

export default ParticularJobEdit;
