import React, { useState, useEffect } from "react";
import Main from "./Layout";
import { useAuth } from "../../common/useAuth";
import { Button, message, Table, Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";

const apiurl = process.env.REACT_APP_BACKEND_URL;
const ScheduledInterviews = () => {
  const { token } = useAuth();
  const [data, setData] = useState([]);
  const [resumeData, setResumeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false); // For controlling the modal visibility

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${apiurl}/interviewer/scheduled-interviews/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (data.error) {
        message.error(data.error);
        return;
      } else if (data.message) {
        message.success(data.message);
        return;
      }
      setData(data);
      console.log(data, "is the response data");
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  const columns = [
    {
      title: "Job ID",
      dataIndex: "job_id",
      key: "job_id",
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Candidate Name",
      dataIndex: "candidate_name",
      key: "candidate_name",
    },
    {
      title: "Candidate Profile",
      key: "candidateProfile",
      render: (record) => (
        <Button onClick={() => handleViewProfile(record?.application_id)}>
          View
        </Button>
      ),
    },
    {
      title: "Interviewer Name",
      dataIndex: "interviewer_name",
      key: "interviewer_name",
    },
    {
      title: "Rounds of Interview",
      dataIndex: "round_of_interview",
      key: "round_of_interview",
    },
    {
      title: "Scheduled Time",
      dataIndex: "schedule_date",
      key: "schedule_date",
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: "Conduct Interview",
      dataIndex: "interview_id",
      key: "interview_id",
      render: (interview_id, record) => {
        if (record.status === "completed") {
          return <p>Completed</p>;
        } else if (record.status === "selected") {
          return <p>Shortlisted</p>;
        } else if (record.status === "rejected") {
          return <p>Rejected</p>;
        }
        return (
          <Button
            onClick={() =>
              navigate(`/interviewer/conduct-interview/${interview_id}`)
            }
          >
            Conduct Interview
          </Button>
        );
      },
    },
  ];

  const handleViewProfile = async (id) => {
    setModalVisible(true); // Show the modal
    setLoading(true); // Start loading spinner

    try {
      const response = await fetch(`${apiurl}/get-resume/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (data.error) {
        message.error(data.error);
        return;
      } else if (data.message) {
        message.success(data.message);
        return;
      }
      setResumeData(data);
      console.log(data, "is the resume data");
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <Main defaultSelectedKey="2">
      <h1>Scheduled Interviews</h1>
      {message && message.success && (
        <div className="success">{message.success}</div>
      )}
      {message && message.error && <div className="error">{message.error}</div>}
      {data && <Table columns={columns} dataSource={data}></Table>}

      {/* Modal for showing resume data */}
      <Modal
        title="Candidate Profile"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={600}
      >
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Spin size="large" />
            <p>Loading...</p>
          </div>
        ) : (
          resumeData && (
            <>
              <div style={{ marginBottom: '10px' }}>
                <p><strong>Name:</strong> {resumeData.candidate_name}</p>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <p><strong>Experience:</strong> {resumeData.experience}</p>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <p><strong>Current CTC:</strong> {resumeData.current_ctc}</p>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <p><strong>Current Job Type:</strong> {resumeData.current_job_type}</p>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <p><strong>Expected CTC:</strong> {resumeData.expected_ctc}</p>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <p><strong>Notice Period:</strong> {resumeData.notice_period}</p>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <p><strong>Resume: </strong>
                  <a
                    href={`${apiurl}${resumeData.resume}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#1890ff', textDecoration: 'none' }}
                  >
                    View Resume
                  </a>
                </p>
              </div>
            </>

          )
        )}
      </Modal>
    </Main>
  );
};

export default ScheduledInterviews;
