import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Spin } from "antd";
import { Link } from "react-router-dom";
import Main from "./Layout";
import { useAuth } from "../../common/useAuth";
import moment from "moment";

const MyTasks = () => {
  const [jobList, setJobList] = useState([]);
  const [jobResumes, setJobResumes] = useState(null);
  const [loading, setLoading] = useState(false); // To control the loading state for fetching resumes
  const [visible, setVisible] = useState(false); // To control the visibility of the modal
  const { apiurl, token } = useAuth();

  useEffect(() => {
    fetchJobPosts();
  }, []);

  const fetchJobPosts = async () => {
    try {
      const response = await fetch(`${apiurl}/rec-job-postings/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setJobList(data);
    } catch (error) {
      console.error("Error fetching job posts:", error);
    }
  };

  const fetchResumesSent = (jobData) => {
    setLoading(true); // Set loading to true when the fetch starts
    setVisible(true); // Show the modal

    if (token) {
      // Use the job_id from the jobData argument
      fetch(`${apiurl}/recruiters/resumes/?job_id=${jobData.id}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`, // Pass the token for authorization
        },
      })
        .then((response) => {
          if (!response.ok) {
            // If the response is not OK, throw an error
            throw new Error("Failed to fetch resumes.");
          }
          return response.json();
        })
        .then((data) => {
          console.log(data, "is the fetched resumes data");
          setJobResumes(data); // Set the fetched resumes into state
          setLoading(false); // Set loading to false when the fetch is complete
        })
        .catch((error) => {
          console.error("Error fetching resumes:", error);
          setLoading(false); // Set loading to false if an error occurs
        });
    } else {
      console.log("No token found, user not authenticated.");
    }
  };

  const columns = [
    {
      title: "Client Name",
      dataIndex: ["username", "username"],
      key: "username",
      render: (username) => username || "N/A",
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
      render: (jobTitle) => jobTitle || "N/A",
    },
    {
      title: "Recruiter Assigned",
      dataIndex: "is_assigned",
      key: "is_assigned",
      render: (isAssigned) =>
        isAssigned ? isAssigned.username : "Not Assigned",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => status || "Unknown",
    },
    {
      title: "Resumes Sent",
      key: "actions",
      render: (_, record) => (
        <Button onClick={() => fetchResumesSent(record)}>View</Button>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) =>
        record.status === "opened" ? (
          <Link to={`${record.id}`}>View complete Details</Link>
        ) : (
          <p style={{ color: "red" }}>Job Post Closed</p>
        ),
    },
  ];

 

	const columns2=[
		{
			title: "cand_name",
			dataIndex:["resume", "candidate_name"],
			key: "candidateName",
		},
		{
			title: "Candidate Mail",
			dataIndex:["resume", "candidate_email"],
			key: "candidateMail",
		},
		{
			title: "Contact",
			dataIndex:["resume", "contact_number"],
			key: "candidateNumber",
		},
		{
			title: "applicationStatus",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Date",
			dataIndex: "application_date",
			key: "date",
			render: (text) => moment(text).format("YYYY-MM-DD"), // Format date here
		},
		{
			title: "Round",
			dataIndex: 'round_num',
			key: "round",	
		},
		// {
		// 	title: "Skills",
		// 	dataIndex: "skills",
		// 	key: "skills",
		// },
	]

  return (
    <Main>
      {jobList && jobList.length > 0 ? (
        <Table
          columns={columns}
          dataSource={jobList}
          rowKey="id"
          className="custom-table"
          pagination={false}
        />
      ) : (
        <div className="no-postings">There are no postings</div>
      )}

      {/* Ant Design Modal to show resumes */}
      <Modal
        title="Resumes Sent"
        visible={visible}
        onCancel={() => setVisible(false)} // Close the modal
        footer={null} // No footer in this case
        width={800} // Customize width if needed
      >
        {loading ? (
          // Show spinner while loading
          <Spin size="large" />
        ) : (
          // Once resumes are fetched, show the resumes table
          <Table
           columns={columns2}
            dataSource={jobResumes } // Use the fetched data or dummy data
            rowKey="id"
            pagination={false}
          />
        )}
      </Modal>
    </Main>
  );
};

export default MyTasks;
