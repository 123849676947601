import { Button } from "antd";
import React from "react";
const apiurl = process.env.REACT_APP_BACKEND_URL;

export const CandidateNameColumn = ({ application }) => (
	<div className="cell">{application.candidate_name}</div>
);

export const SenderColumn = ({ application }) => (
	<div className="cell">{application.sender}</div>
);

export const ReceiverColumn = ({ application }) => (
	<div className="cell">{application.receiver}</div>
);

export const JobIdColumn = ({ application }) => (
	<div className="cell">{application.job_id}</div>
);

export const StatusColumn = ({ application }) => (
	<div className="cell">{application.job_status}</div>
);


export const OtherDetailsColumn = ({ application }) => (
	<div className="cell">{application.other_details || "N/A"}</div>
);


export const MessageColumn = ({ application }) => (
	<div className="cell">{application.message || "N/A"}</div>
);

export const CurrentOrganisationColumn = ({ application }) => (
	<div className="cell">{application.current_organisation || "N/A"}</div>
);

export const CurrentJobLocationColumn = ({ application }) => (
	<div className="cell">{application.current_job_location || "N/A"}</div>
);

export const CurrentJobTypeColumn = ({ application }) => (
	<div className="cell">{application.current_job_type}</div>
);

export const DateOfBirthColumn = ({ application }) => (
	<div className="cell">{application.date_of_birth || "N/A"}</div>
);

export const ExperienceColumn = ({ application }) => (
	<div className="cell">{application.experience} years</div>
);

export const CurrentCTCColumn = ({ application }) => (
	<div className="cell">₹{application.current_ctc}</div>
);

export const ExpectedCTCColumn = ({ application }) => (
	<div className="cell">₹{application.expected_ctc}</div>
);

export const NoticePeriodColumn = ({ application }) => (
	<div className="cell">{application.notice_period} days</div>
);

export const JobStatusColumn = ({ application }) => (
	<div className="cell">{application.job_status}</div>
);


export const SkillColumn = ({ application, skill }) => (
	<div className="cell">
		{application.skillset[skill]
			? `${application.skillset[skill]} years`
			: "0 years"}
	</div>
);

// export const PrimarySkillsColumn = ({ application, skill }) => (
// 	<div className="cell">
// 		{application.skillset["primary_skills"][skill]
// 			? `${application.skillset["primary_skills"][skill]} years`
// 			: "0 years"}
// 	</div>
// );

export const PrimarySkillsColumn = ({ application }) => (
	<div className="cell">
		{application.primary_skills.map((skill) => (
			<div className="flex " style={{ justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
				<div>{skill.skill}</div>
				<div>{skill.years_of_experience} years</div>
			</div>
		))}
	</div>
);

export const SecondarySkillsColumn = ({ application, skill }) => (
	<div className="cell">
		{application.secondary_skills.map((skill) => (
			<div className="flex " style={{ justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
				<div>{skill.skill}</div>
				<div>{skill.years_of_experience} years</div>
			</div>
		))}
	</div>
);

export const ResumeColumn = ({ application }) => (
	<div className="cell">
		<a
			href={`${apiurl}${application.resume}`}
			target="_blank"
			rel="noopener noreferrer">
			View Resume
		</a>
	</div>
);

export const ActionsColumn = ({ application, handleAction }) => {
	const handleShortlist = () => {
		handleAction(application.id, "Shortlisted");
	};

	const handleReject = () => {
		handleAction(application.id, "Reject");
	};

	return (
		<div className="cell">
			{application.status === "hold" && (
				<div className="action-btns">
					<Button onClick={handleShortlist}>Shortlist</Button>
					<Button onClick={handleReject}>Reject</Button>
				</div>
			)}
			{application.status === "pending" ? (
				<div className="action-btns">
					<Button onClick={handleShortlist}>Shortlist</Button>
					<Button onClick={handleReject}>Reject</Button>
					<Button onClick={() => handleAction(application.id, "Hold")}>
						Hold
					</Button>
				</div>
			) : (
				<p></p>
			)}
		</div>
	);
};
