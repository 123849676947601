import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Button,
	Form,
	Input,
	Typography,
	Alert,
	Tooltip,
	Row,
	Col,
	message,
	Select,
	List,
} from "antd";
import {
	InfoCircleOutlined,
	UserOutlined,
	MailOutlined,
} from "@ant-design/icons";
import "./AddRecruiter.css";
import { useAuth } from "../../../common/useAuth";

const { Title } = Typography;


const validateUsername = (_, value) => {
	if (!value || /^[a-zA-Z0-9_]+$/.test(value)) {
		return Promise.resolve();
	}
	return Promise.reject(
		new Error("Username should contain only letters, numbers, and underscores.")
	);
};

const validateEmail = (_, value) => {
	if (!value || /\S+@\S+\.\S+/.test(value)) {
		return Promise.resolve();
	}
	return Promise.reject(new Error("Please enter a valid email address."));
};

const { Option } = Select

const AddRecruiters = ({ onclose }) => {
	const [user, setUser] = useState({
		username: "",
		email: "",
		alloted_to: "",
	});

	const { apiurl, token } = useAuth();

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState("");
	const [error, setError] = useState(null);
	const [recruiterList, setRecruiterList] = useState([])

	const fetchRecruiters = async () => {
		try {
			const response = await fetch(`${apiurl}/org/get-recruiters/`, {
				method: 'GET',
				headers: {
					"Authorization": `Bearer ${token}`
				}
			})
			if (!response.ok) {
				return message.error("Unable to fetch the recruiters");
			}
			const data = await response.json();
			if (data.error) {
				return message.error(data.error);
			}
			setRecruiterList(data.data)
		}
		catch (e) {
			console.error(e);
			message.error(e);
		}
	}

	useEffect(() => {
		if (token) {
			fetchRecruiters();
		}
	}, [token])

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setUser((prevUser) => ({
			...prevUser,
			[name]: value,
		}));
	};

	const handleSubmit = async () => {
		setLoading(true);
		setError(null);
		console.log("alloted to ", user)
		try {
			const response = await fetch(`${apiurl}/agency/recruiters/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
				body: JSON.stringify({
					username: user.username,
					email: user.email,
					alloted_to: user.alloted_to
				}),
			});

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.error || "Failed to register user");
			}
			onclose();
			setLoading(false);
			setSuccess("Account Created Successfully");
			setError(null);
			setUser({ username: "", email: "" });
		} catch (error) {
			setLoading(false);
			setError(error.message);
			setSuccess("");
		}
	};

	return (
		<Form
			onFinish={handleSubmit}
			className="add-rec-form"
			noValidate
			autoComplete="off">
			<Title level={2} style={{ textAlign: "center" }}>
				Create an account
			</Title>

			{error && <Alert message={error} type="error" showIcon />}
			{success && <Alert message={success} type="success" showIcon />}

			<Row gutter={16}>
				<Col span={24}>
					<Form.Item
						name="username"
						rules={[{ required: true, validator: validateUsername }]}
						validateTrigger="onBlur">
						<Input
							name="username"
							value={user.username}
							onChange={handleInputChange}
							placeholder="Enter your username"
							prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
							suffix={
								<Tooltip title="Username should contain only letters, numbers, and underscores">
									<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
								</Tooltip>
							}
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						name="email"
						rules={[{ required: true, validator: validateEmail }]}
						validateTrigger="onBlur">
						<Input
							name="email"
							value={user.email}
							onChange={handleInputChange}
							placeholder="Enter your email"
							prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						name="alloted_to"
						rules={[{ required: true, }]}>
						<Select
							placeholder="Select the user in dropdown"
							onChange={(value) => setUser(prevUser => ({ ...prevUser, alloted_to: value }))}
						>
							{recruiterList && recruiterList.map((item) => (
								<Select.Option key={item.id} value={item.id}>
									{item.name} ({item.role})
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col span={12} offset={6}>
					<Form.Item>
						<Button type="primary" htmlType="submit" block loading={loading}>
							Create account
						</Button>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};

export default AddRecruiters;
