import React, { useState, useEffect } from 'react';
import './RecTaskTracking.css'
import File from '../../../../images/agency/rec-dashboard/File.svg'
import Completed from '../../../../images/agency/rec-dashboard/Completed.svg'
import Incomplete from '../../../../images/agency/rec-dashboard/Incomplete.svg'
import PlayButton from '../../../../images/agency/rec-dashboard/playbutton.svg'
import Date from '../../../../images/agency/rec-dashboard/Date.svg'
import { FilterOutlined, PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useAuth } from '../../../common/useAuth';


const Row = ({ job }) => {
    return (
        <div className="row">
            <div className="title">{job["Job Title"]}</div>
            <div className="num-of-positions">{job['Num of Positions']}</div>
            <div className={`priority ${job['Priority']}`}>
                {job["Priority"]}</div>
            <div className="last-date">
                <img src={Date} alt="" />
                {job["Due Date"]}
            </div>
            <div className="status">{job["Status"]}%</div>
            <div className="circle-color" style={{ cursor: 'pointer' }}>
                <Tooltip title={job["recruiter_name"]}>
                    {job["profile"] ? (
                        <img src={job["profile"]} alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                    ) : (
                        <UserOutlined style={{ fontSize: "20px", color: "#40a9ff" }} />
                    )}
                </Tooltip>
            </div>
        </div>
    );
};

const RecTaskTracking = () => {

    const { apiurl, token } = useAuth();
    const [jobData, setJobData] = useState([]);
    const [recruiters, setRecruiters] = useState([]);
    const [recentActivities, setRecentActivities] = useState([])

    const fetchData = async () => {
        const response = await fetch(`${apiurl}/manager/recruiters-task-tracking/`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        const data = await response.json();
        if (data.error) {
            console.log(data.error)
        }
        console.log(data)

    }

    useEffect(() => {
        if (token) {
            fetchData()
        }
    }, [token])


    // const jobData = [
    //     { "Job Title": "Frontend Engineer", "Num of Positions": 5, "Priority": "Medium", "Due Date": "15/02/2025", "Status": 50, "recruiter_name": "Kalki", "profile": "" },
    //     { "Job Title": "Data Scientist", "Num of Positions": 3, "Priority": "High", "Due Date": "18/02/2025", "Status": 90, "recruiter_name": "Kalki", "profile": "" },
    //     { "Job Title": "Software Developer", "Num of Positions": 12, "Priority": "High", "Due Date": "12/02/2025", "Status": 75, "recruiter_name": "Kalki", "profile": "" },
    //     { "Job Title": "Backend Engineer", "Num of Positions": 8, "Priority": "Low", "Due Date": "20/02/2025", "Status": 40, "recruiter_name": "Kalki", "profile": "" },
    //     { "Job Title": "Frontend Engineer", "Num of Positions": 5, "Priority": "Medium", "Due Date": "15/02/2025", "Status": 50, "recruiter_name": "Kalki", "profile": "" },
    //     { "Job Title": "Data Scientist", "Num of Positions": 3, "Priority": "High", "Due Date": "18/02/2025", "Status": 90, "recruiter_name": "Kalki", "profile": "" },
    //     { "Job Title": "Frontend Engineer", "Num of Positions": 5, "Priority": "Medium", "Due Date": "15/02/2025", "Status": 50, "recruiter_name": "Kalki", "profile": "" },
    //     { "Job Title": "Backend Engineer", "Num of Positions": 8, "Priority": "Low", "Due Date": "20/02/2025", "Status": 40, "recruiter_name": "Kalki", "profile": "" },
    //     { "Job Title": "Data Scientist", "Num of Positions": 3, "Priority": "High", "Due Date": "18/02/2025", "Status": 90, "recruiter_name": "Kalki", "profile": "" },
    // ];

    // const recruiters = [
    //     { "Profile": "", "name": "Kalki" },
    //     { "Profile": "", "name": "Ameer" },
    //     { "Profile": "", "name": "Lakshman" },
    //     { "Profile": "", "name": "Kalki" },
    //     { "Profile": "", "name": "Ameer" },
    //     { "Profile": "", "name": "Kalki" },
    //     { "Profile": "", "name": "Ameer" },
    //     { "Profile": "", "name": "Lakshman" },
    //     { "Profile": "", "name": "Kalki" },
    //     { "Profile": "", "name": "Ameer" },
    //     { "Profile": "", "name": "Kalki" },
    //     { "Profile": "", "name": "Ameer" },
    // ]

    const RecentActivites = [
        { "profile": "", "name": "kalki", "thumbnail": "10 Mins ago", "task": "Candidate_name resume sent", "jobtitle": "software developer" },
        { "profile": "", "name": "kalki2", "thumbnail": "10 Mins ago", "task": "Candidate_name resume sent", "jobtitle": "software developer" },
        { "profile": "", "name": "kalki", "thumbnail": "10 Mins ago", "task": "Candidate_name resume sent", "jobtitle": "software developer" },
        { "profile": "", "name": "kalki", "thumbnail": "10 Mins ago", "task": "Candidate_name resume sent", "jobtitle": "software developer" },
        { "profile": "", "name": "kalki", "thumbnail": "10 Mins ago", "task": "Candidate_name resume sent", "jobtitle": "software developer" },
    ]

    return (
        <div className='rec-task-tracking'>
            <div className="left">
                <div className="main-components">
                    <div className="section new">
                        <div className="top">
                            <span >New</span>
                            <img className='icon' src={File} alt="" />
                        </div>
                        <span className="count">5</span>
                        <span className="thumbnail"> +5 from yesterday</span>
                    </div>
                    <div className="section in-progress">
                        <div className="top">
                            <span>In Progress</span>
                            <img className='icon' src={PlayButton} alt="" />
                        </div>
                        <span className="count">25</span>
                        <span className="thumbnail"> +8 from yesterday</span>
                    </div>
                    <div className="section completed">
                        <div className="top">
                            <span>Completed</span>
                            <img className='icon' src={Completed} alt="" />
                        </div>
                        <span className="count">16</span>
                        <span className="thumbnail"> +8 from yesterday</span>
                    </div>
                    <div className="section missed-deadline">
                        <div className="top">
                            <span>Missed Deadline</span>
                            <img className='icon' src={Incomplete} alt="" />
                        </div>
                        <span className="count">24</span>
                        <span className="thumbnail"> +3 from yesterday</span>
                    </div>
                </div>

                <div className="job-tracking">
                    <div className="title">
                        <span className='heading'>Job Tracking</span>
                        <span className='filter'><FilterOutlined /> Filter</span>
                    </div>
                    <div className="sec-2">
                        <div className="filter1">
                            <span className='item'>New</span>
                            <span className='item selected'>In Progress</span>
                            <span className='item'>Completed</span>
                        </div>
                        <div className="sort">Sort by: dropdown</div>
                    </div>
                    <div className="job-list">
                        {jobData.map((job, index) => (
                            <Row key={index} job={job} />
                        ))}
                    </div>
                </div>
            </div>
            <div className="right">
                <div className="title">
                    <span className='heading'>Recruiters</span>
                    <span className='view-all'>View All</span>
                </div>
                <div className="list">
                    {recruiters.map((item, index) => (
                        <div className="profile" key={index}>
                            {item.Profile ? (
                                <img src={item.Profile} alt="" />
                            ) : (
                                <UserOutlined style={{ fontSize: "20px", padding: '10px', borderRadius: '50%', color: ["rgba(253, 242, 242, 1)", "rgba(254, 249, 238, 1)", "rgba(238, 253, 243, 1)"][index % 3], backgroundColor: ["rgba(55, 154, 230, 1)", "rgba(34, 204, 178, 1)", "rgba(35, 30, 59, 1)", "rgba(223, 28, 61, 1)"][index % 4] }} />
                            )}
                            <span className="name">{item.name}</span>
                        </div>
                    ))}
                    <div className="profile add-more">
                        <div className='logo'>
                            <PlusCircleOutlined style={{ fontSize: "40px", color: "gray" }} />
                        </div>
                        <span className="name">Add</span>
                    </div>
                </div>
                <div className="recent-activities">
                    <div className="title">Recent activites</div>
                    {RecentActivites.map((item, index) => (
                        <div className="activity">
                            <div className="activity-item">
                                {item.profile ? (
                                    <img src={item.profile} alt="" />
                                ) : (
                                    <UserOutlined style={{ fontSize: "20px", padding: '10px', borderRadius: '50%', color: ["rgba(253, 242, 242, 1)", "rgba(254, 249, 238, 1)", "rgba(238, 253, 243, 1)"][index % 3], backgroundColor: ["rgba(55, 154, 230, 1)", "rgba(34, 204, 178, 1)", "rgba(35, 30, 59, 1)", "rgba(223, 28, 61, 1)"][index % 4] }} />
                                )}
                                <div className="details">
                                    <span className="name">{item.name}</span>
                                    <span className="thumbnail">{item.thumbnail}</span>
                                    <span className="candidate">{item.task}</span>
                                    <span className="job">{item.jobtitle}</span>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RecTaskTracking;
