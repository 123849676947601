import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../common/useAuth';
import { message, Button, Form, Input, Upload, Image } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import './Certificates.css'

const Certificates = () => {
    const [data, setData] = useState();
    const { apiurl, token } = useAuth()
    const [add, setAdd] = useState(false);
    const [form] = Form.useForm();

    const fetchData = async (values) => {
        try {
            const response = await fetch(`${apiurl}/candidate/certificates/`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            })
            if (!response.ok) {
                message.error("Not able to fetch data")
            }
            const data = await response.json();
            if (data.error) {
                return message.error(data.error)
            }
            if (data.message) {
                message.warning(data.message)
            }
            setData(data)
        }
        catch (e) {
            message.error("unable to fetch the certificates")
        }
    }

    useEffect(() => {
        if (token) {
            fetchData()
        }
    }, [token])

    const handleAdd = async (values) => {
        const formData = new FormData();
        formData.append('certificate_name', values.certificate_name);
        formData.append('certificate_image', values.certificate_image[0].originFileObj);

        try {
            const response = await fetch(`${apiurl}/candidate/certificates/`, {
                method: 'POST',  // Use POST for adding new data
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                message.error("Failed to add certificate");
                return;
            }

            const result = await response.json();
            if (result.error) {
                return message.error(result.error);
            }

            message.success('Certificate added successfully');
            setAdd(false);
            fetchData();
        } catch (e) {
            message.error("Unable to add certificate");
        }
    }

    const handleRemove = async (certificateId) => {
        try {
            const response = await fetch(`${apiurl}/candidate/certificates/?id=${certificateId}`, {
                method: 'DELETE',
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                message.error("Failed to remove certificate");
                return;
            }

            message.success('Certificate removed successfully');
            fetchData(); // Refresh the list of certificates
        } catch (e) {
            message.error("Unable to remove certificate");
        }
    };

    return (
        <div>
            <h2>Certificates</h2>
            <Button type='primary' onClick={() => { setAdd(true) }}>Add Certificate <PlusSquareOutlined /> </Button>
            <div style={{ marginTop: 20 }}>
                {data && data.length > 0 ? (
                    <ul>
                        {data.map((item) => (
                            <li className='make-grid' key={item.id}>
                                <strong>{item.certificate_name}</strong>
                                <Image className='image' src={`${apiurl}${item.certificate_image}/`}></Image>
                                <Button type="link" onClick={() => handleRemove(item.id)}>Remove</Button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No certificates available</p>
                )}
            </div>
            {add && (
                <Form
                    form={form}
                    onFinish={handleAdd}
                    layout="vertical"
                >
                    <Form.Item
                        label="Certificate Name"
                        name="certificate_name"
                        rules={[{
                            required: true,
                            message: "Please provide the certificate name"
                        }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Add Certificate Image"
                        name="certificate_image"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => e && e.fileList}
                        rules={[{
                            required: true,
                            message: "Please upload a certificate image"
                        }]}
                    >
                        <Upload
                            listType="picture"
                            beforeUpload={() => false}  // Prevent auto upload
                            accept="image/*"
                            maxCount={1}  // Allow only one file
                        >
                            <Button>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">Submit</Button>
                        <Button type="default" onClick={() => setAdd(false)} style={{ marginLeft: 10 }}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    )
}

export default Certificates