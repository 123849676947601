import React from "react";
import "./CandidatesCard.css";
import dummyprofile from "../../../../images/agency/job-postings/dummyprofile.svg";
import Eye from "../../../../images/agency/job-postings/Eye.svg";
import bookicon from "../../../../images/agency/job-postings/bookicon.svg";

const CandidatesCard = () => {
  return (
    <div className="cand-card-container">
      <div className="profile-content-container">
        <div
        className="profile"
          style={{
            height: "65px",
            borderRadius: "50px",
            backgroundColor: "lightgreen",
            minWidth: "65px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "30px",
          }}
        >
          <img src={dummyprofile} style={{ width: "35px" }} />
        </div>
        <div className="profile-content">
          <div
            className="profile-content-row1"
            style={{ display: "flex", gap: "100px", alignItems: "center" }}
          >
            Ameer
            <button
              style={{
                backgroundColor: "#F2F2FD",
                color: "#1681FF",
                fontSize: "12px",
                fontWeight: "400",
                border: "none",
                outline: "none",
                margin: "none",
                borderRadius: "15px",
                height: "30px",
                width: "80px",
              }}
            >
              applied
            </button>
          </div>
          <div
            className="profile-content-row2"
            style={{ display: "flex", gap: "1px" }}
          >
            <img src={bookicon}></img>
            <p style={{ margin: "6px" }}>Software Engineer</p>
          </div>
          <div
            className="profile-content-row3"
            style={{
              width: "100%",
              overflow: "hidden",
              fontSize: "14px",
              color: "#9095A0",
            }}
          >
            Scoped Class Names: I added a custom prefix (e.g.,
            .job-responses-container-custom, .search-button, etc.) to ensure
            that these styles apply only to elements with the corresponding
            classes within the specific component. This will reduce the risk of
            these styles affecting other parts of your project.
          </div>
        </div>
      </div>
      <div
        style={{
          width: "80px",
          height: "25px",
          backgroundColor: "#F2F2FD",
          display: "flex",
          gap: "5px",
          borderRadius: "4px",
          padding: "5px 10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={Eye} style={{ width: "15px" }} />
        <h3
          style={{
            margin: "0px",
            border: "0px",
            color: "#1681FF",
            fontWeight: "400",
            fontSize: "15px",
          }}
        >
          Detail
        </h3>
      </div>
    </div>
  );
};

export default CandidatesCard;
