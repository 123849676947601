import React, { useState, useEffect } from 'react'
import Main from '../Layout'
import { message } from 'antd'
import { useAuth } from '../../../common/useAuth'


import profile_pic from '../../../../images/Manager/ViewCandidate/sampleProfile.png'
import image1 from '../../../../images/Manager/ViewCandidate/image.png'
import image2 from '../../../../images/Manager/ViewCandidate/image2.png'
import image3 from '../../../../images/Manager/ViewCandidate/image3.png'
import checked_success from '../../../../images/Manager/ViewCandidate/checked-success.svg'
import checked_failed from '../../../../images/Manager/ViewCandidate/checked-failed.svg'
import briefcase from '../../../../images/Manager/ViewCandidate/briefcase.svg'
import people from '../../../../images/Manager/ViewCandidate/people.svg'
import "./ViewCandidate.css"
import { MailOutlined, PhoneOutlined, PushpinOutlined } from '@ant-design/icons'


const Profile = ({ profile }) => {
    const data = profile;
    console.log(data)
    const education = [
        {
            "id": 1,
            "details": "JNTUGV -UCEV, CSE, Vizianagaram"
        },
        {
            "id": 2,
            "details": "JNTUGV -UCEV, CSE, Vizianagaram"
        },
        {
            "id": 3,
            "details": "JNTUGV -UCEV, CSE, Vizianagaram"
        },
        {
            "id": 4,
            "details": "JNTUGV -UCEV, CSE, Vizianagaram"
        },
    ]
    return (
        <div className='profile' >
            <div className="main-profile">
                <div className="profile-pic">
                    <img src={profile_pic} alt="Profile Pic" />
                </div>
                <div className="profile-details">
                    <span className="name">{data.candidate_name}</span>
                    <div className="contact">
                        <span className="phone"> <PhoneOutlined /><span className="gap"> {data.candidate_phone} </span></span>
                        <span className="location"> <PushpinOutlined /> <span className="gap"> {data.candidate_location} </span></span>
                    </div>
                    <span className="email"><MailOutlined /> <span className="gap"> {data.candidate_email} </span></span>
                </div>
            </div>
            <div className="profile-completion">
                <div className="profile-completion-flex">
                    <span className='title'>Profile Completion</span>
                    {/* <span className="edit"><EditOutlined /> Edit</span> */}
                </div>
                <div className="line">
                    <div className="handle-line" style={{ width: `${data.profile_percentage}%` }}></div>
                    <div className="box"><div className="percent">{data.profile_percentage}%</div></div>
                </div>

            </div>

            <div className="details-box">
                <div className="details-box-item Skills">
                    <span className="query">Skills</span>
                    <span className="res">{data.skills}</span>
                </div>
                <div className="details-box-item">
                    <span className="query">Education</span>
                    <span className="res">
                        {data.education.map((item, index) => (
                            <span className="education">{item.result}</span>
                        ))}
                    </span>
                </div>
                <div className="details-box-item">
                    <span className="query">Experience</span>
                    <span className="res ">
                        {data.experience.map((item, index) => (
                            <div className="experience">
                                <span className="company_name">{item.company_name}</span>
                                <span className="duration">{item.duration}</span>
                            </div>
                        ))}
                    </span>
                </div>
                <div className="details-box-item">
                    <span className="query">Salary</span>
                    <span className="res">{data.salary}</span>
                </div>
                <div className="details-box-item">
                    <span className="query">Extra Info</span>
                    <span className="res">{data.extra_info}</span>
                </div>
                <div className="documents">
                    <div className="documents-title">
                        <span className="title">Documents</span>
                        <span className="download">
                            Click to Download
                        </span>
                    </div>
                    <div className="line"></div>
                    <div className="document-items">
                        {profile.documents &&
                            <span className="document1 document-item" >{profile.documents.document_name}</span>
                        }
                    </div>
                    <div className="document-status">
                        <span className="download">
                            Download All
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}


const JobDetails = ({ jobs, candidate_id }) => {
    const { apiurl, token } = useAuth();
    const [data, setData] = useState();
    const [jobId, setJobId] = useState(0);

    const rounds = data?.all_rounds


    const selected = data?.selected_round;

    const fetchJobData = async (job_id) => {
        try {
            const response = await fetch(`${apiurl}/candidate_status_for_job/?job_id=${job_id}&candidate_id=${candidate_id}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (data.error) {
                message.error(data.error);
            }
            setData(data);
        } catch (e) {
            console.log(e);
            message.error(e);
        }
    };

    useEffect(() => {
        if (jobs && jobs[jobId]) {
            fetchJobData(jobs[jobId].job_id);
        }
    }, [jobId, jobs]);

    return (
        <div className='job_details'>
            <div className="applied_jobs">
                <span className="title">Applied Jobs</span>
                <span className="dropdown">
                    <select
                        name="job_titles"
                        value={jobs[jobId]?.title}
                        onChange={(e) => {
                            const newJobId = jobs.findIndex(job => job.title === e.target.value);
                            console.log(newJobId);
                            setJobId(newJobId); // Update jobId based on selected job
                        }}
                    >
                        {jobs.map((job) => (
                            <option value={job.title} key={job.id}>{job.title}</option>
                        ))}
                    </select>
                </span>
            </div>

            <div className="job_overview">
                <div className="header">
                    <span>Job Overview</span>
                    <span className="job_position">
                        {data?.new_position ? `New Position` : 'Old Position'}
                    </span>
                    <span className="created_by">
                        <span>Created By - {data?.interviewer}</span>
                        <span className='date'>
                            {data?.created_at ? new Date(data.created_at).toLocaleDateString() : 'Date not available'}
                        </span>
                    </span>
                </div>
                <div className="overview">
                    <div className="box">
                        <span className="image"><img src={briefcase} alt="briefcase" /></span>
                        <div className="details">
                            <span className="val">{data?.job_experience}</span>
                            <span className="query">Experience</span>
                        </div>
                    </div>
                    <div className="box">
                        <span className="image"><img src={briefcase} alt="briefcase" /></span>
                        <div className="details">
                            <span className="val">{data?.job_graduation}</span>
                            <span className="query">Educations</span>
                        </div>
                    </div>
                    <div className="box">
                        <span className="image"><img src={people} alt="people" /></span>
                        <div className="details">
                            <span className="val">{data?.num_of_positions}</span>
                            <span className="query">Positions</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="current_stage">
                <span className="title">Current Stage</span>
                <span className="status">{data?.current_stage}</span>
                <span className="ctc">View CTC</span>
            </div>

            <div className="current_status_bar">
                {rounds && rounds.map((round) => (
                    <span
                        key={round}
                        className={`item ${selected === round ? "selected" : ""}`}
                    >
                        {round}
                    </span>
                ))}
            </div>

            <div className="interviews_section">
                <div className="interviews_title">
                    <span className="title">Interviews</span>
                    <span className="scheduled_by">Scheduled By- {data?.interviewer}
                        <span className='date' style={{
                            marginLeft: "10px"
                        }}>
                            {data?.next_round_time ? new Date(data.next_round_time).toLocaleDateString() : "Date Not Mentioned"}
                        </span>
                    </span>
                </div>
                <div className="main_section">
                    <div className="next_interview">
                        <div className="interview_type">
                            <span className="interview_name">{data?.interview_type}</span>
                            <span className="interview_mode">{data?.interview_mode}</span>
                        </div>
                        <span className="interview_date">
                            {data?.next_round_time ? new Date(data.next_round_time).toLocaleString() : "Date Not Mentioned"}
                        </span>
                        <span className="link">Meet Link: <a href={data?.meet_link}>{data?.meet_link ? data?.meet_link : "Meet link not added"} </a></span>
                    </div>
                    <div className="assigned_recruiter">
                        <span className="name">Assigned Recruiter</span>
                        <img src={image1} alt="" />
                        <span className="name">{data?.recruiter_name}</span>
                    </div>
                    <div className="interview_panel">
                        <span className="title">Interviewers</span>
                        <div className="multiple">
                            <img className='img-1' src={image2} alt="" />
                            <img className='img-2' src={image3} alt="" />
                            <img className='img-3' src={image1} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="matching-status">
                <div className="matched">
                    <div className="icon-flex">
                        <img src={checked_success} alt="success" />
                        <div className="details">
                            <div className='query'>What Skills Matched?</div>
                            <div className="items">
                                {data?.matched_skills ? data.matched_skills.join(', ') : 'No matched skills'}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="not-matched">
                    <div className="icon-flex">
                        <img src={checked_failed} alt="failed" />
                        <div className="details">
                            <div className='query'>What Skills Not Matched?</div>
                            <div className="items">
                                {data?.unmatched_skills ? data.unmatched_skills.join(', ') : 'No unmatched skills'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Comments = ({ feedback }) => {

    const count = feedback.length
    // const comments = [
    //     {
    //         "id": 1,
    //         "name": "Interviewer Name 1",
    //         "feedback": "This is the feedback of interviewer 1, and he said more about this guy ",
    //         "profile": image1
    //     },
    //     {
    //         "id": 2,
    //         "name": "Interviewer Name 2",
    //         "feedback": "This is the feedback of interviewer 1, and he said more about this gy",
    //         "profile": image2
    //     },

    // ]

    const comments = feedback;


    return (
        <div className="comments-side">
            <div className="comments">
                <div className="header  ">
                    <span className="title">Comments</span>
                    <span className='count'>{count}</span>
                </div>
                {comments.map((comment, index) => (
                    <div className="component">
                        <img src={image1} alt="image here" />
                        <div className="details">
                            <span className='name'>{comment.interviewer_name}</span>
                            <span className='feedback'>{comment.feedback}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const NotUpdatedProfile = ({ candidate_id }) => {
    const { apiurl, token } = useAuth();
    const sendNotification = async () => {
        try {
            const response = await fetch(`${apiurl}/notification_to_update_profile/?id=${candidate_id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const data = await response.json();
            if (data.message) {
                message.success(data.message);
            }
            if (data.error) {
                message.error(data.error);
            }
        }
        catch (e) {
            message.error(e);
        }
    }

    return (
        <div className='not-updated'>

            {message && message.success && <div className='success'>{message.success}</div>}
            <div className="section">
                <div className='heading'>Candidate is'nt updated his profile yet</div>
                <div className='small-heading'>Notify Candidate to update his profile</div>
                <button onClick={() => sendNotification()}>Send Notification</button>
            </div>
        </div>
    )
}

const ViewCandidate = () => {

    const [profile, setProfile] = useState()
    const { token, apiurl } = useAuth();
    const [jobs, setJobs] = useState();
    const [feedback, setFeedback] = useState([]);

    const candidate_id = 2;

    const fetchProfile = async () => {
        try {
            const response = await fetch(`${apiurl}/view_candidate_profile/?id=${candidate_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const data = await response.json();
            if (data.error) {
                message.error(data.error)
            }
            if (data.candidate_data) {
                setProfile(data.candidate_data);
            }
            if (!data.candidate_data) {
                message.error("Candidate is not updated his profile, ")
            }
            if (data.applied_jobs) {
                setJobs(data.applied_jobs);
            }
            if (data.feedback) {
                setFeedback(data.feedback);
            }
        }
        catch (e) {
            message.error(e);
        }
    }

    useEffect(() => {
        if (token) {
            fetchProfile();
        }
    }, [token])

    return (
        <Main defaultSelectedKey="9">
            <div className="top-component">
                {profile &&
                    <Profile profile={profile} />
                }
                {!profile &&
                    <NotUpdatedProfile candidate_id={candidate_id} />
                }
                {jobs &&
                    <JobDetails jobs={jobs} candidate_id={candidate_id} />
                }
                {feedback &&
                    <Comments feedback={feedback} />
                }
            </div>
        </Main>
    )
}

export default ViewCandidate