import React, { useEffect, useState } from "react";
import { Table, Button, Select, Form, Tabs, Modal } from "antd";
import { Link } from "react-router-dom";
import "./Interviewers.css";
import Main from "../Layout";
import AddInterviewer from "./AddInterviewer";
import { useAuth } from "../../../common/useAuth";

const { Option } = Select;
const { TabPane } = Tabs;

const Interviewers = () => {
	const [interviewerSummary, setInterviewerSummary] = useState([]);
	const [workAllocationData, setWorkAllocationData] = useState([]);
	const [interviewerList, setInterviewerList] = useState([]);
	const [selectedInterviewers, setSelectedInterviewers] = useState({});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { apiurl, token } = useAuth();

	useEffect(() => {
		loadInterviewers();
		loadWorkAllocationData();
	}, []);


	// console.log(AddInterviewers)
	const loadInterviewers = async () => {
		console.log("running this again");
		try {
			const response = await fetch(`${apiurl}/client/get-interviewers`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setInterviewerSummary(data);
		} catch (error) {
			console.error("Error fetching Interviewers:", error);
		}
	};

	const loadWorkAllocationData = async () => {
		try {
			const response = await fetch(`${apiurl}/api/client/work_to_interviewer/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setWorkAllocationData(data.data);
			setInterviewerList(data.interviewer_data);
		} catch (error) {
			console.error("Error fetching work allocation data:", error);
		}
	};

	const saveInterviewerAllocation = async (jobId) => {
		const interviewerId = selectedInterviewers[jobId];
		if (interviewerId) {
			try {
				await fetch(`${apiurl}/api/client/work_to_interviewer/`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ jobId, interviewerId }),
				});
				loadWorkAllocationData();
			} catch (error) {
				console.error("Error assigning Interviewer:", error);
			}
		}
	};

	const handleInterviewerSelection = (interviewerId, jobId) => {
		setSelectedInterviewers((prev) => ({ ...prev, [jobId]: interviewerId }));
	};

	const renderInterviewerDropdown = (InterviewerName, record) => {
		if (InterviewerName) {
			return InterviewerName;
		} else {
			return (
				<Form onFinish={() => saveInterviewerAllocation(record.id)}>
					<Select
						placeholder="Select Interviewer"
						onChange={(value) => handleInterviewerSelection(value, record.id)}
						style={{ width: "100%" }}>
						{interviewerList.map((Interviewer) => (
							<Option key={Interviewer.id} value={Interviewer.id}>
								{Interviewer.username}
							</Option>
						))}
					</Select>
					<Button type="primary" htmlType="submit">
						Save
					</Button>
				</Form>
			);
		}
	};

	const InterviewerColumns = [
		{
			title: "Interviewer Name",
			dataIndex: "username",
			key: "username",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
	];

	const summaryColumns = [
		{
			title: "Interviewer",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Client",
			dataIndex: "client_name",
			key: "client_name",
		},
		{
			title: "Role",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "No. of Positions",
			dataIndex: "num_of_positions",
			key: "num_of_positions",
		},
		{
			title: "No. of Resumes Shared",
			dataIndex: "resumes_sent",
			key: "resumes_sent",
		},
		{
			title: "No. of Resources Selected",
			dataIndex: "resumes_accepted",
			key: "resumes_accepted",
		},
		{
			title: "Job Status",
			dataIndex: "status",
			key: "status",
		},
	];

	const workAllocationColumns = [
		{
			title: "Client Name",
			dataIndex: "username",
			key: "username",
		},
		{
			title: "Role",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "Primary Skills",
			dataIndex: "primary_skills",
			key: "primary_skills",
		},
		{
			title: "Secondary Skills",
			dataIndex: "secondary_skills",
			key: "secondary_skills",
		},
		{
			title: "Allocate Interviewer",
			dataIndex: "Interviewer_name",
			key: "Interviewer_name",
			render: renderInterviewerDropdown,
		},
		{
			title: "Action",
			key: "action",
			render: (text, record) => (
				<Link to={`/job_post_details/${record.id}`}>View</Link>
			),
		},
	];

	return (
		<Main defaultActiveKey={5}>
			<div>
				<Tabs defaultActiveKey="1">
					<TabPane tab="Interviewers" key="1">
						<div className="add-rec-btn">
							<Button onClick={() => setIsModalVisible(true)}>
								Add Interviewer
							</Button>
						</div>
						<Table
							columns={InterviewerColumns}
							dataSource={interviewerSummary}
							rowKey="id"
							className="custom-table"
							pagination={false}
						/>
					</TabPane>
					<TabPane tab="Interviewer Summary" key="2">
						<Table
							columns={summaryColumns}
							dataSource={interviewerSummary}
							rowKey="id"
							className="custom-table"
							pagination={false}
						/>
					</TabPane>
					<TabPane tab="Work Allocated to Interviewers" key="3">
						<Table
							columns={workAllocationColumns}
							dataSource={workAllocationData}
							rowKey="id"
							className="custom-table"
							pagination={false}
						/>
					</TabPane>
				</Tabs>
				<Modal
					open={isModalVisible}
					onCancel={() => setIsModalVisible(false)}
					footer={null}>
					<AddInterviewer onclose={loadInterviewers} />
				</Modal>
			</div>
		</Main>
	);
};

export default Interviewers;
