import React, { useState } from "react";
import CandidatesCard from "../managercards/CandidatesCard";
import Main from "../Layout";
import "./Candidates.css";
import searchicon from "../../../../images/agency/job-postings/searchicon.svg";
import { Select } from "antd";

const Candidates = () => {
  const [jobTitles, setJobTitles] = useState([
    "Software Developer",
    "Django Developer",
    "React Developer",
    "Full Stack Developer",
  ]);
  const [selectedJobs, setSelectedJobs] = useState([]);

  const handleJobTitleChange = (values) => {
    setSelectedJobs(values);
    console.log("Selected Job Titles:", values);
  };

  return (
    <Main>
      <div className="agency-candidates">
        <h2>Candidates</h2>
        <div className="row1">
          <div className="dropdown">
            <span>Job Title</span>
            <Select
              mode="multiple"
              style={{ width: 200 }}
              placeholder="Select Job Titles"
              onChange={handleJobTitleChange}
              value={selectedJobs} // Keeps checkboxes ticked
              showArrow={true} // Keeps dropdown behavior normal
              tagRender={() => null} // Hides selected values from input
            >
              {jobTitles.map((title, index) => (
                <Select.Option key={index} value={title}>
                  {title}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="search-inputbtn-container">
            <input className="input" placeholder="Search Candidates" />
            <div className="search-btn">
              <img src={searchicon} alt="Search" />
              <h3>Search </h3>
            </div>
          </div>
        </div>
        {selectedJobs.length > 0 && (
          <div className="row2">
            <p style={{textAlign:"left",fontWeight:"600",fontSize:"15px"}}>Results</p>
            <div className="results-clearall">
              <div className="results">
                {selectedJobs.map((job) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        border: "1px solid #E8618C",
                        borderRadius: "20px",
                        padding: "3px 10px",
                      }}
                    >
                      <div className="selected-name">
                        <span style={{ color: "#E8618C" }}>{job}</span>
                      </div>
                    </div>
                  );
                })}
              </div>

              {selectedJobs.length > 1 && (
                <button
                  style={{
                    width: "100px",
                    height: "30px",
                    borderRadius: "12px",
                    color: "#1681FF",
                    outline: "none",
                    margin: "none",
                    border: "1px solid #1681FF",
                    cursor: "pointer",
                  }}
                >
                  Clear All
                </button>
              )}
            </div>
          </div>
        )}

        <div className="row3">
          <CandidatesCard />
          <CandidatesCard />
          <CandidatesCard />
          <CandidatesCard />
          <CandidatesCard />
        </div>
      </div>
    </Main>
  );
};

export default Candidates;
