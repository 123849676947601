import React, { useEffect, useState } from "react";
import "./JobPost.css";
import { useNavigate } from "react-router-dom";
import Main from "../Layout";
import { useAuth } from "../../../common/useAuth";
import { EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Menu } from "antd";

const JobCard = ({ data }) => {
	const navigate = useNavigate();
	return (
		<div className="card">
			<div className="headers">
				<div className="first">
					<span className="role">{data.job_title}</span>
					<span className="status">{data.status}</span>
					<span className="recruiter-name">
						{data.recruiter_name.map((item, index) => {
							return (
								<span>{item} ,  </span>
							)
						})}
					</span>
				</div>
				<div className="last">
					<span className="edit">
						<EditOutlined />
						<span className="gap">Edit</span>
					</span>
					<span className="view-job" onClick={() => {
						navigate(`/agency/postings/${data.id}`)
					}}><EyeOutlined />View Job Post</span>
					<span className="view-candidates"><SearchOutlined />View Candidates</span>
				</div>
			</div>
			<div className="main-section">
				<div className="item">
					<span className="client-name">{data.client_name}</span>
					<span className="deadline">{data.deadline}</span>
				</div>
				<div className="boxes">
					{data.rounds_details && data.rounds_details.map((item, index) => {
						const key = Object.keys(item)[0];
						const value = item[key];

						return (
							<div
								key={index}
								className={`box ${key.toLowerCase() === "hired" ? "selected" : ""} ${key.toLowerCase() === "rejected" ? "rejected" : ""}`}
							>
								<span className="value">{value}</span>
								<span className="query">{key}</span>
							</div>
						);
					})}
				</div>

			</div>
		</div>
	);
};

const JobsDetails = ({ details }) => {
	if (!details) return null;

	return (
		<div className="jobs_details">
			<div className="heading">
				<h5>Jobs</h5>
				<div className="search">
					<>Search</>
					<>Filter</>
				</div>
			</div>
			<div className="details">
				{Object.entries(details).map(([key, value], index, array) => (
					<div key={index} className={`index ${index === 0 ? "selected" : ""} ${index === array.length - 1 ? "last-index" : ""}`}>
						<span className="title">{key.replace(/_/g, " ")}</span>
						<span className="val">{value}</span>
					</div>
				))}
			</div>
		</div>
	);
};


const JobPosts = () => {
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [orgJobs, setOrgJobs] = useState(null);
	const [current, setCurrent] = useState("all");

	const { apiurl, token } = useAuth();

	useEffect(() => {
		if (token) {
			fetchJobPosts();
		}
	}, [token]);

	const fetchJobPosts = async () => {
		try {
			const response = await fetch(`${apiurl}/manager/job-posts/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const data = await response.json();
			setData(data.data || []);
			setOrgJobs(data.org_jobs || {});
			setFilteredData(data.data || []);

		} catch (error) {
			console.error("Error fetching job posts:", error);
		}
	};

	const items = [
		{ label: "All", key: "all" },
		{ label: "Open", key: "open" },
		{ label: "Pending Approval", key: "pending_approval" },
		{ label: "Expired", key: "expired" },
	];

	const handleMenuClick = (e) => {
		setCurrent(e.key);
		filterJobs(e.key);
	};

	const filterJobs = (key) => {
		if (key === "all") {
			setFilteredData(data);
		} else if (key === "open") {
			setFilteredData(data.filter(job => job.status === "opened"));
		} else if (key === "pending_approval") {
			setFilteredData(data.filter(job => job.approval_status === "pending"));
		} else if (key === "expired") {
			setFilteredData(data.filter(job => new Date(job.deadline) < new Date()));
		}
	};

	return (
		<Main defaultSelectedKey="3">
			{orgJobs && <JobsDetails details={orgJobs} />}
			<Menu className="menu" onClick={handleMenuClick} selectedKeys={[current]} mode="horizontal" items={items} />
			<div className="job-cards">
				{filteredData.map((item, index) => (
					<JobCard key={index} data={item} />
				))}
			</div>
		</Main>
	);
};

export default JobPosts