import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
	DashboardOutlined,
	UnorderedListOutlined,
	LogoutOutlined,
} from "@ant-design/icons";
import logo from "./../../../images/LOGO-10.png";
import { useAuth } from "../../common/useAuth";
import Applications from "./ScheduleInterviews/ApplicationsToSchedule";

const { Header, Sider } = Layout;

const Main = ({ children, defaultSelectedKey }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [openKeys, setOpenKeys] = useState([]);
	const { handleLogout } = useAuth();

	const defaultSelectedKeys = () => {
		const pathname = location.pathname;

		const menuItems = [
			"/",
			"/recruiter/postings",
			"/recruiter/manage_calender_events",
			"/recruiter/profile",

		];

		const index = menuItems.findIndex((item) => pathname.startsWith(item));
		if (index === -1) return ["1"];
		return [`${index + 2}`];
	};

	const handleMenuItemClick = ({ key, action }) => {
		if (action === "logout") {
			Logout();
		}
	};

	const Logout = () => {
		handleLogout();
		navigate("/login");
	};

	const menuItems = [
		{
			key: "1",
			icon: <DashboardOutlined />,
			label: <Link to="/">Dashboard</Link>,
		},
		{
			key: "2",
			icon: <UnorderedListOutlined />,
			label: <Link to="/recruiter/postings">Assigned Posts</Link>,
		},
		{
			key: "3",
			icon: <UnorderedListOutlined />,
			label: <Link to="/recruiter/manage_calender_events">My Events</Link>,
		},
		{
			key: "4",
			icon: <UnorderedListOutlined />,
			label: <Link to="/recruiter/profile">Profile</Link>,
		},
		{
			key: "5",
			icon: <UnorderedListOutlined />,
			label: <Link to="/recruiter/schedule_applications">Schedule Applications</Link>,
		},
		{
			key: "5",
			icon: <LogoutOutlined />,
			label: "Logout",
			onClick: Logout,
		},
	];

	return (
		<Layout>
			<Sider
				className="side"
				breakpoint="md"
				collapsedWidth="0"
				width={"225px"}
				style={{
					height: "calc(100vh - 100px)",
					position: "fixed",
					left: "0",
					top: "100px",
					bottom: 0,
					zIndex: 1,
					background: "#fff",
				}}>
				<Menu
					theme="light"
					defaultSelectedKeys={defaultSelectedKey ? defaultSelectedKey : defaultSelectedKeys()}
					openKeys={openKeys}
					onOpenChange={(keys) => setOpenKeys(keys)}
					onClick={handleMenuItemClick}
					className="menu"
					mode="inline"
					items={menuItems}></Menu>
			</Sider>

			<Layout>
				<Header className="head" style={{ padding: 0 }}>
					<div className="header-logo">
						<a href="/">
							<img alt="logo" src={logo}></img>
						</a>
					</div>
					<div className="header-buttons"></div>
				</Header>
				<div className="content">{children}</div>
			</Layout>
		</Layout>
	);
};

export default Main;
