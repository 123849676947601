import React from 'react';
import styles from './InterviewersTable.models.css'; // Make sure to import your CSS module

const InterviewersTable = ({ interviewers }) => {
  return (
    <div>
      {interviewers && interviewers.length > 0 ? (
        <table className={styles.interviewersTable}>
          <thead>
            <tr>
              <th>Round</th>
              <th>Interviewer Name</th>
              <th>Interviewer Email</th>
              <th>Mode of Interview</th>
              <th>Type of Interview</th>
            </tr>
          </thead>
          <tbody>
            {interviewers.map((interviewer, index) => (
              <tr key={index}>
                <td>{interviewer.round_num}</td>
                <td>{interviewer.name.username}</td>
                <td>{interviewer.name.email}</td>
                <td>{interviewer.mode_of_interview}</td>
                <td>{interviewer.type_of_interview}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No interviewers data available</p>
      )}
    </div>
  );
};

export default InterviewersTable;
