import React, { useState, useEffect } from 'react';
import Main from './Layout';
import { useAuth } from '../../common/useAuth';
import { useParams } from 'react-router-dom';
import { message, Button, Form, Input, Spin, Table, InputNumber, DatePicker } from 'antd';
import './ConductInterview.css';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import PrevInterviewRes from './PrevInterviewRes';

const apiurl = process.env.REACT_APP_BACKEND_URL;

const ConductInterview = () => {
    const { token } = useAuth();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [rejectAction, setRejectAction] = useState(false);
    const [acceptAction, setAcceptAction] = useState(false);
    const [primarySkills, setPrimarySkills] = useState([])
    const [secondarySkills, setSecondarySkills] = useState([]);
    const [primarySkillRatings, setPrimarySkillRatings] = useState({});
    const [secondarySkillRatings, setSecondarySkillRatings] = useState({});
    const [remarks, setRemarks] = useState();
    const [hasNextRound, setHasNextRound] = useState(false);
    const navigate = useNavigate()

    const fetchInterviewDetails = async () => {
        setLoading(true);
        console.log("interview id is ", id)
        try {
            const response = await fetch(`${apiurl}/interviewer/scheduled-interviews/?id=${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            const data = await response.json();
            if (data.error) {
                message.error(data.error);
            } else {
                const dateString = new Date(data.scheduled_date);
                data.scheduled_date = dateString.toLocaleString();
                setData(data);
            }
        } catch (e) {
            console.error(e);
            message.error('Failed to fetch interview details.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (token) {
            fetchInterviewDetails();
        }
    }, [token]);

    const fetchSkills = async (job_id, resume_id) => {
        try {
            const response = await fetch(`${apiurl}/fetch-skills/?id=${job_id}&resume_id=${resume_id}`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const data = await response.json();
            if (data.error) {
                message.error(data.error);
                return;
            }
            setHasNextRound(data.has_next_round);
            setPrimarySkills(data.data.primary_skills ? data.data.primary_skills.split(',').map(skill => skill.trim()) : []);
            setSecondarySkills(data.data.secondary_skills ? data.data.secondary_skills.split(',').map(skill => skill.trim()) : []);
        } catch (e) {
            console.error(e);
            message.error(e.message || 'Failed to fetch skills.');
        }
    };

    const skillColumns = (skillType) => [
        {
            title: 'Skill',
            dataIndex: 'skill',
            key: 'skill',
        },
        {
            title: 'Rating [1-10]',
            dataIndex: 'rating',
            key: 'rating',
            render: (_, record) => (
                <InputNumber
                    min={1}
                    max={10}
                    placeholder="Rate skill"
                    onChange={(value) => {
                        if (skillType === 'primary') {
                            setPrimarySkillRatings((prev) => ({
                                ...prev,
                                [record.skill]: value,
                            }));
                        }
                        else {
                            setSecondarySkillRatings((prev) => ({
                                ...prev,
                                [record.skill]: value,
                            }))
                        }
                    }} />
            ),
        },
    ];

    const prepareSkillData = (skills) => {
        return skills.map((skill) => ({ skill, rating: null }));
    };

    const handleRejection = async (values, candidate_resume_id, round_num) => {
        setLoading(true);
        try {
            const requestedData = JSON.stringify({
                "round_num": round_num,   //actual round number to store the evaluation results in the database
                "primary_skills": primarySkillRatings,
                "secondary_skills": secondarySkillRatings,
                "remarks": values.remarks,
            })
            const response = await fetch(`${apiurl}/interviewer/reject-candidate/?id=${candidate_resume_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': ` Bearer ${token}`
                },
                body: requestedData
            })
            const data = await response.json();
            if (data.message) {
                message.success(data.message)
                navigate(-1)
            }
            else {
                message.error(data.error)
            }
        } catch (e) {
            console.error(e);
            message.error('Failed to reject the candidate.');
        } finally {
            setLoading(false);
        }
    };

    const promoteCandidate = async (round_num, candidate_resume_id) => {
        setLoading(true);

        const requestData = JSON.stringify({
            "remarks": remarks,
            "primary_skills": primarySkillRatings,
            "secondary_skills": secondarySkillRatings,
        })

        console.log("request data is ", requestData)

        try {
            const response = await fetch(`${apiurl}/interviewer/promote-candidate/?id=${candidate_resume_id}&round_num=${round_num}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: requestData,
            });
            const data = await response.json();

            if (data.error) {
                message.error(data.error);
            }

            if (data.message) {
                message.success(data.message);
                navigate(-1);
            }
        }

        catch (e) {
            console.error(e);
            message.error('Failed to accept the candidate.');
        } finally {
            setLoading(false);
        }

    };

    const handleSelect = async (round_num, candidate_resume_id) => {

        setLoading(true);

        const requestData = JSON.stringify({
            "remarks": remarks,
            "primary_skills": primarySkillRatings,
            "secondary_skills": secondarySkillRatings,
        })

        console.log("request data is ", requestData)

        try {
            const response = await fetch(`${apiurl}/interviewer/select-candidate/?id=${candidate_resume_id}&round_num=${round_num}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: requestData,
            });
            const data = await response.json();

            if (data.error) {
                message.error(data.error);
            }

            if (data.message) {
                message.success(data.message);
                navigate(-1)
            }
        }

        catch (e) {
            console.error(e);
            message.error('Failed to select the candidate.');
        } finally {
            setLoading(false);
        }
    }

    return (
        <Main defaultSelectedKey="2" className="interview-details">

            {message && message.success && <div className="success">{message.success}</div>}
            {message && message.error && <div className="error">{message.error}</div>}

            <h1>Conduct Interview</h1>
            <h2>Interview Details</h2>
            {loading ? (
                <Spin />
            ) : (
                data && (
                    <div className="interview-details-data">
                        <p>Job ID: {data.job_id}</p>
                        <p>Job Title: {data.job_title}</p>
                        <p>Interviewer Name: {data.interviewer_name}</p>
                        <p>Candidate Name: {data.candidate_name}</p>
                        <p>Round Number: {data.round_num}</p>
                        <p>Scheduled Date and Time: {data.scheduled_date}</p>
                    </div>
                )
            )}

            {data && data.round_num > 1 && (
                <PrevInterviewRes ApplicationId={data.candidate_resume_id} />
            )}
            <div className="candidate-actions">
                <Button
                    onClick={() => {
                        setAcceptAction(true);
                        setRejectAction(false);
                        fetchSkills(data.job_id, data.candidate_resume_id);
                    }}>,
                    Promote Candidate
                </Button>
                <Button danger
                    type="primary"
                    onClick={() => {
                        setAcceptAction(false);
                        setRejectAction(true)
                    }}>
                    Reject Candidate
                </Button>
            </div>

            {rejectAction && (
                <Form
                    onFinish={(values) => handleRejection(values, data.candidate_resume_id, data.round_num)}
                    layout="vertical"
                    className="reject-form"
                    style={{ marginTop: '30px' }}
                >
                    <Form.Item
                        label="Reason for Rejection"
                        name="reject_reason"
                        rules={[{ required: true, message: 'Please provide a reason for rejection' }]}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit Rejection
                        </Button>
                        <Button
                            style={{ marginLeft: '10px' }}
                            onClick={() => setRejectAction(false)}
                        >
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            )
            }
            {acceptAction && (
                <div>
                    {primarySkills.length > 0 && (
                        <div>
                            <h3>Primary Skills</h3>
                            <Table
                                columns={skillColumns('primary')}
                                dataSource={prepareSkillData(primarySkills)}
                                rowKey="skill"
                                pagination={false}
                            />
                        </div>
                    )}
                    {secondarySkills.length > 0 && (
                        <div>
                            <h3>Secondary Skills</h3>
                            <Table
                                columns={skillColumns('secondary')}
                                dataSource={prepareSkillData(secondarySkills)}
                                rowKey="skill"
                                pagination={false}
                            />
                        </div>
                    )}


                    {hasNextRound ?
                        <Form
                            layout="vertical"
                            onFinish={(values) => {
                                setRemarks(values.remarks);
                                promoteCandidate(data.round_num, data.candidate_resume_id,);
                            }}
                            style={{ marginTop: '30px' }}
                        >
                            <Form.Item
                                label="Remarks"
                                name="remarks"
                                rules={[{ required: true, message: 'Please provide remarks before proceeding.' }]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Promote Candidate
                                </Button>
                                <Button
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => setAcceptAction(false)}
                                >
                                    Cancel
                                </Button>
                            </Form.Item>
                        </Form>
                        : (
                            <Form
                                layout="vertical"
                                onFinish={(values) => {
                                    setRemarks(values.remarks);
                                    handleSelect(data.round_num, data.candidate_resume_id,);
                                }}
                                style={{ marginTop: '30px' }}
                            >
                                <Form.Item
                                    label="Remarks"
                                    name="remarks"
                                    rules={[{ required: true, message: 'Please provide remarks before proceeding.' }]}
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Select Candidate
                                    </Button>
                                    <Button
                                        style={{ marginLeft: '10px' }}
                                        onClick={() => setAcceptAction(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Form.Item>
                            </Form>
                        )
                    }
                </div>
            )}

        </Main >
    );
};

export default ConductInterview;