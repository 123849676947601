import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./CompleteJobPost.css";
import { Button, message, Modal, Table } from "antd";
import Main from "./Layout";
import UploadData from "./AddingCandidates/UploadData";
import { useAuth } from "../../common/useAuth";
import Loader from "../../Loader/Loader";
import ResumeUploadModal from "./AddingCandidates/UploadResumeModal";
import ScreenResumeModal from "./ScreenResumeModal";
import { render } from "@testing-library/react";

const CompleteJobPostRecruiter = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { apiurl, token } = useAuth();
	const [loading, setLoading] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [summary, setSummary] = useState(null);
	const [questionary, setquestionary] = useState(null);
	const [resumeModal, setResumeModal] = useState(false);
	const [resumes, setResumes] = useState(0)
	const [screenresumeModal, setScreenResumeModal] = useState(false);

	const [job, setJob] = useState(null);

	const fetchquestionary = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/generatequestionary/${id}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setquestionary(data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error(error);
		}
	};

	const fetchJobDetails = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/job-details/recruiter/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (!response.ok) {
				setLoading(false);
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			setJob(data.jd);
			setSummary(data.summary);
			setResumes(data.count);
		} catch (error) {
			console.error("Error fetching job details:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			fetchJobDetails();
		}
	}, []);

	const columns = [
		{
			title: "Round",
			dataIndex: "round_num",
			key: "round_num",
		},
		{
			title: "Interviewer Name",
			dataIndex: "name",
			key: "name",
			render: (name) => {
				return <p>{name?.username}</p>
			}
		},
		{
			title: "Interviewer Email",
			dataIndex: "name",
			key: "name",
			render: (name) => {
				return <p>{name.email}</p>
			}
		},
		{
			title: "Type of Interview",
			dataIndex: "type_of_interview",
			key: "type_of_interview",
		},
	];

	if (loading) {
		return <Loader />;
	}

	return (
		<Main>
			<div className="jd-rec-main">
				<div className="job-details-manager-main">
					<h1>Job Details by {job?.username?.username}</h1>
					<div className="job-detail">
						<span>Job Title:</span>
						<div>{job?.job_title}</div>
					</div>
					<div className="job-detail">
						<span>Job Description:</span>
						<div>{job?.job_description}</div>
					</div>
					<div className="job-detail">
						<span>Job Closing Date:</span>
						<div>{job?.job_close_duration}</div>
					</div>
					<div className="job-detail">
						<span>Languages</span>
						<div>{job?.languages}</div>
					</div>
					<div className="job-detail">
						<span>Notice Period</span>
						<div>{job?.notice_period}</div>
					</div>
					<div className="job-detail">
						<span>No Positions</span>
						<div>{job?.num_of_positions}</div>
					</div>
					<div className="job-detail">
						<span>Job Department:</span>
						<div>{job?.job_department}</div>
					</div>
					<div className="job-detail">
						<span>Primary Skills:</span>
						<div>{job?.primary_skills}</div>
					</div>
					<div className="job-detail">
						<span>Secondary Skills:</span>
						<div>{job?.secondary_skills}</div>
					</div>
					<div className="job-detail">
						<span>Years of Experience:</span>
						<div>{job?.years_of_experience}</div>
					</div>
					<div className="job-detail">
						<span>CTC:</span>
						<div>{job?.ctc}</div>
					</div>
					<div className="job-detail">
						<span>Rounds of Interview:</span>
						<div>{job?.rounds_of_interview}</div>
					</div>
					<div className="job-detail">
						<span>Job Location:</span>
						<div>{job?.job_locations}</div>
					</div>
					<div className="job-detail">
						<span>Job Type:</span>
						<div>{job?.job_type}</div>
					</div>
					<div className="job-detail">
						<span>Job Level:</span>
						<div>{job?.job_level}</div>
					</div>
					<div className="job-detail">
						<span>Qualifications:</span>
						<div>{job?.qualifications}</div>
					</div>
					<div className="job-detail">
						<span>Qualification Dept</span>
						<div>{job?.qualification_department}</div>
					</div>
					<div className="job-detail">
						<span>Timings:</span>
						<div>{job?.timings}</div>
					</div>
					<div className="job-detail">
						<span>Other Benefits:</span>
						<div>{job?.other_benefits}</div>
					</div>
					<div className="job-detail">
						<span>Working Days per Week:</span>
						<div>{job?.working_days_per_week}</div>
					</div>
					<div className="job-detail">
						<span>Decision Maker:</span>
						<div>{job?.decision_maker}</div>
					</div>
					<div className="job-detail">
						<span>Decision Maker Email:</span>
						<div>{job?.decision_maker_email}</div>
					</div>
					<div className="job-detail">
						<span>Bond:</span>
						<div>{job?.bond}</div>
					</div>
					<div className="job-detail">
						<span>Rotational Shift:</span>
						<div>{job?.rotational_shift ? "Yes" : "No"}</div>
					</div>
					<div className="job-detail">
						<span>Job Post Status</span>
						<div>{job?.status}</div>
					</div>
					<hr />
					<h2>Interviewers Data</h2>
					<Table
						columns={columns}
						dataSource={job?.interview_details || []}
						rowKey="id"
						pagination={false}
					/>
					<hr />
				</div>
				{job?.status === 'opened' &&
					<div className="right-div">
						{summary && <pre className="summarized-desc">{summary}</pre>}
						<div>
							<Button onClick={() => setResumeModal(true)}>
								Check Resume Score
							</Button>
							<Button
								onClick={() => {
									setScreenResumeModal(true);
								}}>
								Generate questionary
							</Button>
						</div>
						<Button onClick={() => setIsVisible(true)}>Share Resume</Button>
						<Modal
							open={isVisible}
							onCancel={() => setIsVisible(false)}
							footer={null}>
							<UploadData
								primary_skills={job?.primary_skills}
								secondary_skills={job?.secondary_skills} />
						</Modal>

						<p>Number of resumes sent :<span className="green"> {resumes}</span></p>
					</div>
				}
			</div>
			<ResumeUploadModal
				isVisible={resumeModal}
				onClose={() => { console.log("clicked"); setResumeModal(false) }}
				id={id}
			/>
			<ScreenResumeModal
				isVisible={screenresumeModal}
				onClose={() => setScreenResumeModal(false)}
				id={id}
			/>
			{questionary && (
				<div className="questionary">
					{questionary.map((question, index) => {
						return (
							<div key={index} className="quest">
								<span>Question {index + 1}:</span>
								<p>{question.question_text}</p>
								<span>Expected Answer:</span>
								<p>{question.correct_answer}</p>
							</div>
						);
					})}
				</div>
			)}
		</Main>
	);
};

export default CompleteJobPostRecruiter;
