import React, { useState, } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
	DashboardOutlined,
	TeamOutlined,
	BarChartOutlined,
	UnorderedListOutlined,
	UsergroupAddOutlined,
	FileTextOutlined,
	RiseOutlined,
	HomeOutlined,
	EyeOutlined,
	CommentOutlined,
	MailOutlined,
	LogoutOutlined,
} from "@ant-design/icons";
import logo from "./../../../images/LOGO-10.png";
import { useAuth } from "../../common/useAuth";

const { Header, Sider } = Layout;

const Main = ({ defaultSelectedKey, children }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [openKeys, setOpenKeys] = useState([]);
	const { handleLogout } = useAuth();
	const defaultSelectedKeys = () => {
		if (defaultSelectedKey) {
			console.log(defaultSelectedKey, " is the key")
			return defaultSelectedKey
		}
		const pathname = location.pathname;

		const menuItems = [
			"/manager",
			"/agency/recruiters",
			"/agency/postings",
			"/agency/terms",
			"/agency/negotiations",
			"/agency/detail_activities",
			"/agency/business_by_clients",
			"/agency/raise_invoice",
			"/agency/edit_requests",
		];

		const index = menuItems.findIndex((item) => pathname.startsWith(item));
		if (index === -1) return ["1"];
		return [`${index + 1}`];
	};

	const handleMenuItemClick = ({ key, action }) => {
		if (action === "logout") {
			Logout();
		}
		setOpenKeys(openKeys.includes(key) ? [] : [key]);
	};

	const Logout = () => {
		handleLogout();
		navigate("/login");
	};

	const menuItems = [
		{
			key: "1",
			icon: <DashboardOutlined />,
			label: <Link to="/">Dashboard</Link>,
		},
		{
			key: "2",
			icon: <BarChartOutlined />,
			label: <Link to="/agency/recruiters">Recruiters</Link>,
		},
		{
			key: "3",
			icon: <BarChartOutlined />,
			label: <Link to="/agency/postings">Postings</Link>,
		},
		{
			key: "4",
			icon: <FileTextOutlined />,
			label: <Link to="/agency/terms">Terms and Conditions</Link>,
		},
		{
			key: "5",
			icon: <FileTextOutlined />,
			label: <Link to="/agency/negotiations">Negotiations</Link>,
		},
		{
			key: "6",
			icon: <FileTextOutlined />,
			label: <Link to="/agency/detail_activities">Clients Activities</Link>,
		},
		{
			key: "7",
			icon: <FileTextOutlined />,
			label: <Link to="/agency/business_by_clients">Business by client</Link>,
		},
		{
			key: "8",
			icon: <FileTextOutlined />,
			label: <Link to="/agency/invoices">Raise invoice</Link>,
		},
		{
			key: "9",
			icon: <FileTextOutlined />,
			label: <Link to="/agency/view_candidate">View Candidate</Link>,
		},

		{
			key: "10",
			icon: <MailOutlined />,
			label: "Others",
			children: [
				{
					key: "9-0",
					icon: <CommentOutlined />,
					label: <Link to="/manager/edit_requests">See my edit requests</Link>,
				},
				{
					key: "9-1",
					icon: <RiseOutlined />,
					label: <Link to="/manager/negotiations">Negotiations</Link>,
				},
			],
		},
		{
			key: "11",
			icon: <LogoutOutlined />,
			label: "Logout",
			onClick: Logout,
		},
	];

	return (
		<Layout>
			<Sider
				className="side"
				breakpoint="md"
				collapsedWidth="0"
				width={"225px"}
				style={{
					height: "calc(100vh - 100px)",
					position: "fixed",
					left: "0",
					top: "100px",
					bottom: 0,
					zIndex: 1,
					background: "#fff",
				}}>
				<Menu
					theme="light"
					defaultSelectedKeys={defaultSelectedKey ? defaultSelectedKey : defaultSelectedKeys()}
					openKeys={openKeys}
					onOpenChange={(keys) => setOpenKeys(keys)}
					onClick={handleMenuItemClick}
					className="menu"
					mode="inline"
					items={menuItems}></Menu>
			</Sider>

			<Layout>
				<Header className="head" style={{ padding: 0 }}>
					<div className="header-logo">
						<a href="/home">
							<img alt="logo" src={logo}></img>
						</a>
					</div>
					<div className="header-buttons"></div>
				</Header>
				<div className="content">{children}</div>
			</Layout>
		</Layout>
	);
};

export default Main;
