import React, { useState, useEffect } from "react";
import { useAuth } from "../../common/useAuth";
import { message, Table, Tag } from "antd";
import Main from "./Layout";

const CandidateApplications = () => {
  const [data, setData] = useState([]);
  const { token, apiurl } = useAuth();

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiurl}/candidate/applications`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (result.error) {
        return message.error(result.error);
      }
      setData(result.data);
    } catch (e) {
      message.error("Failed to fetch applications");
      console.error(e);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Job ID",
      dataIndex: ["job_id", "id"],
      key: "job_id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const color = status === "selected" ? "green" : status === "rejected" ? "red" : "blue";
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Round Number",
      dataIndex: "round_num",
      key: "round_num",
    },
    {
      title: "Sender (Recruiter)",
      dataIndex: ["sender", "username"],
      key: "sender",
    },
    {
      title: "Receiver (Client)",
      dataIndex: ["receiver", "username"],
      key: "receiver",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text) => (text ? text : "No message"),
    },
    {
      title: "Next Interview",
      dataIndex: ["next_interview", "schedule_date"],
      key: "next_interview",
      render: (date) => (date ? new Date(date).toLocaleString() : "Not Scheduled"),
    },
  ];

  return (
    <Main defaultSelectedKey="2">
      <h2>Candidate Applications</h2>
      <Table columns={columns} dataSource={data} rowKey="id" pagination={{ pageSize: 5 }} />
    </Main>
  );
};

export default CandidateApplications;
