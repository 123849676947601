import React from "react";
import Main from "../Layout";
import { Button } from "antd";
import backicon from '../../../../images/agency/backbutton.svg'
import jobicon from '../../../../images/agency/jobicon.svg'
import searchicon from '../../../../images/agency/Search.svg'
import JobResponseCard from "../managercards/JobResponseCard";


import './JobResponses.css'

const JobResponses = () => {
  return (
    <Main>
      <div className="job-responses-container">
        <div className="field-search-container">
          <div className="field-container">
            <img src={backicon} />
            <div className="field-text-container">
              <div className="first-row">
                <h3>Field Sales</h3>
                <div style={{color:"#117B34",borderRadius:"10px",backgroundColor:"#EEFDF3"}} >
                  05/02/2025
                </div>
              </div>
              <div className="second-row">
                <div className="img-text">
                  <img  src={jobicon}/>
                  <span>Full Time</span>
                </div>
                <div className="img-text">
                <img src={jobicon}/>
                <span>Feb 5</span>
                </div>
                <div className="img-text">
                <img  src={jobicon} />
                <span>Client name</span>
                </div>
              </div>
            </div>
          </div>
            <div className="search-container">
              <img src={searchicon}/>
            <input className="search-input" placeholder="Search"   />
          </div>
        </div>
        <div className="all-interviews-container">
          <div className="interview-container">
            <h3>cv.review</h3>
            <JobResponseCard/>
            <JobResponseCard/>
            <JobResponseCard/>
            <JobResponseCard/>
            </div>
          <div className="interview-container">
            <h3>shortlisted</h3>
            <JobResponseCard/>
            <JobResponseCard/>
            <JobResponseCard/>
            <JobResponseCard/>
            <JobResponseCard/>
            <JobResponseCard/>
            <JobResponseCard/>
          </div>
          <div className="interview-container">
            <h3>1st interview</h3>
            <JobResponseCard/>
            <JobResponseCard/>
            <JobResponseCard/>
            <JobResponseCard/>
            <JobResponseCard/>

            
          </div>
          <div className="interview-container"> 
            <h3>2nd interview</h3>
            <JobResponseCard/>
            <JobResponseCard/>
            <JobResponseCard/>
            <JobResponseCard/>


          </div>
          <div className="interview-container"> 
            <h3>3rd interview</h3>
            <JobResponseCard/>
            <JobResponseCard/>
            <JobResponseCard/>


          </div>
        </div>
      </div>
    </Main>
  );
};

export default JobResponses;
