import React, { useState } from 'react'
import Profile from './Profile'
import { Button } from 'antd'
import Experiences from './Experiences'
import Certificates from './Certificates'
import Education from './Education'
import Main from '../Layout'


const CandidateDetails = () => {

    const [option, setOption] = useState("basic")

    return (
        <Main defaultSelectedKey="4">
            <h1>Candidate Profile</h1>
            <div className="options flex">
                <Button onClick={() => setOption("basic")}>Basic Details</Button>   ``
                <Button onClick={() => setOption("education")}>Education</Button>
                <Button onClick={() => setOption("experience")}>Experience</Button>
                <Button onClick={() => setOption("certificates")}>Certificates</Button>
            </div>
            <hr />
            {option && option == "basic" &&
                <Profile></Profile>
            }
            {option && option == "education" &&
                <Education></Education>
            }
            {option && option == "experience" &&
                <Experiences />
            }
            {option && option == "certificates" &&
                <Certificates></Certificates>
            }
        </Main>
    )
}

export default CandidateDetails