import React from "react";
import { Form, Input, Space, Select } from "antd";


const { Option } = Select
const PrimarySkillsForm = ({ primarySkills }) => {
    const SkillsArray = Object.values(primarySkills);
    const primarySkillsArray = SkillsArray[0]
        ? SkillsArray[0].split(',').map((skill) => skill.trim())
        : [];
    console.log(typeof primarySkills, SkillsArray[0], primarySkillsArray)

    return (
        <div>
            {primarySkillsArray && primarySkillsArray.length > 0 ? (
                primarySkillsArray.map((skill, index) => (
                    <Space
                        key={index}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                    >
                        <Form.Item
                            name={[`primary_skills`, index, "skill"]}
                            initialValue={skill}
                            label="Skill Name"

                        >
                            <input type="text" disabled />
                        </Form.Item>

                        <Form.Item
                            label="Years of Experience"
                            name={[`primary_skills`, index, "experience"]}
                            rules={[{ required: true, message: "Skill Experience is required" }]}
                        >
                            <Select placeholder="Select experience">
                                <Option value="0">0 years</Option>
                                <Option value="1-2">1-2 years</Option>
                                <Option value="2-3">2-3 years</Option>
                                <Option value="3-4">3-4 years</Option>
                                <Option value="4-5">4-5 years</Option>
                                <Option value="5+">5+ years</Option>
                                <Option value="N/A">N/A</Option>
                            </Select>
                        </Form.Item>
                    </Space>
                ))
            ) : (
                <p>No primary skills to display</p>
            )}
        </div>
    );
};

export default PrimarySkillsForm;
