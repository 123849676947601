import React from "react";
import Main from "./Layout";

const Approvals = () => {
    return (
        <Main defaultSelectedKey='4'>
            <h2>This is approvals page</h2>
        </Main>
    )
}

export default Approvals;