import React, { useState, useEffect } from "react";
import { Table, message, Tooltip, Button, Modal } from "antd";
import { Link } from "react-router-dom";
import JobStatus from "./JobStatus";
import "./MyJobPosts.css";
import { useAuth } from "../../../common/useAuth";
import Main from "../Layout";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const MyJobPosts = () => {
  const [jobList, setJobList] = useState([]);
  const { token } = useAuth();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    fetchJobPosts();
  }, []);

  const numberFormat = (value) => {
    let numberString = value.replace(/[^0-9.]/g, "");
    const numericValue = parseFloat(numberString);
    return isNaN(numericValue)
      ? ""
      : new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(numericValue);
  };

  const fetchJobPosts = async () => {
    try {
      const response = await fetch(`${apiurl}/client/job-postings/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch job posts");
      }
      const data = await response.json();
      setJobList(data);
      console.log("Posts", data);
    } catch (error) {
      message.error("Failed to fetch job posts. Please try again.");
    }
  };

  const handleDelete = (record) => {
    try {
      const apiUrl = `${apiurl}/job-postings/?job_id=${record.id}`;

      const response = fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });


      // Handle the response
      if (!response.ok) {
        throw new Error(`Failed to delete job post. Status: ${response.status}`);
      }

      const data = response.json();
      console.log("Job post deleted successfully:", data);
      return data;
    } catch (error) {
      console.error("Error deleting job post:", error);
    }
  };

  const handleCanceldelete = () => {
    setVisible(false);
  };

  const showConfirm = (record) => {
    setVisible(true);
  };

  const columns = [
    {
      title: "Job ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "CTC",
      dataIndex: "ctc",
      key: "ctc",
      render: (ctc) => numberFormat(ctc),
    },
    {
      title: "Job Post status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Job Closed by",
      dataIndex: "job_close_duration",
      key: "job_close_duration",
    },

    {
      title: "Approval Status",
      key: "approval_status",
      dataIndex: "approval_status",
      render: (_, record) => (
        <div>
          {record.approval_status === "accepted" &&
            <div className="green">Accepted</div>
          }
          {record.approval_status === "pending" &&
            <div className="warning">Pending</div>
          }
          {record.approval_status === "rejected" &&
            <div className="red">Rejected</div>
          }
        </div>
      )

    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div className="action-btns">
          <Link to={`/client/complete_job_post/${record.id}`}>
            View Details
          </Link>
          {record.status === "opened" ? (
            <Link to={`/client/get-resumes/${record.id}`}>View Responses</Link>
          ) : (
            <Tooltip title="Job post is closed">
              <Link style={{ color: "red", borderColor: "red" }} disabled>
                View Responses
              </Link>
            </Tooltip>
          )}
        </div>
      ),
    },

    {
      title: "Delete",
      key: "actions",
      render: (record) => {
        const isDisabled = record.approval_status === "accepted";

        return (
          <div>
            <Tooltip title={isDisabled ? "Cannot delete an accepted record" : ""}>
              <Button
                type="primary"
                danger
                onClick={() => showConfirm(record)}
                disabled={isDisabled}
              >
                Delete
              </Button>
            </Tooltip>

            <Modal
              title="Confirm Deletion"
              key={record.key}
              open={visible}
              onOk={() => handleDelete(record)}
              onCancel={handleCanceldelete}
              okText="Delete"
              cancelText="Cancel"
              okButtonProps={{ danger: true }}
            >
              <p>Are you sure you want to delete this item?</p>
            </Modal>
          </div>
        );

      },
    }
  ];

  return (
    <Main defaultSelectedKey="2">
      <div className="job-postings-list-main">
        <div>
          <Link to="/client/postjob">Add New Post</Link>
        </div>
        <div>
          {jobList && jobList.length > 0 ? (
            <Table
              columns={columns}
              dataSource={jobList}
              rowKey="id"
              expandable={{
                expandedRowRender: (record) => <JobStatus job={record} />,
                rowExpandable: (record) => true,
              }}
              pagination={false}
            />
          ) : (
            <div className="no-postings">There are no postings</div>
          )}
        </div>
      </div>
    </Main>
  );
};

export default MyJobPosts;
