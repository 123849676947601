import React, { useEffect, useState } from 'react';
import Main from './Layout';
import { useAuth } from '../../common/useAuth';
import { message, Table } from 'antd';
import { render } from '@testing-library/react';

const UpcomingInterviews = () => {
    const { token, apiurl } = useAuth();
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/candidate/upcoming-interviews/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                // Map data to include unique keys for each row
                const formattedData = result.map((item, index) => ({
                    ...item,
                    key: index, // Ant Design Table requires a unique 'key' for each row
                }));
                setData(formattedData);
            }
        } catch (e) {
            message.error(e.message || 'Something went wrong.');
        }
    };

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    const columns = [
        {
            title: 'Job ID',
            dataIndex: 'job_id',
            key: 'job_id',
            render: (job_id) => {
                return <p>{job_id.id}</p>
            }
        },
        {
            title: 'Job Title',
            dataIndex: 'job_id',
            key: 'job_id',
            render: (job_id) => {
                return <p>{job_id.job_title}</p>
            }
        },
        {
            title: 'Company Name',
            dataIndex: 'job_id',
            key: 'job_id',
            render: (job_id) => {
                return <p>{job_id.company_name}</p>
            }
        },
        {
            title: 'Interviewer Name',
            dataIndex: 'interviewer_name',
            key: 'interviewer_name',
        },
        {
            title: 'Round Number',
            dataIndex: 'round_num',
            key: 'round_num',
        },
        {
            title: (
                <>
                    Scheduled Date & Time
                    <br />
                    <span style={{ fontSize: '12px', color: 'gray' }}>(MM DD YYYY , HH:MM:SS )</span>
                </>
            ),
            dataIndex: 'scheduled_date_and_time',
            key: 'scheduled_date_and_time',
            render: (time) => {
                const formattedDate = new Date(time.schedule_date).toLocaleString()
                return <p>{formattedDate}</p>
            }
        },
    ];

    return (
        <Main defaultSelectedKey="3">
            <h1>Upcoming Interviews</h1>
            {data.length > 0 ? (
                <Table columns={columns} dataSource={data} />
            ) : (
                <p>No upcoming interviews found.</p>
            )}
        </Main>
    );
};

export default UpcomingInterviews;
