import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Form,
    Input,
    Typography,
    Alert,
    Tooltip,
    Row,
    Col,
} from "antd";
import {
    InfoCircleOutlined,
    UserOutlined,
    MailOutlined,
} from "@ant-design/icons";
import "./AddInterviewer.css";
import { useAuth } from "../../../common/useAuth";

const { Title } = Typography;


const validateUsername = (_, value) => {
    if (!value || /^[a-zA-Z0-9_]+$/.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject(
        new Error("Username should contain only letters, numbers, and underscores.")
    );
};

const validateEmail = (_, value) => {
    if (!value || /\S+@\S+\.\S+/.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject(new Error("Please enter a valid email address."));
};

const AddInterviewer = ({ onclose }) => {
    const [user, setUser] = useState({
        username: "",
        email: "",
    });

    const { apiurl, token } = useAuth();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiurl}/client/add-interviewers/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    username: user.username,
                    email: user.email,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || "Failed to register user");
            }
            onclose();
            setLoading(false);
            setSuccess("Account Created Successfully");
            setError(null);
            setUser({ username: "", email: "" });
        } catch (error) {
            setLoading(false);
            setError(error.message);
            setSuccess("");
        }
    };

    return (
        <Form
            onFinish={handleSubmit}
            className="add-rec-form"
            noValidate
            autoComplete="off">
            <Title level={2} style={{ textAlign: "center" }}>
                Create an account
            </Title>

            {error && <Alert message={error} type="error" showIcon />}
            {success && <Alert message={success} type="success" showIcon />}

            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, validator: validateUsername }]}
                        validateTrigger="onBlur">
                        <Input
                            name="username"
                            value={user.username}
                            onChange={handleInputChange}
                            placeholder="Enter your username"
                            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            suffix={
                                <Tooltip title="Username should contain only letters, numbers, and underscores">
                                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                                </Tooltip>
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, validator: validateEmail }]}
                        validateTrigger="onBlur">
                        <Input
                            name="email"
                            value={user.email}
                            onChange={handleInputChange}
                            placeholder="Enter your email"
                            prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} offset={6}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block loading={loading}>
                            Create account
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default AddInterviewer;

