import Main from './Layout';

const Candidate = () => {
  return (
    <Main defaultSelectedKey="1">
      <h2>Welcome to HireSync!</h2>
    </Main>
  );
};

export default Candidate;
