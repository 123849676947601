import React from "react";
import "./DashBoardCard.css";
import { useNavigate } from "react-router-dom";

const DashaBoardCard = ({ count, text, imgSrc }) => {

  const Navigate=useNavigate();

  const handleCards=()=>{
    console.log("card clicked",text);

    if(text==="Interview Schedule"){
      Navigate("/agency/jobresponses");
    }
  }
  return (
    <div className="rctr-dash-card" onClick={handleCards}>
      <div className="count-card-image">
        <div className="count-card">
          <h3>{count}</h3>
        </div>
        <div className="count-image">
          <img src={imgSrc} />
        </div>
      </div>
      {text === "Recruiter Allocation Pending" ? (
        <h2>
          Recruiter Allocation 
          Pending 
        </h2>
      ) : (
        <h2>{text}</h2>
      )}
    </div>
  );
};

export default DashaBoardCard;
