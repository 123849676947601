const skillsList = [
	"JavaScript",
	"Python",
	"Java",
	"SQL",
	"HTML",
	"CSS",
	"React",
	"Node.js",
	"Angular",
	"Vue.js",
	"REST APIs",
	"GraphQL",
	"Git",
	"AWS",
	"Azure",
	"Docker",
	"Kubernetes",
	"Cloud Computing",
	"Data Analysis",
	"Data Engineering",
	"Machine Learning",
	"Artificial Intelligence",
	"Cybersecurity",
	"Database Management",
	"Django",

	// Sales and Customer Service Skills
	"Sales Strategy",
	"Sales Forecasting",
	"Lead Generation",
	"CRM Software",
	"Salesforce",
	"Customer Relationship Management",
	"Account Management",
	"Cold Calling",
	"Negotiation",
	"Customer Service",
	"Active Listening",
	"Relationship Building",
	"Objection Handling",
	"Territory Management",
	"Sales Reporting",
	"Closing Techniques",
	"Upselling",
	"Cross-selling",

	// Marketing Skills (Digital and Traditional)
	"Content Marketing",
	"SEO",
	"Social Media Marketing",
	"Email Marketing",
	"Google Analytics",
	"Copywriting",
	"Pay-per-click (PPC)",
	"Brand Management",
	"Market Research",
	"Influencer Marketing",
	"Video Production",
	"Public Relations",
	"Campaign Management",
	"Content Strategy",
	"Google Ads",
	"A/B Testing",
	"Creative Thinking",
	"Brand Strategy",

	// Content Writing and Editorial Skills
	"Creative Writing",
	"Technical Writing",
	"Copywriting",
	"Proofreading",
	"Editing",
	"Content Strategy",
	"SEO Writing",
	"Research",
	"Storytelling",
	"Grammar",
	"Blogging",
	"Press Releases",
	"Content Optimization",
	"Headline Writing",
	"Content Calendar Management",
	"Audience Analysis",
	"Adaptability",
	"Attention to Detail",

	// Human Resources (HR) Skills
	"Recruitment",
	"Employee Onboarding",
	"HRIS Software",
	"Interviewing",
	"Talent Acquisition",
	"Employee Relations",
	"Performance Management",
	"Compensation and Benefits",
	"Payroll Management",
	"Conflict Resolution",
	"HR Compliance",
	"Training and Development",
	"Diversity and Inclusion",
	"Organizational Development",
	"Succession Planning",
	"HR Metrics and Analytics",
	"Coaching",
	"Team Building",

	// Project Management Skills
	"Project Planning",
	"Risk Management",
	"Budgeting",
	"Agile Methodologies",
	"Scrum",
	"Kanban",
	"JIRA",
	"Trello",
	"Project Scheduling",
	"Stakeholder Management",
	"Task Delegation",
	"Resource Allocation",
	"Time Management",
	"Gantt Charts",
	"Problem-solving",
	"Process Improvement",

	// Finance and Business Skills
	"Financial Analysis",
	"Accounting",
	"Financial Modeling",
	"Budgeting",
	"Forecasting",
	"Auditing",
	"Taxation",
	"Bookkeeping",
	"Profit and Loss Management",
	"Risk Assessment",
	"Investment Analysis",
	"Business Strategy",
	"Market Analysis",
	"Pricing Strategy",
	"Regulatory Compliance",
	"SAP",
	"ERP Systems",

	// Soft Skills (Universal Skills across roles)
	"Communication",
	"Teamwork",
	"Adaptability",
	"Leadership",
	"Problem-solving",
	"Critical Thinking",
	"Time Management",
	"Conflict Resolution",
	"Emotional Intelligence",
	"Decision Making",
	"Creativity",
	"Networking",
	"Public Speaking",
	"Collaboration",
	"Self-motivation",
	"Organizational Skills",

	// Design and Creative Skills
	"Graphic Design",
	"UI/UX Design",
	"Adobe Photoshop",
	"Figma",
	"Sketch",
	"Illustration",
	"Prototyping",
	"Wireframing",
	"Animation",
	"Adobe Illustrator",
	"Video Editing",
	"Motion Graphics",
	"Typography",
	"Visual Storytelling",
	"Responsive Design",
	"Layout Design",
];

export default skillsList;
