import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../common/useAuth'
import { message } from 'antd';
import Main from '../Layout';

const RecruiterProfile = () => {
    const { token, apiurl } = useAuth();
    const [data, setData] = useState();

    const fetchProfile = async () => {
        try {
            const response = await fetch(`${apiurl}/recruiter/get-profile/`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            const data = response.json()
            setData(data)
        }
        catch (e) {
            message.error(e);
        }
    }

    useEffect(() => {
        if (token) {
            fetchProfile()
        }
    }, [token])

    return (
        <Main defaultSelectedKey="4" >RecruiterProfile</Main>
    )
}

export default RecruiterProfile