import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../common/useAuth';
import dayjs from 'dayjs';
import { Form, Input, Button, Select, Space, Checkbox, Slider, InputNumber, DatePicker } from 'antd';
import './Editjob.css';
import Main from '../Layout';

const { TextArea } = Input
const { Option } = Select
const current = new Date()
const apiurl = process.env.REACT_APP_BACKEND_URL;
const jobLevels = ["Entry", "Mid", "Senior", "Lead"];
const jobTypeItems = [
    'Full Time',
    'Part Time',
    'Intern',
    'Consultant'
]
const internTimePeriod = [
    '1 Month',
    '2 Months',
    '3 Months',
    '4 Months',
    '5 Months',
    '6 Months',
    '7 Months',
    '8 Months',
    '9 Months',
    '10 Months',
    '11 Months',
    '1 Year'
]

const interviewTypes = [
    { value: 'non-technical', label: 'Non Tech' },
    { value: 'technical', label: 'Tech' },
    { value: 'assignment', label: 'Assignment' }
]

const interviewModes = [
    { value: "face_to_face", label: "Face-to-Face" },
    { value: "online", label: "Online" },
    { value: "telephone", label: "Telephone" },
];

const listOfVisa = ['H1 Visa', 'L1 Visa', 'TN Permit Holder', 'Green Card Holder', 'US Citizen', 'Authorized to work in US']

const probationPeriodItems = [
    '1 Month',
    '2 Months',
    '3 Months',
    '4 Months',
    '5 Months',
    '6 Months',
    '7 Months',
    '8 Months',
    '9 Months',
    '10 Months',
    '11 Months',
    '1 Year',
    '1 and half Year',
    '2 Years',
    'None'
]
const Editjob = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const { token } = useAuth()
    const [form] = Form.useForm()
    const [noticePeriod, setNoticePeriod] = useState('');
    const [rotationalShift, setRotationalShift] = useState(false);
    const [probationTime, setProbationTime] = useState("")
    const [internTime, setInternTime] = useState("")
    const [consultantTime, setConsultantTime] = useState("")
    const [jobType, setJobType] = useState()
    const [organizationDetails, setOrganizationDetails] = useState("")
    const [jobCloseDuration, setJobCloseDuration] = useState();
    const [job, setJob] = useState({
        job_id: id,
        job_title: '',
        job_description: '',
        primary_skills: '',
        secondary_skills: '',
        years_of_experience: 0,
        ctc: '',
        rounds_of_interview: 0,
        interviewers: [],
        interviewer_emails: [],
        job_locations: '',
        job_type: '',
        job_level: '',
        qualifications: '',
        timings: '',
        other_benefits: '',
        working_days_per_week: 5,
        interview_process: '',
        decision_maker: '',
        bond: '',
        rotational_shift: false,
        age: '',
        differently_abled: '',
        gender: '',
        languages: '',
        notice_time: '',
        notice_period: '',
        time_period: '',
        qualification_department: '',
        visa_status: '',
        intern_time_period: '',
        consultant_time_period: '',
        probation_time_period: '',
    });
    const renderJobTypeFields = (jobType) => {
        switch (jobType) {
            case 'Full Time':
                return (
                    <Form.Item label="Probation Period" name="probation_time_period">
                        <Select onChange={setProbationTime} options={probationPeriodItems.map(val => ({ label: val, value: val }))} />
                    </Form.Item>
                );
            case 'Intern':
                return (
                    <Form.Item label="Intern Time Period" name="intern_time_period">
                        <Select onChange={setInternTime} options={internTimePeriod.map(val => ({ label: val, value: val }))} />
                    </Form.Item>
                );
            case 'Consultant':
                return (
                    <Form.Item label="Consultant Time Period" name="consultant_time_period">
                        <Input onChange={setConsultantTime}></Input>
                    </Form.Item>
                );
            default:
                return null;
        }
    };

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/org-particular-job/?id=${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const data = await response.json();
            if (data.error) {
                alert(data.error);
                return navigate(-1);
            }
            setJob(data);
            setRotationalShift(data.rotational_shift)
            console.log("job type is ", data.job_type)
            setJobType(data.job_type)
            setOrganizationDetails(data.organization)
            setJobCloseDuration(data.job_close_duration)
            if (data.job_type === "Full Time") {
                setProbationTime(data.time_period);
                form.setFieldsValue({ probation_time_period: data.time_period });
            } else if (data.job_type === "Intern") {
                setInternTime(data.time_period);
                form.setFieldsValue({ intern_time_period: data.time_period });
            } else if (data.job_type === "Consultant") {
                setConsultantTime(data.time_period)
                form.setFieldsValue({ consultant_time_period: data.time_period });
            }

            form.setFieldsValue(data);
            console.log("data", data)
        }
        catch (e) {
            console.error("Error fetching job details ", e);
        }
    }

    useEffect(() => {
        if (token) {
            fetchData()
        }
    }, [token, id]);

    // const handleInputChange = (e) => {
    //     const { name, value, type, checked } = e.target;
    //     if (name.startsWith('interviewers-')) {
    //         const index = parseInt(name.split('-')[1], 10);
    //         const updatedInterviewers = [...job.interviewers];
    //         updatedInterviewers[index] = value;
    //         setJob({
    //             ...job,
    //             interviewers: updatedInterviewers
    //         });
    //     } else if (name.startsWith('interviewer_emails-')) {
    //         const index = parseInt(name.split('-')[1], 10);
    //         const updatedInterviewerEmails = [...job.interviewer_emails];
    //         updatedInterviewerEmails[index] = value;
    //         setJob({
    //             ...job,
    //             interviewer_emails: updatedInterviewerEmails
    //         });
    //     } else if (name === 'rounds_of_interview') {
    //         const rounds = parseInt(value, 10) || 0;
    //         setJob(prevState => ({
    //             ...prevState,
    //             rounds_of_interview: rounds,
    //             interviewers: Array(rounds).fill(''),
    //             interviewer_emails: Array(rounds).fill(''),
    //         }));
    //     } else {
    //         setJob(prevJob => ({
    //             ...prevJob,
    //             [name]: value
    //         }));
    //     }
    // };

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        const payload = {
            ...formData,
            time_period: jobType === "Intern" ? internTime : jobType === "Full Time" ? probationTime : jobType === "Consultant" ? `${consultantTime}` : " ",
            organization_code: organizationDetails.org_code,
            job_post_id: job.id
        };
        console.log(payload);
        fetch(`${apiurl}/org-edit-jobpost/?id=${job.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        })
            .then(response => {
                if (response.ok) {
                    setMessage("Approval request sent to the client successfully");
                    alert(message);
                    form.setFieldsValue(job);
                    navigate(-1);
                } else {
                    throw new Error('Failed to update job details');
                }
            })
            .catch(error => {
                console.error('Error updating job details:', error)
                alert(error)
            }
            );
    };

    return (
        <Main className="no-overflow">
            <h1>Edit the job post - {job && job.job_title} [id = {job.id}]</h1>
            {job.age != null &&
                <Form
                    form={form}

                    layout="vertical"
                    // initialValues={job}
                    onFinish={handleSubmit}
                    style={{ maxWidth: 800, margin: "0 auto" }}
                >
                    <Form.Item label="Job Title" name="job_title">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Job Type"
                        name="job_type"
                        rules={[{ required: true, message: "Please enter job type" }]}>
                        <Select
                            placeholder="Choose the job Type"
                            onChange={(value) => {
                                setJobType(value);
                            }}
                        >
                            {jobTypeItems.map((val) => (
                                <Option value={val} key={val}>{val}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={(prev, curr) => prev.job_type !== curr.job_type}>
                        {({ getFieldValue }) => renderJobTypeFields(getFieldValue('job_type'))}
                    </Form.Item>
                    <Form.Item label="Job Department" name="job_department">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Job Description" name="job_description">
                        <TextArea rows={3} />
                    </Form.Item>
                    <Form.Item
                        label="Job Level"
                        name="job_level"
                        rules={[
                            { required: true, message: "Please select job level" },
                        ]}>
                        <Select placeholder="Select job level">
                            {jobLevels.map((level) => (
                                <Option key={level} value={level}>
                                    {level}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Primary Skills" name="primary_skills">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Secondary Skills" name="secondary_skills">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Qualifications" name="qualifications">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Qualification Department" name="qualification_department">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Age" name="age">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Bond" name="bond">
                        <Input />
                    </Form.Item>
                    <Form.Item label="CTC" name="ctc">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Decision Maker" name="decision_maker">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Decision Maker Email" name="decision_maker_email">
                        <Input type="email" />
                    </Form.Item>
                    <Form.Item label="Number of positions" name="num_of_positions">
                        <InputNumber min={1} defaultValue={1} />
                    </Form.Item>
                    {/* <Form.Item label="Last Date to submit" name="job_close_duration">
                        <DatePicker
                            value={jobCloseDuration ? dayjs(jobCloseDuration, "YYYY-MM-DD") : null} // Dynamically set value
                            format="YYYY-MM-DD" // Ensure date is displayed in the correct format
                            disabledDate={(current) => current && current.isBefore(dayjs(), 'day')} // Disable past dates
                            onChange={(date, dateString) => {
                                console.log("Selected date:", dateString);
                            }}
                        />
                    </Form.Item> */}
                    <Form.Item
                        label="Differently Abled"
                        name="differently_abled"
                        rules={[
                            { required: true, message: "Please select the below options" },
                        ]}>
                        <Select placeholder='Select below'>
                            <Option key='Yes' value="Yes">Yes (Only for differently abled)</Option>
                            <Option key='No' value="No">No</Option>
                            <Option key='No Mention' value="No Mention">No Mention</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Gender"
                        name="gender"
                        rules={[
                            { required: true, message: "Please select the gender" },
                        ]}>
                        <Select placeholder="Select any gender requirements">
                            <Option key='Male' value="Male">Male</Option>
                            <Option key='Female' value="Female">Female</Option>
                            <Option key='Others' value="Others">Others</Option>
                            <Option key='No Mention' value="No Mention">No Mention</Option>
                            <Option key='Both Male and Female' value="Both Male and Female">Both Male and Female</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Industry" name="industry">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Job Locations" name="job_locations">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Languages" name="languages">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Notice Period"
                        name="notice_period"
                        rules={[
                            { required: true, message: "Select a Notice Period" },
                        ]}>
                        <Select placeholder="Select a notice period" onChange={(e) => setNoticePeriod(e)}
                        >
                            <Option key="serving_notice" value="serving_notice">Serving notice</Option>
                            <Option key='need_to_serve_notice' value='need_to_serve_notice'>Need to serve notice</Option>
                            <Option key='immediate_joining' value='immediate_joining'>Immediate Joining</Option>
                        </Select>

                    </Form.Item>
                    {noticePeriod === 'need_to_serve_notice' &&
                        <Form.Item
                            label="Notice Time Period"
                            name="notice_time"
                            rules={[
                                { required: true, message: "Select a Time Period" },
                            ]}>
                            <Select placeholder="Select a Time period" >
                                <Option key="15" value="15">15 Days</Option>
                                <Option key="30" value="30">30 Days</Option>
                                <Option key="45" value="45">45 Days</Option>
                                <Option key="2M" value="2M">2 Months </Option>
                                <Option key="6M" value="6M">6 Months </Option>
                            </Select>

                        </Form.Item>
                    }
                    {/* <Form.Item label="Notice Time" name="notice_time">
                        <Input />
                    </Form.Item> */}
                    <Form.Item label="Other Benefits" name="other_benefits">
                        <TextArea rows={2} />
                    </Form.Item>
                    <Form.Item label="Rotational Shift" name="rotational_shift" valuePropName="checked" >
                        <Checkbox onChange={(e) => setRotationalShift(e.target.checked)}>{rotationalShift ? "Yes" : "No"}</Checkbox>
                    </Form.Item>
                    <Form.Item label="Timings" name="timings">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Visa Status" name="visa_status">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Job Close Date" name="job_close_duration">
                        <Input></Input>
                    </Form.Item>
                    <Form.Item label="Working Days per Week" name="working_days_per_week">
                        <Input type="number" min={1} max={7} />
                    </Form.Item>
                    <Form.Item label="Years of Experience" name="years_of_experience">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Rounds of Interview" name="rounds_of_interview">
                        <Input type="number" disabled />
                    </Form.Item>

                    <h3>Interview Details</h3>
                    <Form.List name="interview_details">
                        {(fields) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Space key={key} align="baseline" style={{ display: "flex", marginBottom: 8 }}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, "round_num"]}
                                            fieldKey={[fieldKey, "round_num"]}
                                            label="Round Number"
                                            rules={[{ required: true, message: "Round number is required" }]}
                                        >
                                            <Input placeholder="Round Number" disabled />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, "name", "username"]}
                                            fieldKey={[fieldKey, "name", "username"]}
                                            label="Name"
                                            rules={[{ required: true, message: "Name is required" }]}
                                        >
                                            <Input placeholder="Name" disabled />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, "name", "email"]}
                                            fieldKey={[fieldKey, "name", "email"]}
                                            label="Email"
                                            rules={[{ required: true, message: "Email is required" }]}
                                        >
                                            <Input placeholder="Email" disabled />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, "type_of_interview"]}
                                            fieldKey={[fieldKey, "type_of_interview"]}
                                            label="Type of Interview"
                                        >
                                            <Select>
                                                {interviewTypes.map((mode) => (
                                                    <Option key={mode.value} value={mode.value}>
                                                        {mode.label}
                                                    </Option>
                                                ))}
                                            </Select>

                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, "mode_of_interview"]}
                                            fieldKey={[fieldKey, "mode_of_interview"]}
                                            label="Mode of Interview"
                                        >
                                            <Select>
                                                {interviewModes.map((mode) => (
                                                    <Option key={mode.value} value={mode.value}>
                                                        {mode.label}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                                    </Space>
                                ))}
                            </>
                        )}
                    </Form.List>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Send Edit Request
                        </Button>
                    </Form.Item>

                </Form>
            }
        </Main>
    );
};

export default Editjob;
