import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../common/useAuth';
import { message, Button, Form, Input, DatePicker, Upload, Image, Table } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import './Experiences.css';

const CandidateExperience = () => {
    const [data, setData] = useState([]);
    const { apiurl, token } = useAuth();
    const [add, setAdd] = useState(false);
    const [form] = Form.useForm();

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/candidate/experience/`, {
                method: 'GET',
                headers: { "Authorization": `Bearer ${token}` },
            });
            if (!response.ok) return message.error("Failed to fetch experience data");
            const result = await response.json();
            setData(result);
        } catch (error) {
            message.error("Error fetching candidate experience");
        }
    };

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    const handleAdd = async (values) => {
        const formData = new FormData();
        formData.append('company_name', values.company_name);
        formData.append('from_date', values.from_date.format('YYYY-MM-DD'));
        if (values.to_date) formData.append('to_date', values.to_date.format('YYYY-MM-DD'));
        formData.append('status', values.status);
        console.log(values.reason_for_resignation)
        if (values.reason_for_resignation) formData.append('reason_for_resignation', values.reason_for_resignation);
        const getFile = (fileList) => (fileList && fileList.length > 0 ? fileList[0].originFileObj : null);

        formData.append('relieving_letter', getFile(values.relieving_letter));
        formData.append('pay_slip1', getFile(values.pay_slip1));
        formData.append('pay_slip2', getFile(values.pay_slip2));
        formData.append('pay_slip3', getFile(values.pay_slip3));


        try {
            const response = await fetch(`${apiurl}/candidate/experience/`, {
                method: 'POST',
                headers: { "Authorization": `Bearer ${token}` },
                body: formData,
            });
            if (!response.ok) return message.error("Failed to add experience");
            message.success('Experience added successfully');
            setAdd(false);
            fetchData();
            form.resetFields(); // Reset form after submission
        } catch (error) {
            message.error("Error adding experience");
        }
    };

    const handleRemove = async (experienceId) => {
        try {
            const response = await fetch(`${apiurl}/candidate/experience/?id=${experienceId}`, {
                method: 'DELETE',
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                message.error("Failed to remove experience");
                return;
            }

            message.success('Experience removed successfully');
            fetchData();
        } catch (error) {
            message.error("Error removing Experience");
        }
    };

    const columns = [
        { title: 'Company Name', dataIndex: 'company_name', key: 'company_name' },
        { title: 'From Date', dataIndex: 'from_date', key: 'from_date' },
        { title: 'To Date', dataIndex: 'to_date', key: 'to_date', render: (text) => text || 'Present' },
        { title: 'Status', dataIndex: 'status', key: 'status' },
        { title: 'Reason for Resignation', dataIndex: 'reason_for_resignation', key: 'reason_for_resignation' },
        {
            title: 'Relieving Letter',
            dataIndex: 'relieving_letter',
            key: 'relieving_letter',
            render: (text) => text ? <a href={`${apiurl}${text}`} target="_blank" rel="noopener noreferrer">View</a> : 'N/A',
        },
        {
            title: 'Pay Slip 1',
            dataIndex: 'pay_slip1',
            key: 'pay_slip1',
            render: (text) => text ? <a href={`${apiurl}${text}`} target="_blank" rel="noopener noreferrer">View</a> : 'N/A',
        },
        {
            title: 'Pay Slip 2',
            dataIndex: 'pay_slip2',
            key: 'pay_slip2',
            render: (text) => text ? <a href={`${apiurl}${text}`} target="_blank" rel="noopener noreferrer">View</a> : 'N/A',
        },
        {
            title: 'Pay Slip 3',
            dataIndex: 'pay_slip3',
            key: 'pay_slip3',
            render: (text) => text ? <a href={`${apiurl}${text}`} target="_blank" rel="noopener noreferrer">View</a> : 'N/A',
        },
        {
            title: "Action",
            dataIndex: 'id',
            key: 'id',
            render: (id) => (
                <Button onClick={() => handleRemove(id)}>Delete</Button>
            )
        }
    ];

    return (
        <div>
            <h2>Candidate Experience</h2>
            <Button type='primary' onClick={() => setAdd(true)}>Add Experience <PlusSquareOutlined /></Button>
            <Table dataSource={data} columns={columns} rowKey="id" style={{ marginTop: 20 }} />

            {add && (
                <Form form={form} onFinish={handleAdd} layout="vertical">
                    <Form.Item label="Company Name" name="company_name" rules={[{ required: true, message: "Company name is required" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="From Date" name="from_date" rules={[{ required: true, message: "From date is required" }]}>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item label="To Date" name="to_date">
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item label="Status" name="status" rules={[{ required: true, message: "Status is required" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Reason for Resignation" name="reason_for_resignation">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Relieving Letter" name="relieving_letter" valuePropName="fileList" getValueFromEvent={e => e?.fileList || []}>
                        <Upload beforeUpload={() => false} maxCount={1}>
                            <Button>Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item label="Pay Slip 1" name="pay_slip1" valuePropName="fileList" getValueFromEvent={e => e?.fileList || []}>
                        <Upload beforeUpload={() => false} maxCount={1}>
                            <Button>Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item label="Pay Slip 2 (Optional)" name="pay_slip2" valuePropName="fileList" getValueFromEvent={e => e?.fileList || []}>
                        <Upload beforeUpload={() => false} maxCount={1}>
                            <Button>Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item label="Pay Slip 3 (Optional)" name="pay_slip3" valuePropName="fileList" getValueFromEvent={e => e?.fileList || []}>
                        <Upload beforeUpload={() => false} maxCount={1}>
                            <Button>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">Submit</Button>
                        <Button type="default" onClick={() => setAdd(false)} style={{ marginLeft: 10 }}>Cancel</Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
};

export default CandidateExperience;
