import React, { useEffect, useState } from "react";
import { useAuth } from "../../common/useAuth";
import Main from "./Layout";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import DashaBoardCard from "./managercards/DashaBoardCard";
import InterviewsCard from "./managercards/InterviewsCard";
import "./Manager.css";
import interviewschedule from "../../../images/agency/interviewschedule.svg";
import approvalpending from "../../../images/agency/approvalpending.svg";
import rctrjoballocation from "../../../images/agency/rctrjoballocation.svg";
import { Table, Typography } from "antd";
import tableicon from "../../../images/agency/tableicon.svg";
const { Text } = Typography;

// const apiurl = process.env.REACT_APP_BACKEND_URL;

const Manager = () => {
  const { token, apiurl } = useAuth();
  const [Data, setData] = useState(null);
  const [latestJob, setLatestJobs] = useState(null);
  const [tab, setTabs] = useState("Jobs");

  const [upcomingInterviews, setUpcomingInterviews] = useState(null);

  const navigate = useNavigate();
  const fetchTandC = async () => {
    const response = await fetch(`${apiurl}/organization-terms`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.error) {
      message.error(data.error);
    }
    if (data === null) {
      alert("add terms and conditions!");
      navigate("/agency/terms");
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiurl}/manager/dashboard/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data.error) {
        return message.error(data.error);
      }

      if (data.data) {
        setData(data.data);
      }

      if (data.latest_jobs) {
        setLatestJobs(data.latest_jobs);
      }

      if (data.upcoming_interviews) {
        setUpcomingInterviews(data.upcoming_interviews);
      }
    } catch (e) {
      message.error(e);
    }
  };

  const formattedTime = (time) => {
    time = new Date(time).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return time;
  };

  console.log(
    "latestJob",
    latestJob,
    "upcomingInterviews",
    upcomingInterviews,
    "Data",
    Data
  );

  useEffect(() => {
    fetchTandC();
    fetchData();
  }, []);

  const cardData = [
    {
      count: Data?.interviews_scheduled,

      imgSrc: interviewschedule,
      text: "Interview Schedule",
    },
    {
      count: Data?.approval_pending,
      imgSrc: approvalpending,
      text: "Approval Pending",
    },
    {
      count: Data?.recruiter_allocation_pending,
      imgSrc: rctrjoballocation,
      text: "Recrutier Allocation Pending",
    },
    {
      count: Data?.jobpost_edit_requests,
      imgSrc: interviewschedule,
      text: "job post edit request",
    },
    {
      count: Data?.opened_jobs,
      imgSrc: interviewschedule,
      text: "Active job posts",
    },
  ];
  const columns = [
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      className: "first-column",
      render: (text, record) => (
        <div className="tableicon">
          <img src={tableicon} />
          <h4>{text}</h4>
        </div>
      ),
    },
    {
      title: "Positions Left",
      dataIndex: "positions_left",
      key: "positions_left",
    },
    {
      title: "Applications",
      dataIndex: "applications",
      key: "applications",
    },
    {
      title: "Interviewed",
      dataIndex: "interviewed",
      key: "interviewed",
    },
    {
      title: "Rejected",
      dataIndex: "rejected",
      key: "rejected",
    },
    {
      title: "Feedback Pending",
      dataIndex: "feedback_pending",
      key: "feedback_pending",
    },
    {
      title: "Offered",
      dataIndex: "offered",
      key: "offered",
    },
  ];

  const handleTab = (tab) => {
    if (tab === "Jobs") {
      setTabs("Jobs");
    } else {
      setTabs("onBoarding");
    }
  };

  return (
    <Main>
      {/* {message && message.success && (
        <div className="success">{message.success}</div>
      )}
      {message && message.error && <div className="error">{message.error}</div>} */}
      <div className="manager-dashboard-container">
        <div className="overview-require">
          <div className="overview">
            <h2>Overview</h2>
            <div className="cards-container">
              {cardData.map((obj, index) => {
                return (
                  <DashaBoardCard
                    key={index}
                    count={obj.count}
                    text={obj.text}
                    imgSrc={obj.imgSrc}
                  />
                );
              })}
            </div>
          </div>

          <div className="require-attention">
            <h2>Require Attention</h2>
            <div style={{ display: "flex", gap: "10px" }}>
              <h3
                onClick={() => handleTab("Jobs")}
                style={{
                  cursor: "pointer",
                  padding:"5px",
                  borderBottom: tab === "Jobs" ?"2px solid #FFB8B8" : "none", 
                }}
              >
                Jobs
              </h3>
              <h3
                onClick={() => handleTab("onBoarding")}
                style={{ cursor: "pointer" ,
                  padding:"5px",
                borderBottom: tab ==="onBoarding"?"2px solid #FFB8B8" : "none", 
                }}
              >
                onBoarding
              </h3>
            </div>
            {tab === "Jobs" ? (
              <Table
                dataSource={latestJob}
                columns={columns}
                bordered={false}
                pagination={false}
                className="require-attention-table"
              />
            ) : (
              "Onboarding"
            )}
          </div>
        </div>
        <div className="upcomming-meets">
          <h2>Upcomming Interviews</h2>
          <div className="interview-cards-container">
            {upcomingInterviews &&
              upcomingInterviews.map((key, index) => (
                <InterviewsCard
                key={index}
                  time={formattedTime(key.scheduled_time)}
                  round_num={key.round_num}
                  job_title={key.job_title}
                  interviewer_name={key.candidate_name}
                  is_highlighted={(index + 1) % 4 == 0}
                />
              ))}
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Manager;
