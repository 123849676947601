import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./CompleteJobPost.css";
import { Button, Table, Modal, Select, message } from "antd";
import Main from "../Layout";
import { useAuth } from "../../../common/useAuth";

const CompleteJobPost = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { apiurl, token } = useAuth();
	const [job, setJob] = useState(null);
	const [recruiters, setRecruiters] = useState([]);
	const [selectedRecruiter, setSelectedRecruiter] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [status, setStatus] = useState();
	const fetchJobDetails = async () => {
		if (token) {
			try {
				const response = await fetch(`${apiurl}/job-details?job_id=${id}`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				setJob(data);
			} catch (error) {
				console.error("Error fetching job details:", error);
			}
		}
	};

	const fetchJobEditDetails = async () => {
		try {

			const response = await fetch(`${apiurl}/client/job-edit-details?id=${id}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			const data = await response.json()
			if (data.status) {
				setStatus(data.status);
			}
			else if (data.notFound) {
				setStatus('not found');
			}
		}
		catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		fetchJobDetails();
		fetchJobEditDetails();
	}, [token, id, apiurl]);

	const fetchRecruiters = async () => {
		try {
			const response = await fetch(`${apiurl}/agency/recruiters/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setRecruiters(data);
		} catch (error) {
			console.error("Error fetching recruiters:", error);
		}
	};

	const handleAssign = () => {
		setIsModalVisible(true);
		fetchRecruiters();
	};

	const handleChangeRecruiter = () => {
		setIsModalVisible(true);
		fetchRecruiters();
	};

	const handleAcceptJobPost = async (accept) => {
		try {
			const response = await fetch(`${apiurl}/org/accept-job/?id=${id}&accept=${accept}`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`
				}
			})
			const data = response.json();
			if (data.error) {
				message.error(data.error)
			}
			if (data.message) {
				message.success(data.message)
			}
			window.location.reload()
		}
		catch (e) {
			console.error(e)
			message.error(e)
		}
	}

	const handleCloseJob = async () => {
		try {
			const response = await fetch(`${apiurl}/manager/close-job/?id=${id}`, {
				method: 'POST',
				headers: {
					"Authorization": `Bearer ${token}`
				}
			})
			if (!response.data) {
				message.error("There is an error in closing job");
			}
			const data = await response.json();
			if (data.error) {
				return message.error(data.error);
			}
			if (data.message) {
				message.success(data.message);
				window.location.reload();
			}
			console.log(data)
		}
		catch (e) {
			console.error(e)
		}
	}


	const handleSubmitRecruiter = async () => {
		if (!selectedRecruiter) {
			message.error("Please select a recruiter");
			return;
		}
		try {
			const response = await fetch(`${apiurl}/assign-recruiter/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ job_id: id, recruiter_ids: selectedRecruiter }),
			});
			const data = await response.json();
			if (response.ok) {
				message.success("Recruiter updated successfully");
				setIsModalVisible(false);
				fetchJobDetails();
			} else {
				message.error("Failed to update recruiter");
			}
		} catch (error) {
			console.error("Error updating recruiter:", error);
			message.error("An error occurred while updating the recruiter");
		}
	};

	const columns = [
		{
			title: "Round",
			dataIndex: "round_num",
			key: "round_num",
		},
		{
			title: "Interviewer Name",
			dataIndex: "name",
			key: "name",
			render: (name) => {
				return <p>{name.username}</p>;
			},
		},
		{
			title: "Interviewer Email",
			dataIndex: "name",
			key: "name",
			render: (name) => {
				return <p>{name.email}</p>;
			},
		},
		{
			title: "Type of Interview",
			dataIndex: "type_of_interview",
			key: "type_of_interview",
		},
		{
			title: "Mode of Interview",
			dataIndex: "mode_of_interview",
			key: "mode_of_interview",
		},
	];

	return (
		<Main>
			{message && message.success && <div className="success">{message.success}</div>}
			{message && message.error && <div className="error">{message.error}</div>}
			<div className="job-details-manager-main">
				<h1>Job Details by {job?.username?.username}</h1>
				<div className="job-detail">
					<span>Job Title:</span>
					<div>{job?.job_title}</div>
				</div>
				<div className="job-detail">
					<span>Job Description:</span>
					<div>{job?.job_description}</div>
				</div>
				<div className="job-detail">
					<span>Job Closing Date:</span>
					<div>{job?.job_close_duration}</div>
				</div>
				<div className="job-detail">
					<span>Languages</span>
					<div>{job?.languages}</div>
				</div>
				<div className="job-detail">
					<span>Notice Period</span>
					<div>{job?.notice_period}</div>
				</div>
				<div className="job-detail">
					<span>No Positions</span>
					<div>{job?.num_of_positions}</div>
				</div>
				<div className="job-detail">
					<span>Job Department:</span>
					<div>{job?.job_department}</div>
				</div>
				<div className="job-detail">
					<span>Primary Skills:</span>
					<div>{job?.primary_skills}</div>
				</div>
				<div className="job-detail">
					<span>Secondary Skills:</span>
					<div>{job?.secondary_skills}</div>
				</div>
				<div className="job-detail">
					<span>Years of Experience:</span>
					<div>{job?.years_of_experience}</div>
				</div>
				<div className="job-detail">
					<span>CTC:</span>
					<div>{job?.ctc}</div>
				</div>
				<div className="job-detail">
					<span>Rounds of Interview:</span>
					<div>{job?.rounds_of_interview}</div>
				</div>
				<div className="job-detail">
					<span>Job Location:</span>
					<div>{job?.job_locations}</div>
				</div>
				<div className="job-detail">
					<span>Job Type:</span>
					<div>{job?.job_type}</div>
				</div>
				<div className="job-detail">
					<span>Job Level:</span>
					<div>{job?.job_level}</div>
				</div>
				<div className="job-detail">
					<span>Qualifications:</span>
					<div>{job?.qualifications}</div>
				</div>
				<div className="job-detail">
					<span>Qualification Dept</span>
					<div>{job?.qualification_department}</div>
				</div>
				<div className="job-detail">
					<span>Timings:</span>
					<div>{job?.timings}</div>
				</div>
				<div className="job-detail">
					<span>Other Benefits:</span>
					<div>{job?.other_benefits}</div>
				</div>
				<div className="job-detail">
					<span>Working Days per Week:</span>
					<div>{job?.working_days_per_week}</div>
				</div>
				<div className="job-detail">
					<span>Decision Maker:</span>
					<div>{job?.decision_maker}</div>
				</div>
				<div className="job-detail">
					<span>Decision Maker Email:</span>
					<div>{job?.decision_maker_email}</div>
				</div>
				<div className="job-detail">
					<span>Bond:</span>
					<div>{job?.bond}</div>
				</div>
				<div className="job-detail">
					<span>Rotational Shift:</span>
					<div>{job?.rotational_shift ? "Yes" : "No"}</div>
				</div>
				<div className="job-detail">
					<span>Job Post Status</span>
					<div>{job?.status}</div>
				</div>
				<hr />
				<h2>Interviewers Data</h2>
				<Table
					columns={columns}
					dataSource={job?.interview_details || []}
					rowKey="id"
					pagination={false}
				/>
				<hr />
				{
					job?.approval_status === 'pending' && (
						<div className="buttons">
							<Button
								onClick={() => handleAcceptJobPost(true)}
								type="primary"
							>
								Accept
							</Button>
							<Button
								onClick={() => handleAcceptJobPost(false)}
								type="primary"
								danger
							>
								Reject
							</Button>

						</div>
					)
				}
				{job?.approval_status === 'rejected' && (
					<h2 className="red">Rejected</h2>
				)}
				{job?.approval_status === 'accepted' && job?.status === "opened" && (
					<div>
						<div>
							<h3>Job Assigned To..</h3>
							{job.assigned_to && job.assigned_to.length > 0 ? (
								<>
									{job.assigned_to.map((recruiter) => (
										<h4 key={recruiter.id} className="green">
											{recruiter.username}
										</h4>
									))}
									<Button onClick={handleChangeRecruiter} disabled={status === 'pending'}>
										Change the Staff
									</Button>
								</>
							) : (
								<Button onClick={handleAssign} disabled={status === 'pending'}>
									Assign Job to Staff
								</Button>
							)}
							{status && (status == 'accepted' || status == 'rejected' || status == 'not found') &&
								(
									<Button onClick={() => navigate(`/agency/edit_job/${id}`)}>
										Edit Job
									</Button>
								)
							}
							{status && (status == 'pending') && (
								<div className="red">
									Your Edit request is pending.....
								</div>
							)}

						</div>
					</div>
				)}
				{job?.status == 'openend' &&
					<div className="closeJob">
						<Button type="primary" danger onClick={() => { handleCloseJob() }}>Close Job</Button>
					</div>
				}

				{job?.status == 'closed' &&
					<div className="error">Job Closed</div>
				}

			</div>

			<Modal
				title="Select Recruiters"
				open={isModalVisible}
				onOk={handleSubmitRecruiter}
				onCancel={() => setIsModalVisible(false)}
				okText="Submit"
				cancelText="Cancel"
			>
				<Select
					mode="multiple" // Enable multiple selection
					style={{ width: "100%" }}
					placeholder="Select recruiters"
					onChange={setSelectedRecruiter} // Ensure selectedRecruiter is an array
					value={selectedRecruiter} // Should be an array of selected recruiter IDs
				>
					{recruiters.map((recruiter) => (
						<Select.Option key={recruiter.id} value={recruiter.id}>
							{recruiter.username}
						</Select.Option>
					))}
				</Select>
			</Modal>

		</Main>
	);
};

export default CompleteJobPost;
