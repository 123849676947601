import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../common/useAuth';
import { message, Table, Modal, Button, Input, DatePicker, Form, TimePicker } from 'antd';
import Main from '../Layout';
import dayjs from 'dayjs';

const ApplicationsToSchedule = () => {
    const { apiurl, token } = useAuth();
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [selectedDate, setSelectedDate] = useState(null);
    const [fromTime, setFromTime] = useState(null);

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/recruiter/schedule_interview/pending_application/`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (data.error) {
                return message.error(data.error);
            }
            setData(data);
        } catch (e) {
            console.error(e);
            message.error("Failed to fetch applications");
        }
    };

    const fetchApplicationDetails = async (application_id) => {
        console.log(application_id, "is the application id ")
        setLoading(true);
        try {
            const response = await fetch(
                `${apiurl}/recruiter/schedule_interview/pending_application/?application_id=${application_id}`,
                {
                    method: "GET",
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            );

            const applicationData = await response.json();
            if (applicationData.error) {
                return message.error(applicationData.error);
            }

            setSelectedApplication(applicationData);
            setIsModalOpen(true);
        } catch (error) {
            message.error("Failed to fetch application details");
        } finally {
            setLoading(false);
        }
    };

    const handleScheduleInterview = async (values) => {
        const payload = {
            scheduled_date: values.scheduled_date.format('YYYY-MM-DD'),
            from_time: values.from_time.format('HH:mm:ss'),
            to_time: values.to_time.format('HH:mm:ss'),
            meet_link: values.meet_link,
        };

        console.log(selectedApplication.application_id)

        try {
            const response = await fetch(`${apiurl}/recruiter/schedule_interview/pending_application/?application_id=${selectedApplication.application_id}`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const responseData = await response.json();
            if (response.ok) {
                message.success("Interview scheduled successfully!");
                setIsModalOpen(false);
                fetchData();
            } else {
                message.error(responseData.error || "Failed to schedule interview");
            }
        } catch (error) {
            message.error("Failed to schedule interview");
        }
    };

    const columns = [
        {
            title: "Job Title",
            dataIndex: "job_title",
            key: "job_id",
        },
        {
            title: "Candidate Name",
            dataIndex: "candidate_name",
            key: "candidate_name",
        },
        {
            title: "Round Number",
            dataIndex: "round_num",
            key: "round_num",
        },
        {
            title: "Schedule Interview",
            key: "schedule_interview",
            render: (_, record) => (
                (record.next_interview ? (
                    <h7>Interview scheduled</h7>
                ) : (
                    <Button type="primary" onClick={() => fetchApplicationDetails(record.application_id)}>
                        Schedule
                    </Button>
                ))
            ),
        },
    ];

    return (
        <Main>
            <h1>Schedule Interviews</h1>
            <Table columns={columns} dataSource={data} rowKey="application_id" />

            {/* Modal for scheduling interview */}
            <Modal
                title="Schedule Interview"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
            >
                {selectedApplication && (
                    <Form form={form} layout="vertical" onFinish={handleScheduleInterview}>
                        <Form.Item label="Interviewer Name">
                            <Input value={selectedApplication.interviewer_name} disabled />
                        </Form.Item>
                        <Form.Item label="Interviewer Email">
                            <Input value={selectedApplication.interviewer_email} disabled />
                        </Form.Item>
                        <Form.Item label="Candidate Name">
                            <Input value={selectedApplication.candidate_name} disabled />
                        </Form.Item>
                        <Form.Item label="Candidate Email">
                            <Input value={selectedApplication.candidate_email} disabled />
                        </Form.Item>
                        <Form.Item label="Candidate Contact">
                            <Input value={selectedApplication.candidate_contact} disabled />
                        </Form.Item>
                        <Form.Item label="Alternate Contact">
                            <Input value={selectedApplication.candidate_alternate_contact} disabled />
                        </Form.Item>
                        <Form.Item label="Job Title">
                            <Input value={selectedApplication.job_title} disabled />
                        </Form.Item>
                        <Form.Item label="CTC">
                            <Input value={selectedApplication.job_ctc} disabled />
                        </Form.Item>

                        <Form.Item
                            label="Scheduled Date"
                            name="scheduled_date"
                            rules={[{ required: true, message: "Please select a date of interview!" }]}
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                style={{ width: "100%" }}
                                disabledDate={(current) => current && current < dayjs().startOf("day")}
                                onChange={(date) => setSelectedDate(date)}
                            />
                        </Form.Item>

                        {/* From Time */}
                        <Form.Item
                            label="From Time"
                            name="from_time"
                            rules={[{ required: true, message: "Please select from time!" }]}
                        >
                            <TimePicker
                                format="HH:mm"
                                style={{ width: "100%" }}
                                minuteStep={5}
                                disabledTime={() => {
                                    const now = dayjs().add(5, "minute");
                                    return {
                                        disabledHours: () =>
                                            [...Array(now.hour()).keys()],
                                        disabledMinutes: (hour) =>
                                            hour === now.hour()
                                                ? [...Array(now.minute()).keys()]
                                                : [],
                                    };
                                }}
                                onChange={(time) => setFromTime(time)}
                            />
                        </Form.Item>

                        <Form.Item
                            label="To Time"
                            name="to_time"
                            rules={[
                                { required: true, message: "Please select to time!" },
                                {
                                    validator: (_, value) =>
                                        fromTime && value && value.isAfter(fromTime)
                                            ? Promise.resolve()
                                            : Promise.reject("To Time must be after From Time"),
                                },
                            ]}
                        >
                            <TimePicker
                                format="HH:mm"
                                style={{ width: "100%" }}
                                minuteStep={5}
                                disabledTime={() => {
                                    if (!fromTime) return {};
                                    return {
                                        disabledHours: () =>
                                            [...Array(fromTime.hour()).keys()], // Disable hours before from_time
                                        disabledMinutes: (hour) =>
                                            hour === fromTime.hour()
                                                ? [...Array(fromTime.minute() + 1).keys()] // Disable minutes before from_time
                                                : [],
                                    };
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Meet Link"
                            name="meet_link"
                        >
                            <Input type="url" placeholder="Enter meet link" />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form>
                )}
            </Modal>
        </Main>
    );
};

export default ApplicationsToSchedule;
