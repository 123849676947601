import React, { useEffect, useState } from 'react'
import { Button, Modal, message, Form, Input, Spin } from 'antd'
import { useAuth } from '../../../common/useAuth'
import { useNavigate } from 'react-router-dom'


const OrganizationTerms = ({ companyCode, setCurrentStep, termsData, setTermsData }) => {
    const { token, apiurl } = useAuth();
    const [htmlContent, setHtmlContent] = useState();
    const [loading, setLoading] = useState(false)
    const [isNegotiating, setIsNegotiating] = useState(false);
    const navigate = useNavigate()
    const [form] = Form.useForm();

    useEffect(() => {
        fetchOrganizationTerms()
    }, [companyCode])

    const fetchOrganizationTerms = async () => {
        setLoading(true)
        try {
            const response = await fetch(
                `${apiurl}/get-organization-terms/?org_code=${companyCode}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const contentType = response.headers.get("Content-Type");
            if (contentType.includes("text/html")) {
                const html = await response.text();
                setHtmlContent(html);

                const dataJsonMatch = html.match(/window\.__DATA__ = (\{.*?\});/);
                if (dataJsonMatch && dataJsonMatch[1]) {
                    const data = JSON.parse(dataJsonMatch[1]);
                    setTermsData(data);
                }
            } else {
                const data = await response.json();

                if (data.error) {
                    setCurrentStep(0)
                    return message.error(data.error)
                }
                setTermsData(data);
            }
            message.success("Company terms fetched successfully!");
        } catch (error) {
            message.error(error.message);
        }
        finally { setLoading(false) }
    };


    const handleNegotiateTerms = () => {
        setIsNegotiating(true);
    };

    const handleNegotiationSubmit = async (values) => {
        console.log("Negotiated Terms:", values);
        values = { ...values, code: companyCode }
        try {
            const response = await fetch(`${apiurl}/negotiate-terms/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(values),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.detail || "Failed to create job posting");
            }
            message.success("Negotiation raised successfully!");
            navigate('/');
            setIsNegotiating(false);
        } catch (error) {
            message.error(error.message);
        }

    };


    return (
        <div>

            <div className="companyterms">
                <h3>Company Terms</h3>
                {loading ? (
                    <Spin size="large" />
                ) : (
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
                        <Button style={{ marginLeft: 10 }} type="primary" onClick={() => { setCurrentStep(2) }}>
                            Agree to Terms
                        </Button>
                        <Button style={{ marginLeft: 10 }} onClick={handleNegotiateTerms}>
                            Negotiate Terms
                        </Button>

                    </div>
                )}

                <Button onClick={() => { setCurrentStep(0) }}>Go Back</Button>

                <Modal
                    title="Negotiate Terms"
                    open={isNegotiating}
                    onCancel={() => setIsNegotiating(false)}
                    footer={null}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleNegotiationSubmit}>
                        <Form.Item
                            label="Service Fee"
                            name="service_fee"
                            initialValue={termsData?.service_fee}
                            rules={[
                                { required: true, message: "Please enter service fee" },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Replacement Clause"
                            name="replacement_clause"
                            initialValue={termsData?.replacement_clause}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter replacement clause",
                                },
                            ]}>
                            <Input.TextArea rows={4} />
                        </Form.Item>
                        <Form.Item
                            label="Invoice After"
                            name="invoice_after"
                            initialValue={termsData?.invoice_after}
                            rules={[
                                { required: true, message: "Please enter invoice after" },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Payment Within"
                            name="payment_within"
                            initialValue={termsData?.payment_within}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter payment within",
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Interest Percentage"
                            name="interest_percentage"
                            initialValue={termsData?.interest_percentage}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter interest percentage",
                                },
                            ]}>
                            <Input />
                        </Form.Item>

                        <Button type="primary" htmlType="submit">
                            Submit Negotiated Terms
                        </Button>
                    </Form>
                </Modal>
            </div>
        </div>
    )
}

export default OrganizationTerms