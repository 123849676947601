import React, { useState, useEffect } from "react";
import Main from "../Layout"; // Assuming you have a layout component
import { useAuth } from "../../../common/useAuth"; // Custom hook for authentication
import { Button, message, Modal, Table } from "antd"; // Ant Design components
import { useNavigate } from "react-router-dom"; // Navigation hook

const apiurl = process.env.REACT_APP_BACKEND_URL; // API URL from environment variables

const ScheduledInterviewsClient = () => {
  const { token } = useAuth(); // Authentication token from the custom hook
  const [data, setData] = useState([]); // State to store interviews data
  const [jobList, setJobList] = useState([]); // State to store job postings list
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [modalContent, setModalContent] = useState(""); // State to store modal content (Interviewer Name)
  const navigate = useNavigate(); // Hook for navigation

  // Fetch interview details by job ID
  const fetchData = async (id) => {
    setIsModalVisible(true)
    try {
      const response = await fetch(`${apiurl}/client/scheduled-interviews/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (data.error) {
        message.error(data.error); // Show error message if any
        return;
      } else if (data.message) {
        message.success(data.message); // Show success message if any
        return;
      }
      setData(data); // Set the interview data
      console.log(data, "is the response data");
    } catch (e) {
      console.error(e);
    }
  };

  // Fetch job postings data
  const fetchJobPosts = async () => {
    try {
      const response = await fetch(`${apiurl}/client/job-postings/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch job posts");
      }
      const data = await response.json();
      setJobList(data); // Set the job list data
      console.log("Posts", data);
    } catch (error) {
      message.error("Failed to fetch job posts. Please try again.");
    }
  };

  // Fetch job postings when the token is available
  useEffect(() => {
    if (token) {
      fetchJobPosts();
    }
  }, [token]);

  // Show modal with interviewer's name
  const showModal = (interviewerName) => {
    setModalContent(interviewerName); // Set modal content (interviewer's name)
    setIsModalVisible(true); // Show the modal
  };

  // Close the modal
  const handleCancel = () => {
    setIsModalVisible(false); // Close the modal
  };

  // Columns for the job list table
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Job Interviews",
      dataIndex: "jobInterviews",
      key: "jobInterviews",
      render: (_, record) => (
        <button onClick={() => fetchData(record.id)}>View Interviews</button>
      ),
    },
  ];

  // Columns for the interviewers table
  const columns2 = [
    {
      title: "Round Number",
      dataIndex: "interviewer",
      key: "round_num",
      render: (interviewer) => interviewer?.round_num || "N/A",
    },
    {
      title: "Interviewer ID",
      dataIndex: "interviewer",
      key: "interviewer_id",
      render: (interviewer) => interviewer?.name?.id || "N/A",
    },
    {
      title: "Interviewer Name",
      dataIndex: "interviewer",
      key: "interviewer_name",
      render: (interviewer) => interviewer?.name?.username
    },
    {
      title: "Scheduled Time",
      dataIndex: "scheduled_date",
      key: "scheduled_date",
      render: (schedule_date) => {
        const date = new Date(schedule_date);
        return date.toLocaleString();
      },
    },
    {
      title: "From Time",
      dataIndex: "from_time",
      key: "from_time",
      // render: (schedule_date) => {
      //   const date = new Date(schedule_date);
      //   return date.toLocaleString(); 
      // },
    },
    {
      title: "To Time",
      dataIndex: "to_time",
      key: "to_time",
      // render: (schedule_date) => {
      //   const date = new Date(schedule_date);
      //   return date.toLocaleString(); 
      // },
    },
  ];

  return (
    <Main defaultSelectedKey="5">
      {/* Job list table */}
      <Table columns={columns} dataSource={jobList} />

      {/* Modal to display Interviewer Name */}
      <Modal
        width='70%'
        title="Interviewer Name"
        visible={isModalVisible} // Modal visibility controlled by state
        onCancel={handleCancel} // Close modal when clicking the cancel button
        footer={null} // No footer in the modal
      >
        <Table columns={columns2} dataSource={data} />

      </Modal>
    </Main>
  );
};

export default ScheduledInterviewsClient;
