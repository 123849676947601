import React, { useState } from "react";
import { Form, Button, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../images/logo.svg";
const apiurl = process.env.REACT_APP_BACKEND_URL;

const VerifyEmail = () => {
    const { uuid, token } = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                `${apiurl}/verify-email/${uuid}/${token}/`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            setLoading(false);

            if (!response.ok) {
                message.error(data.error || "Something went wrong");
                return;
            }

            message.success(data.success || "Email verified successfully!");
            navigate("/login");
        } catch (error) {
            console.error(error);
            setLoading(false);
            message.error("Network error. Please try again later.");
        }
    };

    return (
        <div className="no_overflow">
            <div className="topnav">
                <img src={logo} alt="Home page" width={90} height={70} style={{ marginLeft: 30, marginTop: 5 }} />
            </div>
            <h1 className="center heading1">
                Connecting professionals and forging <br /> career paths, one connection at a time.
            </h1>

            <div className="button">
                <Button type="primary" loading={loading} onClick={handleSubmit}>
                    {loading ? "Verifying..." : "Verify Email"}
                </Button>
            </div>
        </div>
    );
};

export default VerifyEmail;
