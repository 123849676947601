import React, { useState, useEffect } from "react";
import { Table, Button, message, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../common/useAuth";
import Main from "../Layout";

const apiurl = process.env.REACT_APP_BACKEND_URL;
const { TabPane } = Tabs;

const AnyEditRequests = () => {
	const navigate = useNavigate();
	const [jobList, setJobList] = useState([]);
	const [interviewers, setInterviewers] = useState([]);
	const [loading, setLoading] = useState(false);
	const { token } = useAuth()

	const fetchData = () => {
		try {
			fetch(`${apiurl}/client/not-approval-jobs/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					setJobList(data);
					setInterviewers(data.interviewers_data);
					console.log(data, " is the received data")
				})
				.catch((error) => {
					message.error("Failed to fetch job posts. Please try again.");
				});
		}
		catch (e) {
			console.error(e);
		}
	}

	useEffect(() => {
		if (token) {
			fetchData();
		}
	}, [token]);

	const handleDetails = (id) => {
		setLoading(true);
		navigate(`/client/edited_job_details/${id}`);
	};

	const handleInterviewersData = (id) => {
		navigate(`/client/interviewers_data/${id}`);
	};

	const jobColumns = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Job Title",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "Organization Code",
			key: "organizaton_code",
			render: (text, record) => (
				<>
					<p>Organization Code : <span>{record.organization_code}</span></p>
					<p>Organization Complete Name : <span>{record.organization_name}</span></p>
				</>
			),
		},
		{
			title: "Requested by",
			dataIndex: "edited_by_username",
			key: "edited_by",
		},
		{
			title: "Status",
			key: "status",
			render: (text, record) => {
				if (record.status === 'pending') {
					return (
						<Button
							type="primary"
							onClick={() => handleDetails(record.id)}
							loading={loading}
						>
							See Complete Details
						</Button>
					);
				} else {
					return <p>{record.status}</p>;
				}
			}
		}
	];

	const interviewerColumns = [
		{
			title: "ID",
			dataIndex: "job_id",
			key: "job_id",
		},
		{
			title: "Edited By",
			dataIndex: "edited_by",
			key: "edited_by",
		},
		{
			title: "View Complete Details",
			key: "viewInterviewersDetails",
			render: (text, record) => (
				<Button onClick={() => handleInterviewersData(record.job_id)}>
					See Complete details
				</Button>
			),
		},
	];

	return (
		<Main>
			<Tabs defaultActiveKey="1">
				<TabPane tab="Job Posts" key="1">
					<div>
						{jobList && jobList.length > 0 ? (
							<Table columns={jobColumns} dataSource={jobList} rowKey="id" />
						) : (
							<div className="no-postings">There are no changes in postings</div>
						)}
					</div>
				</TabPane>
				<TabPane tab="Interviewers Data" key="2">
					<div>
						{interviewers && interviewers.length > 0 ? (
							<Table
								columns={interviewerColumns}
								dataSource={interviewers}
								rowKey="job_id"
							/>
						) : (
							<div className="no-postings">
								There are no changes in interviewers
							</div>
						)}
					</div>
				</TabPane>
			</Tabs>
		</Main>
	);
};

export default AnyEditRequests;
