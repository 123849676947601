import React from "react";
import "./Client.css";
import Main from "./Layout";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const Client = () => {
	const navigate = useNavigate();
	return (
		<Main defaultSelectedKey='1'>
			<h2>Welcome</h2>
			<Button onClick={() => {
				navigate('/client/edit-requests')
			}} >Any Edit Request</Button>
		</Main>
	);
};

export default Client;
