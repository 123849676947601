import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../common/useAuth";
import InterviewersTable from "../../../InterviewersTable/InterviewersTable";
import { Button } from "antd";
import Main from "../Layout";
const apiurl = process.env.REACT_APP_BACKEND_URL;
const CompleteJobPost_Client = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  id = parseInt(id);
  const [name, setName] = useState("");
  const { token } = useAuth();
  const [approved, setApproved] = useState(true);
  const [interviewers, SetInterviewers] = useState([]);
  const [job, setJob] = useState({
    id,
    username: "",
    job_title: "",
    job_description: "",
    primary_skills: "",
    secondary_skills: "",
    years_of_experience: 0,
    ctc: "",
    rounds_of_interview: 0,
    interviewers: "",
    interviewer_emails: "",
    job_locations: "",
    job_type: "",
    job_level: "",
    qualifications: "",
    timings: "",
    other_benefits: "",
    working_days_per_week: 5,
    interview_process: "",
    decision_maker: "",
    bond: "",
    rotational_shift: false,
    is_approved: true,
  });

  const columns = [
    {
      title: "Round",
      dataIndex: "round_num",
      key: "round_num",
    },
    {
      title: "Interviewer Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Interviewer Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Type of Interview",
      dataIndex: "type_of_interview",
      key: "type_of_interview",
    },
    {
      title: "Mode of Interview",
      dataIndex: "mode_of_interview",
      key: "mode_of_interview",
    },
  ];

  useEffect(() => {
    if (token) {
      fetch(`${apiurl}/client/job-postings/?id=${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          id: id,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data, "is the data");
          SetInterviewers(data.interview_details);
          setJob(data);
          setApproved(data.is_approved);
        })
        .catch((error) => console.error("Error fetching job details:", error));
    }

  }, [token, id]);

  useEffect(() => {
    console.log(interviewers, "Updated interviewers state");
  }, [interviewers]);

  useEffect(() => {
    if (job && job.is_assigned != null) {
      fetch(`${apiurl}/api/get_name/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: job.is_assigned }),
      })
        .then((response) => response.json())
        .then((data) => setName(data.name))
        .catch((error) =>
          console.error("Error fetching assigned staff name:", error)
        );
    }
  }, [job && job.is_assigned, token]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Main defaultSelectedKey="2">
      <Button type="text" onClick={handleBack}>
        {" "}
        &lt;- Go Back
      </Button>
      <div className="job-details-manager-main">
        {job && (
          <div>
            <h1>Job Details by {job.username.username}</h1>
            <div className="job-detail">
              <span>Job Title:</span>
              <div>{job.job_title}</div>
            </div>
            <div className="job-detail">
              <span>Job Description:</span>
              <div>{job.job_description}</div>
            </div>
            <div className="job-detail">
              <span>Job Closing Date:</span>
              <div>{job.job_close_duration}</div>
            </div>
            <div className="job-detail">
              <span>Languages</span>
              <div>{job.languages}</div>
            </div>
            <div className="job-detail">
              <span>Notice Period</span>
              <div>{job.notice_period}</div>
            </div>
            <div className="job-detail">
              <span>No Positions</span>
              <div>{job.num_of_positions}</div>
            </div>
            <div className="job-detail">
              <span>Job Department:</span>
              <div>{job.job_department}</div>
            </div>
            <div className="job-detail">
              <span>Primary Skills:</span>
              <div>{job.primary_skills}</div>
            </div>
            <div className="job-detail">
              <span>Secondary Skills:</span>
              <div>{job.secondary_skills}</div>
            </div>
            <div className="job-detail">
              <span>Years of Experience:</span>
              <div>{job.years_of_experience}</div>
            </div>
            <div className="job-detail">
              <span>CTC:</span>
              <div>{job.ctc}</div>
            </div>
            <div className="job-detail">
              <span>Rounds of Interview:</span>
              <div>{job.rounds_of_interview}</div>
            </div>
            <div className="job-detail">
              <span>Job Location:</span>
              <div>{job.job_locations}</div>
            </div>
            <div className="job-detail">
              <span>Job Type:</span>
              <div>{job.job_type}</div>
            </div>
            <div className="job-detail">
              <span>Job Level:</span>
              <div>{job.job_level}</div>
            </div>
            <div className="job-detail">
              <span>Qualifications:</span>
              <div>{job.qualifications}</div>
            </div>
            <div className="job-detail">
              <span>Qualification Dept</span>
              <div>{job.qualification_department}</div>
            </div>
            <div className="job-detail">
              <span>Timings:</span>
              <div>{job.timings}</div>
            </div>
            <div className="job-detail">
              <span>Other Benefits:</span>
              <div>{job.other_benefits}</div>
            </div>
            <div className="job-detail">
              <span>Working Days per Week:</span>
              <div>{job.working_days_per_week}</div>
            </div>
            <div className="job-detail">
              <span>Decision Maker:</span>
              <div>{job.decision_maker}</div>
            </div>
            <div className="job-detail">
              <span>Decision Maker Email:</span>
              <div>{job.decision_maker_email}</div>
            </div>
            <div className="job-detail">
              <span>Bond:</span>
              <div>{job.bond}</div>
            </div>
            <div className="job-detail">
              <span>Rotational Shift:</span>
              <div>{job.rotational_shift ? "Yes" : "No"}</div>
            </div>
            <div className="job-detail">
              <span>Job Post Status</span>
              <div>{job?.status}</div>
            </div>

            <hr />
            <h2>Interviewers data</h2>
            <div>
              <InterviewersTable interviewers={interviewers} />
            </div>
          </div>
        )}
      </div>
    </Main>
  );
};

export default CompleteJobPost_Client;
