import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import "./forms.css";
import Title from "antd/es/skeleton/Title";
const apiurl = process.env.REACT_APP_BACKEND_URL;
const ForgotPassword1 = () => {
	const [loading, setLoading] = useState(false);
	const validateEmail = (_, value) => {
		if (!value || /\S+@\S+\.\S+/.test(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error("Please enter a valid email address."));
	};
	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/forgotpassword/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(values),
			});
			setLoading(false);

			if (response.status == 404) {
				message.error("User Not found")
			}
			if (response.ok) {
				const data = await response.json();
				if (data.error) {
					return message.error(data.error)
				}
				message.success(data.success);
			}
		} catch (error) {
			console.error(error);
			message.error("Network Error. Please Try again after sometime");
		}
	};
	return (
		<>
			<div className="topnav">
				<img
					src="../src/media/ga_orgsync.png"
					alt="Home page img"
					width={90}
					height={70}
					style={{ marginLeft: 30, marginTop: 5 }}
				/>
			</div>
			<h1 className="center heading1">
				Connecting professionals and forging <br /> career paths, one connection
				at a time."
			</h1>
			<div className="client-signup">
				<Form layout="vertical" name="login_form" onFinish={handleSubmit}>
					<Title level={2} style={{ textAlign: "center" }}>
						Login
					</Title>
					<Form.Item
						label="Email"
						name="email"
						rules={[{ required: true, validator: validateEmail }]}
						validateTrigger="onBlur">
						<Input
							placeholder="Enter your email"
							prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
							className="login-input input_width"
						/>
					</Form.Item>

					<div className="form-act-btns">
						<Button
							type="primary"
							htmlType="submit"
							loading={loading}
							style={{ backgroundColor: "#001744" }}>
							Send verification mail
						</Button>
					</div>
				</Form>

				<div className="home_img center" style={{ paddingRight: 100 }}>
					<img
						src="../src/media/forgot_pwd.png"
						alt="email verification"
						className="home-img"
					/>
				</div>
			</div>
		</>
	);
};

export default ForgotPassword1;
