import "./App.css";
import { Route, Routes } from "react-router-dom";
import Forms from "./components/forms/forms";
import ClientSignup from "./components/forms/ClientSignup";
import ForgotPassword1 from "./components/forms/ForgotPassword";
import SetPassword from "./components/forms/SetPassword";
import { AuthRoute } from "./components/common/AuthRoute";
import MainHome from "./components/Home/Main";
import MyJobPosts from "./components/dashboard/client/ViewJobPost/MyJobPosts";
import AgencySignUp from "./components/forms/AgencySignup";
import OrganizationTerms from "./components/dashboard/Manager/TermsAndConditions/TermsAndConditions";
import JobPosting from "./components/dashboard/client/PostingNewJob/PostJob";
import SeeNegotiations from "./components/dashboard/Manager/TermsAndConditions/Negotiations";
import JobPosts from "./components/dashboard/Manager/JobPost/JobPosts";
import CompleteJobPost from "./components/dashboard/Manager/JobPost/CompleteJobPost";
import Recruiters from "./components/dashboard/Manager/Recruiter/Recruiters";
import MyTask from "./components/dashboard/Recruiter/MyTask";
import CompleteJobPostRecruiter from "./components/dashboard/Recruiter/CompleteJobPosts";
import CompleteJobPost_Client from "./components/dashboard/client/ViewJobPost/CompleteJobPost";
import Editjob from "./components/dashboard/Manager/EditJob/Editjob";
import AnyEditRequests from "./components/dashboard/client/EditRequests/AnyEditRequests";
import ParticularJobEdit from "./components/dashboard/client/EditRequests/JobEditRequest";
import ReceivedData from "./components/dashboard/client/Resumes/ReceivedData";
import Resumes from "./components/dashboard/client/Resumes/Resumes";
// import ScheduledInterviews from "./components/dashboard/client/Interviews/ScheduledInterviews";
import ScheduledInterviewsClient from "./components/dashboard/client/Interviews/ScheduledInterviews";
// import ConductInterview from "./components/dashboard/client/Interviews/ConductInterview";
import Approvals from "./components/dashboard/client/Approvals";
import Interviewers from "./components/dashboard/client/Interviewers/Interviewers";
import ScheduledInterviews from "./components/dashboard/Interviewer/ScheduledInterviews";
import ConductInterview from "./components/dashboard/Interviewer/ConductInterview";
import UpcomingInterviews from "./components/dashboard/Candidate/UpcomingInterviews";
import Profile from "./components/dashboard/Candidate/profile/Profile";
import Candidate from "./components/dashboard/Candidate/Candidate";
import CandidateDetails from "./components/dashboard/Candidate/profile/CandidateDetails";
import CandidateApplications from "./components/dashboard/Candidate/MyApplications";
import RecruiterProfile from "./components/dashboard/Recruiter/Profile/RecruiterProfile";
import Invoice from "./components/dashboard/Manager/Invoices/AllInvoice";
import VerifyEmail from "./components/forms/VerifyEmail";
import ViewCandidate from "./components/dashboard/Manager/Candidate/ViewCandidate";
import ApplicationsToSchedule from "./components/dashboard/Recruiter/ScheduleInterviews/ApplicationsToSchedule";
import JobResponseCard from "./components/dashboard/Manager/managercards/JobResponseCard";
import JobResponses from "./components/dashboard/Manager/JobResponses/JobResponses";
import CandidatesCard from "./components/dashboard/Manager/managercards/CandidatesCard";
import Candidates from "./components/dashboard/Manager/Candidates/Candidates";
import RecTaskTracking from "./components/dashboard/Manager/Recruiter/RecTaskTracking";
import AllRecruiters from "./components/dashboard/Manager/Recruiter/AllRecruiters";
const App = () => {
	return (
		<>
			<Routes>
				<Route path="/login" element={<Forms />} />
				<Route path="/forgot_password" element={<ForgotPassword1 />} />
				<Route path="/reset/:uuid/:token" element={<SetPassword />} />
				<Route path="/verify-email/:uuid/:token" element={<VerifyEmail />} />
				<Route path="/client/signup" element={<ClientSignup />} />
				<Route path="/agency/signup" element={<AgencySignUp />} />
				<Route path="/" element={<AuthRoute />}>
					<Route path="/" element={<MainHome />} />
					<Route path="/client/mypostings" element={<MyJobPosts />} />
					<Route path="/client/postjob" element={<JobPosting />} />
					<Route path="/client/complete_job_post/:id" element={<CompleteJobPost_Client />}></Route>
					<Route path="/client/edited_job_details/:id" element={<ParticularJobEdit />} />
					<Route path="/client/edit-requests" element={<AnyEditRequests />}></Route>
					<Route path="/client/received-data" element={<ReceivedData />} />
					<Route path="/client/get-resumes/:id" element={<Resumes />} /> {/* client resumes here  */}
					<Route path="/client/interviews/" element={<ScheduledInterviewsClient />} />
					<Route path='/client/approvals/' element={<Approvals />}></Route>
					<Route path='/client/interviewers' element={<Interviewers />} />
					<Route path="/interviewer/conduct-interview/:id" element={<ConductInterview />} />
					<Route path="/interviewer/scheduled_interviews" element={<ScheduledInterviews />} />
					<Route path="/agency/terms" element={<OrganizationTerms />} />
					<Route path="/agency/postings" element={<JobPosts />} />
					<Route path="/agency/postings/:id" element={<CompleteJobPost />} />
					<Route path="/agency/edit_job/:id" element={<Editjob />} />
					<Route path="/agency/negotiations" element={<SeeNegotiations />} />
					<Route path="/agency/recruiters" element={<Recruiters />} />
					<Route path="/agency/invoices" element={<Invoice />}></Route>
					<Route path='/agency/view_candidate' element={<ViewCandidate />} />
					<Route path="/agency/all_recruiters" element={<AllRecruiters />} />
					<Route path="/agency/task_tracking" element={<RecTaskTracking />} />
					<Route path="/agency/jobresponses" element={<JobResponses />} />
					{/* <Route path="/agency/card" element={<JobResponseCard />} /> */}
					<Route path="/agency/card" element={<CandidatesCard />} />
					<Route path="/agency/Candidates" element={<Candidates />} />
					<Route path="/recruiter/postings" element={<MyTask />} />
					<Route path="/recruiter/postings/:id" element={<CompleteJobPostRecruiter />} />
					<Route path="/recruiter/profile" element={<RecruiterProfile />} />
					<Route path="/recruiter/schedule_applications" element={<ApplicationsToSchedule />} />


					<Route path='/candidate/profile' element={<CandidateDetails />}></Route>
					<Route path='/candidate/upcoming_interviews' element={<UpcomingInterviews />} />
					<Route path='/candidate/applications' element={<CandidateApplications />} />
				</Route>
			</Routes>
		</>
	);
};

export default App;
